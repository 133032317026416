import './style/login.css'
//import avat from './images/avat.jpg';
import avat2 from './images/avat2.jpg';
//import { Link } from 'react-router-dom';
import authAxois from './requiestHandler';
import {useNavigate} from 'react-router-dom'
//import axios from 'axios';

function Login(){
  const navigate=useNavigate();
    return(<div className='login'>
      <title>Bingo Employee Login</title>
   
    
   
    <meta name="viewport" content="width=device-width, initial-scale=1" alt=''/>


         <img className="wave" src="https://www.htmlhints.com/demo/form/animatedLoginForm/img/wave.png"  alt=''/>
    <div className="container">
      <div className="img"></div>
      <div className="login-content">
        <div className='form'>
          <img src={avat2} style={{borderRadius:'50px'}} alt=''/>
          <h2 className="title" style={{color:'#fff'}}>አጫዋች</h2>
          <p className='invalid'></p>
          <div className="input-div one">
            <div className="i">
              <i className="fas fa-user"></i>
            </div>
            <div className="div">
             
              <input type="text" className="inputphone" placeholder='ስልክ ቁጥር'/>
            </div>
          </div>
          <div className="input-div pass">
            <div className="i">
              <i className="fas fa-lock"></i>
            </div>
            <div className="div">
            
              <input type="password" className="inputpassword" placeholder='ማለፊያ ቃል'/>
            </div>
          </div>
          <button className='button' onClick={()=>{
                   document.querySelector('.button').classList.add('button--loading')
                   const dataa={
                    phone:document.querySelector('.inputphone').value,
                    pwd:document.querySelector('.inputpassword').value
                   }
/******************************************************* **********
                   if((dataa.phone==='0900766703' && dataa.pwd==='test@bingo@game') || (dataa.phone==='0969461435' && dataa.pwd==='my@time')){
                         sessionStorage.setItem('vb',document.querySelector('.inputphone').value)
                         navigate('/auth/employee/game')
                   }else{
                    const gt=document.querySelector('.invalid')
                    const ut=document.createElement('p')
                    ut.textContent="Cheack Your Network Connection OR \nContact Your Service Provider"
                    ut.style.color='red';
                    ut.style.position='absolute';
                    ut.style.marginTop='-20px';
                    ut.style.marginLeft='100px';
                    gt.appendChild(ut);
                   }
                  ********************************************************/ 
                

                   
                   authAxois.post('/employee/login',dataa).then((response)=>{
                    //const usrinfo=JSON.parse(response.data.message) 
                    //console.log(response.data.statusss)
                     const stt=response.data.statuss;
                    // navigate('/players/board')
                    // console.log(usrinfo)
                     if(stt==='2xx')
                        {
                      //const usrinfo=JSON.parse(response.data.message)
                      console.log(response.data)
                        //sessionStorage.setItem('nameE',response.data[0].name)
                        //sessionStorage.setItem('adxm',response.data[0].boss_phone)
                        //sessionStorage.setItem('adxm',)
                        sessionStorage.setItem('vb',document.querySelector('.inputphone').value)
                        navigate('/auth/employee/game')
                        
                     }else{
                       const gt=document.querySelector('.invalid')
                       const ut=document.createElement('p')
                       ut.textContent=response.data.message
                       ut.style.color='red';
                       ut.style.position='absolute';
                       ut.style.marginTop='-20px';
                       ut.style.marginLeft='100px';
                       gt.appendChild(ut);

                       document.querySelector('.button').classList.remove('button--loading');
                     }
                   }).catch((e)=>{
                      //console.log(e)
                      const gt=document.querySelector('.invalid')
                       const ut=document.createElement('p')
                       ut.textContent="Cheack Your Network Connection OR \nContact Your Service Provider"
                       ut.style.color='red';
                       ut.style.position='absolute';
                       ut.style.marginTop='-20px';
                       ut.style.marginLeft='100px';
                       gt.appendChild(ut);
                      
                       document.querySelector('.button').classList.remove('button--loading');  
                      // navigate('/auth/employee/game')
                   })
                   
                
                   
          }}>ይግቡ</button>
        </div>

        
      </div>
    </div>
    
    <div style={{display:'none'}} className='counterone'></div>
    </div>)
}

export default Login;