//import logo from './logo.svg';
import Home from './pages/home.jsx';
import Login from './pages/login.jsx';
import AdminLogin from './pages/adminlogin.jsx';
import Adminpanel from './pages/adminpanel.jsx';
import Adminplayers from './pages/adminplayers.jsx';
import Game from './pages/game.jsx'
import {Routes,Route} from 'react-router-dom'
import AdminpanelController from './pages/admincontrol.jsx';
import AdminpanelloginController from './pages/adminpanellogincontroller.jsx'
import AdminpanelmainloginController from './pages/adminpanelmainlogincontroller.jsx'
import PlayCard from './pages/playingcards.jsx';
import Board from './pages/board.jsx';

function App() {
  return (
    <div className="App">
      <Routes>
         <Route path='/' exact element={<Home />}/>
         <Route path='/auth/employee/login' element={<Login />}/>
         <Route path='/auth/admin/login' element={<AdminLogin />}/>
         <Route path='/auth/employee/game' element={<Game />}/>
         <Route path='/auth/admin/game' element={<Adminpanel />}/>
         <Route path='/auth/admin/game/allplayers' element={<Adminplayers />}/>
         <Route path='/auth/admin/game/allacount/controller' element={<AdminpanelController />}/>
         <Route path='/auth/admin/game/controller/login' element={<AdminpanelloginController />}/>
         <Route path='/auth/admin/main/controller/login' element={<AdminpanelmainloginController />}/>
         <Route path='/playing/cards' element={<PlayCard />}/> 
         <Route path='/players/board' element={<Board />}/>
      </Routes>
      
    </div>
  );
}

export default App;
