import './style/adminlogin.css'
import avat from './images/avat.jpg';
import { useNavigate } from 'react-router-dom';
import authAxois from './requiestHandler';

function AdminLogin(){
  const navigate=useNavigate();
    return(<div className='adminlogin'>
       <img className="wave" src="https://www.htmlhints.com/demo/form/animatedLoginForm/img/wave.png" alt=''/>
    <div className="container">
      <div className="img"></div>
      <div className="login-content">
        <div className='form'>
          <img src={avat} style={{borderRadius: '30px'}} alt=''/>
          <h2 className="title" style={{color:'#fff'}}>ተቆጣጣሪ</h2>
          <p className='invalid'></p>
          <div className="input-div one">
            <div className="i">
              <i className="fas fa-user"></i>
            </div>
            <div className="div">
              
              <input type="text" className="inputphone" placeholder='ስልክ ቁጥር'/>
            </div>
          </div>
          <div className="input-div pass">
            <div className="i">
              <i className="fas fa-lock"></i>
            </div>
            <div className="div">
              
              <input type="password" className="inputpassword" placeholder='ማለፊያ ቃል'/>
            </div>
          </div>
         
          <button className='button' onClick={()=>{
            
            const info={
              phone:document.querySelector('.inputphone').value,
              pwd:document.querySelector('.inputpassword').value,
            }
            document.querySelector('.button').classList.add('button--loading')
            authAxois.post('/auth/admin/login',info).then((res)=>{
                if(res.data.statuss==='2xx'){
                  sessionStorage.setItem('phoneA',info.phone)
                  sessionStorage.setItem('adxm',info.phone)
                  sessionStorage.setItem('nameE',info.fullname)
                  sessionStorage.setItem('adx',res.data.adx)
                  navigate('/auth/admin/game')
                }else{
                  const gt=document.querySelector('.invalid')
                       const ut=document.createElement('p')
                       ut.textContent=res.data.message
                       ut.style.color='red';
                       ut.style.position='absolute';
                       ut.style.marginTop='-20px';
                       ut.style.marginLeft='100px';
                       gt.appendChild(ut);

                       document.querySelector('.button').classList.remove('button--loading');
                }
            }).catch(e=>e)
          }}>ይግቡ</button>
        </div>
      </div>
    </div>
    <script type="text/javascript" src="js/main.js"></script>
    <div style={{display:'none'}} className='counterone'></div>
    </div>)
}

export default AdminLogin;