import React from 'react';
import avat from './images/avat.jpg';
import avat2 from './images/avat2.jpg';
import avatar3 from './images/avatar3.png';
import girl from './images/girl.jpg';
import glass from './images/glass.jpg';
import glassOne from './images/glassOne.jpg';
import './style/home.css';
import { Link } from 'react-router-dom';

clearInterval()

function Home(){
    return(<div>

           <div className="main">
           <h1>ኖር ይግቡ!!</h1> 
           
              <div className='ttk'>
                
               <Link to="/auth/admin/login" >

                    <div className="card">
                        <div className="wrapper">
                            <img src={glassOne} className="cover-image" alt=''/>
                       </div>
                            <img src={girl} className="title" alt=''/>
                           <img src={glass} className="character" alt=''/>
              <h2>ተቆጣጣሪ</h2>
           </div>
        </Link>

    </div>

<Link to="/auth/employee/login">
 <div className='tmt'> 
  <div className="card">
  
    <div className="wrapper">
      <img src={avatar3} className="cover-image" alt=''/>
    </div>
    <img src={avat2} className="title" alt=''/>
    <img src={avat} className="character" alt=''/>
    <h2>አጫዋች</h2>
  </div>
</div>  
</Link>

</div>
<div style={{display:'none'}} className='counterone'></div>
    </div>)
}

export default Home;