import authAxois from './requiestHandler';
import './style/adminpanel.css';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';
let value=[];
const values=[]

function Adminplayers(){
  if(!sessionStorage.getItem('phoneA')){
    window.location='https://yegnabingo.bingobet.net'
 }
    const [values,setValue]=useState([])
    //let sta=true;
 
       
useEffect(()=>{

  fetchData();

        },[])
 
 

   const fetchData=async()=>{
      try{
         const response=await  authAxois.get('/admin/employee/allplayers/'+sessionStorage.getItem('phoneA'))
         //const jsonData=await response.json();
         setValue(response.data);
      }catch(e){
        console.log(`fetching data error due to :${e}`)
      }
   }

       

   //values=[...new Set(value)]
    return(<div className='adminpanel'>
          <link rel="apple-touch-icon" href="../../public/bingo.jpg" />
           <main>
    <header className='header'>
      
          <ul className='smooth'>
            <Link to="/auth/admin/game"><li>የጨዋታዎች ዝርዝር
                 <div class="icon">&#10095;</div>
                 <span className="bottom-line"></span>
             </li>
            </Link> 
             
           <Link to="/auth/admin/game/allplayers">  
             <li style={{backgroundColor:'black'}}>የጨዋታ ዝርዝር
                 <div class="icon">&#10095;</div>
                  <span className="bottom-line"></span> 
             </li>
            </Link>
             
             <li>የተገኘው ገቢ
                 <div class="icon">&#10095;</div>
                  <span className="bottom-line"></span>
             </li>
             <li>የጨዋታው ይዘት
                 <div class="icon">&#10095;</div>
                 <span className="bottom-line"></span>
             </li>
             <li>ማስተካከያ
                 <div class="icon">&#10095;</div>
                 <span className="bottom-line"></span>
             </li>
         </ul>
      
    </header>
    <div className='sectionn'>
      <p>የጨዋታዎች ዝርዝር</p>
      <div className='changebar'>
  <button onClick={()=>{
  }}>ቀን መርጠህ ጨዋታ ሰርዝ</button>
  <button>የአጫዋች መረጃ ቀይር</button>
  <button>የአጫዋች መረጃ አጥፋ</button>
  
</div>





        <div className='fixsection'>                
        </div>
     <div className='gameroundd'></div>
      <div className='tata'>
      <table>
  

     
      
    
         <tr> 
            <th>የአጫዋች ስም</th>
            <th>የአጫዋች ቁጥር </th>
            <th>ቀን</th>
            <th>ሰአት</th>
            <th>የተጫዋች ብዛት</th>
            <th>መደብ</th>
            <th>የአሸናፊ ክፍያ</th>
            <th>የተገኘው ገቢ</th>
         </tr>
        
            {
               values.map((item)=>
               
                    (<>
                       <tr>
                         <td>{item.employee_name}</td>
                         <td>{item.employee_phone}</td>
                         <td>{item.date_of_play}</td>
                         <td>{item.time_of_play}</td>
                         <td>{item.number_of_players}</td>
                         <td>{item.medeb}</td>
                         <td>{`${item.winning_price} ብር`}</td>
                         <td>{`${item.gain} ብር`}</td>
                     </tr> 
                   </>))
                      
                }      
        
       
</table>





      </div>
    </div>

    
  </main>



  <div style={{display:'none'}} className='counterone'></div>
    </div>)
}

export default Adminplayers;