import cards from "./allcards";
import './style/login.css'
//import avat from './images/avat.jpg';
//import avat2 from './images/avat2.jpg';
//import { Link } from 'react-router-dom';
//import authAxois from './requiestHandler';
//import {useNavigate} from 'react-router-dom'
import './style/playingcards.css'
//const xm=document.querySelector('.playercardarea')
const playerscard=()=>{
      
}


const handleClick=(index)=>{
  console.log(index)
}
function PlayCard(){

    return(<div className='login'>
      <title>Player Bingo Cards</title>
      
      <div className="playerscardarea">
      <div className="mainarea">
        {cards.map((val,index)=>{
            return(<div className="cardarea">
                <ul>
                <div className="betnum">
                  <li><button>B</button></li>
                     <li><button>I</button></li>
                     <li><button>N</button></li>
                     <li><button>G</button></li>
                    <li><button>O</button></li>
                  </div>
                  <br/><br/>
                  <div className="betnumbe"> 
                     <li><p className={"one"} key={index} onClick={handleClick(index)}>{val.b[0]}</p></li><br/> 
                     <li><p key={index} onClick={handleClick(index)}>{val.i[0]}</p></li><br/> 
                     <li><p key={index} onClick={handleClick(index)}>{val.n[0]}</p></li><br/>
                     <li><p key={index} onClick={handleClick(index)}>{val.g[0]}</p></li><br/> 
                     <li><p key={index} onClick={handleClick(index)}>{val.o[0]}</p></li><br/> 
                  </div>
                   


                  <div className="betnumber"> 
                     <li><p key={index} onClick={handleClick(index)}>{val.b[1]}</p></li><br/> 
                     <li><p key={index} onClick={handleClick(index)}>{val.i[1]}</p></li><br/> 
                     <li><p key={index} onClick={handleClick(index)}>{val.n[1]}</p></li><br/>
                     <li><p key={index} onClick={handleClick(index)}>{val.g[1]}</p></li><br/> 
                     <li><p key={index} onClick={handleClick(index)}>{val.o[1]}</p></li><br/> 
                  </div>  

                  <div className="betnumber2"> 
                     <li><p className="btx" onClick={()=>{
                       if(document.querySelector('.btx').style.backgroundColor==='green'){
                        document.querySelector('.btx').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.btx').style.backgroundColor='green'
                      }
                     }}>{val.b[2]}</p></li><br/> 
                     <li><p className="bty" onClick={()=>{
                      if(document.querySelector('.bty').style.backgroundColor==='green'){
                        document.querySelector('.bty').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bty').style.backgroundColor='green'
                      }
                     }}>{val.i[2]}</p></li><br/> 
                     <li><p className="btz" onClick={()=>{
                       if(document.querySelector('.btz').style.backgroundColor==='green'){
                        document.querySelector('.btz').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.btz').style.backgroundColor='green'
                      }
                     }}>{val.n[2]}</p></li><br/>
                     <li><p className="btnx" onClick={()=>{
                       if(document.querySelector('.btnx').style.backgroundColor==='green'){
                        document.querySelector('.btnx').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.btnx').style.backgroundColor='green'
                      }
                     }}>{val.g[2]}</p></li><br/> 
                    <li><p  className="btny" onClick={()=>{
                       if(document.querySelector('.btny').style.backgroundColor==='green'){
                        document.querySelector('.btny').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.btny').style.backgroundColor='green'
                      }
                    }}>{val.o[2]}</p></li><br/> 
                  </div> 



             <div className="betnumber3"> 
                     <li><p className="bty1" onClick={()=>{
                      if(document.querySelector('.bty1').style.backgroundColor==='green'){
                        document.querySelector('.bty1').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bty1').style.backgroundColor='green'
                      }
                     }}>{val.b[3]}</p></li><br/> 
                     <li><p className="bty2" onClick={()=>{
                      if(document.querySelector('.bty2').style.backgroundColor==='green'){
                        document.querySelector('.bty2').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bty2').style.backgroundColor='green'
                      }
                     }}>{val.i[3]}</p></li><br/> 
                     <li><p className="bty3" onClick={()=>{
                      if(document.querySelector('.bty3').style.backgroundColor==='green'){
                        document.querySelector('.bty3').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bty3').style.backgroundColor='green'
                      }
                     }}>{val.n[3]}</p></li><br/>
                     <li><p className="btk" onClick={()=>{
                      if(document.querySelector('.btk').style.backgroundColor==='green'){
                        document.querySelector('.btk').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.btk').style.backgroundColor='green'
                      }
                     }}>{val.g[3]}</p></li><br/> 
                    <li><p className="bti" onClick={()=>{
                      if(document.querySelector('.bti').style.backgroundColor==='green'){
                        document.querySelector('.bti').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bti').style.backgroundColor='green'
                      }
                    }}>{val.o[3]}</p></li><br/> 
                  </div>  



                  <div className="betnumber4"> 
                     <li><p className="bty5" onClick={()=>{
                      if(document.querySelector('.bty5').style.backgroundColor==='green'){
                        document.querySelector('.bty5').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bty5').style.backgroundColor='green'
                      }
                     }}>{val.b[4]}</p></li><br/> 
                     <li><p className='bty6' onClick={()=>{
                      if(document.querySelector('.bty6').style.backgroundColor==='green'){
                        document.querySelector('.bty6').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bty6').style.backgroundColor='green'
                      }
                     }}>{val.i[4]}</p></li><br/> 
                     <li><p className="bty7" onClick={()=>{
                      if(document.querySelector('.bty7').style.backgroundColor==='green'){
                        document.querySelector('.bty7').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bty7').style.backgroundColor='green'
                      }
                     }}>{val.n[4]}</p></li><br/>
                     <li><p className="bty8" onClick={()=>{
                      if(document.querySelector('.bty8').style.backgroundColor==='green'){
                        document.querySelector('.bty8').style.backgroundColor='gold'
                      }else{
                        document.querySelector('.bty8').style.backgroundColor='green'
                      }
                     }}>{val.g[4]}</p></li><br/> 
                    <li><p className="bty9" onClick={()=>{
                        if(document.querySelector('.bty9').style.backgroundColor==='green'){
                          document.querySelector('.bty9').style.backgroundColor='gold'
                        }else{
                          document.querySelector('.bty9').style.backgroundColor='green'
                        }
                    }}>{val.o[4]}</p></li><br/> 
                  </div>  


                   <li><h3>{val.cardname}</h3></li>
                
                </ul>
                
            </div>)
        })}
    </div>

<div className="choosedcard">
  <h2>Choosed Card Number place</h2>
  <div className="mainchoosed"></div>
</div>

      </div>
    


    
    <div style={{display:'none'}} className='counterone'></div>
    </div>)
}

export default PlayCard;