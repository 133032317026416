import authAxois from './requiestHandler';
import './style/adminpanel.css';
//import axios from 'axios';
import { Link } from 'react-router-dom';
import { useEffect, useState } from 'react';



function Adminpanel(){
  if(!sessionStorage.getItem('phoneA')){
    window.location='/'
 }
    const [valuess,setValue]=useState([])
    const values=[];
  //  let valar=[];
    //let sta=true;
 
       
    useEffect(()=>{

      fetchData();
    
    },[])

    for(let j=0;j<valuess.length;j++){
      if(sessionStorage.getItem('phoneA')===valuess[j].boss_phone){
         values.push(valuess[j])
      }
 }  
   const fetchData=async()=>{
      try{
        
      /*  
        const data={
          employeeName:sessionStorage.getItem('nameE'),
          adx:sessionStorage.getItem('adx')
        }
       */

         const response=await  authAxois.get('/admin/employee/info')
         //const jsonData=await response.json();
         setValue(response.data);
       
      }catch(e){
        console.log(`fetching data error due to :${e}`)
      }
   }

    return(<div className='adminpanel'>
           
           <main>
    <header className='header'>
      
          <ul className='smooth'>
            <Link to="/auth/admin/game"><li style={{backgroundColor:'black'}}>የአጫዋቾች ዝርዝር
                 <div class="icon">&#10095;</div>
                 <span className="bottom-line"></span>
             </li>
            </Link> 
             
           <Link to="/auth/admin/game/allplayers">  
             <li>የጨዋታ ዝርዝር
                 <div class="icon">&#10095;</div>
                  <span className="bottom-line"></span> 
             </li>
            </Link>

             <li>የተገኘው ገቢ
                 <div class="icon">&#10095;</div>
                  <span className="bottom-line"></span>
             </li>
             <li>የጨዋታው ይዘት
                 <div class="icon">&#10095;</div>
                 <span className="bottom-line"></span>
             </li>
             <li>ማስተካከያ
                 <div class="icon">&#10095;</div>
                 <span className="bottom-line"></span>
             </li>
         </ul>
      
    </header>
    <div className='sectionn'>
      <p>የአጫዋቾች ዝርዝር</p>
      <div className='changebar'>
  <button onClick={()=>{
    document.querySelector('.changebar').style.display='none'
    document.querySelector('.createmp').style.display='block'
  }}>አዲስ አጫዋች ፍጠር </button>
  <button>የአጫዋች መረጃ ቀይር</button>
  <button>የአጫዋች መረጃ አጥፋ</button>
  
</div>


<div className='createmp'>
   <div className='createone'>
      <div className='createtwo'>
        <h1 onClick={()=>{
          document.querySelector('.createmp').style.display='none'
          document.querySelector('.changebar').style.display='block'
        }}>X</h1>
         <input className='intone' placeholder='የአጫዋች ስም' required/> 
         <input className='inttwo' placeholder='የአጫዋች ሰልክ ቁጥር'required/> 
         <input className='intthree' placeholder='የአጫዋች ኢ-ሜል' required/> 
         <input className='intfour' placeholder='የአጫዋች ማለፊያ ቃል' required/> 
         <input className='intfive' placeholder='የአጫዋች ሀገር' required/> 
         <input className='intsix' placeholder='ከተማ' required/> 
         <input className='intseven' placeholder='አድራሻ' required/> 
         <input className='inteight' placeholder='የሰፈሩ ሰም' required/> 
         <input className='intnine' placeholder='የቤት ቁጥር' required/>
        
         <input type='hidden' className='inteleven' value={sessionStorage.getItem('phoneA')}/> 
      <div className='btnfooter'>  
         <button onClick={()=>{
           const dataa={
             fullname:document.querySelector('.intone').value,
             phone:document.querySelector('.inttwo').value,
             email:document.querySelector('.intthree').value,
             pwd:document.querySelector('.intfour').value,
             country:document.querySelector('.intfive').value,
             city:document.querySelector('.intsix').value,
             adress:document.querySelector('.intseven').value,
             place:document.querySelector('.inteight').value,
             house_no:document.querySelector('.intnine').value,
             statuss:Date().toString().slice(0,16),
             boss_phone:sessionStorage.getItem('phoneA')
           }
           authAxois.post('/admin/employee/auth/game',dataa).then((res)=>{
              if(res.data.statuss==='2xx'){
                    alert('New User Has Been Created Successfully')
              }
              else{
                alert('failed')
              }
           }).catch(e=>e)
         }}>ፍጠር</button> 
      </div>
    </div> 
   </div>
</div>


        <div className='fixsection'>                
        </div>
     <div className='gameroundd'></div>
      <div className='tata'>
      <table>
  

     
      
    
         <tr> 
            <th>የአጫዋች ስም </th>
            <th>ስልክ ቁጥር</th>
            <th>ኢ-ሜል</th>
            <th>ማለፊያ ቃል</th>
            <th>ሀገር</th>
            <th>ከተማ</th>
            <th>አደራሻ</th>
            <th>የሰፈሩ ስም</th>
            <th>የቤት ቁጥር</th>
            <th>ቀን</th>
            <th>የሀላፊው ስልክ ቁጥር</th>
         </tr>
        
            {
               values.map((item)=>
                    (<>
                       <tr>
                         <td>{item.fullname}</td>
                         <td>{item.phone}</td>
                         <td>{item.email}</td>
                         <td>{item.pwd}</td>
                         <td>{item.country}</td>
                         <td>{item.city}</td>
                         <td>{item.adress}</td>
                         <td>{item.place}</td>
                         <td>{item.house_no}</td>
                         <td>{item.status}</td>
                         <td>{item.boss_phone}</td>
                     </tr> 
                   </>))
                      
                }      
        
       
</table>





      </div>
    </div>

    
  </main>



  <div style={{display:'none'}} className='counterone'></div>
    </div>)
}

export default Adminpanel;