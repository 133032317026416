import './style/game.css'
import avat2 from './images/avat2.jpg';
import lossx from './images/lossx.jpg';
import b1 from './audio1/b1.mp3';
import b2 from './audio1/b2.mp3';
import b3 from './audio1/b3.mp3';
import b4 from './audio1/b4.mp3';
import b5 from './audio1/b5.mp3';
import b6 from './audio1/b6.mp3';
import b7 from './audio1/b7.mp3';
import b8 from './audio1/b8.mp3';
import b9 from './audio1/b9.mp3';
import b10 from './audio1/b10.mp3';
import b11 from './audio1/b11.mp3';
import b12 from './audio1/b12.mp3';
import b13 from './audio1/b13.mp3';
import b14 from './audio1/b14.mp3';
import b15 from './audio1/b15.mp3';
import b16 from './audio1/b16.mp3';
import b17 from './audio1/b17.mp3';
import b18 from './audio1/b18.mp3';
import b19 from './audio1/b19.mp3';
import b20 from './audio1/b20.mp3';
import b21 from './audio1/b21.mp3';
import b22 from './audio1/b22.mp3';
import b23 from './audio1/b23.mp3';
import b24 from './audio1/b24.mp3';
import b25 from './audio1/b25.mp3';
import b26 from './audio1/b26.mp3';
import b27 from './audio1/b27.mp3';
import b28 from './audio1/b28.mp3';
import b29 from './audio1/b29.mp3';
import b30 from './audio1/b30.mp3';
import b31 from './audio1/b31.mp3';
import b32 from './audio1/b32.mp3';
import b33 from './audio1/b33.mp3';
import b34 from './audio1/b34.mp3';
import b35 from './audio1/b35.mp3';
import b36 from './audio1/b36.mp3';
import b37 from './audio1/b37.mp3';
import b38 from './audio1/b38.mp3';
import b39 from './audio1/b39.mp3';
import b40 from './audio1/b40.mp3';
import b41 from './audio1/b41.mp3';
import b42 from './audio1/b42.mp3';
import b43 from './audio1/b43.mp3';
import b44 from './audio1/b44.mp3';
import b45 from './audio1/b45.mp3';
import b46 from './audio1/b46.mp3';
import b47 from './audio1/b47.mp3';
import b48 from './audio1/b48.mp3';
import b49 from './audio1/b49.mp3';
import b50 from './audio1/b50.mp3';
import b51 from './audio1/b51.mp3';
import b52 from './audio1/b52.mp3';
import b53 from './audio1/b53.mp3';
import b54 from './audio1/b54.mp3';
import b55 from './audio1/b55.mp3';
import b56 from './audio1/b56.mp3';
import b57 from './audio1/b57.mp3';
import b58 from './audio1/b58.mp3';
import b59 from './audio1/b59.mp3';
import b60 from './audio1/b60.mp3';
import b61 from './audio1/b61.mp3';
import b62 from './audio1/b62.mp3';
import b63 from './audio1/b63.mp3';
import b64 from './audio1/b64.mp3';
import b65 from './audio1/b65.mp3';
import b66 from './audio1/b66.mp3';
import b67 from './audio1/b67.mp3';
import b68 from './audio1/b68.mp3';
import b69 from './audio1/b69.mp3';
import b70 from './audio1/b70.mp3';
import b71 from './audio1/b71.mp3';
import b72 from './audio1/b72.mp3';
import b73 from './audio1/b73.mp3';
import b74 from './audio1/b74.mp3';
import b75 from './audio1/b75.mp3';
import authAxois from './requiestHandler';
import yotbingo from './audio1/yotbingo.mp3';
import not from './audio1/not.mp3';
import cards from './allcards';
import manwinning3 from './images/manwinning3.jpg';
import gamepictwo from './images/gamepictwo.jpg'
import win from './images/win.mp3'
//import b53 from './audio1/b53.mp3';
import bingowin from './gameaudio/bingowin.mp3';
import { useState } from 'react';
import io from 'socket.io-client'
//import { useNavigate } from 'react-router-dom'; 
import horizontalvertical from './gameaudio/horizontalvertical.mp3';
import oneany from './gameaudio/oneany.mp3';
import threeany from './gameaudio/threeany.mp3';
import twodiagonal from './gameaudio/twodiagonal.mp3';
import twovertical from './gameaudio/twovertical.mp3';
import twoany from './gameaudio/twoany.mp3';
import twohorizontal from './gameaudio/twohorizontal.mp3';
import bingoimg from './images/bingoimg.jpg';

//let value=0
//let d=0;
let gameIndicatorValue=1;
let actual_bingo_number=[]
let val=''
let takencards=[]
const calledNumber={
  b:[],
  i:[],
  n:[],
  g:[],
  o:[]
};


const cardNumbers=cards
//const socket=io.connect('http://localhost:7000')
const socket=io.connect('https://gamefree.bingobet.net')
let puase=0;
let bingo=[]
let caller=''
let number_of_players=0
let medeb='10 birr'
let perc=0.7;

let a=2;
let b=60;
let count=setInterval(()=>{
      let xn=document.querySelector('.counterone')
      xn.style.marginLeft='-200px'
      let dx=document.createElement('p')
      dx.textContent=`${a}:${b}`;
      dx.style.position='absolute'
      dx.style.backgroundColor='gold'
      dx.style.width='200px';
      dx.style.color='black'
      dx.style.height='50px'
      dx.style.padding='auto';
      dx.style.borderRadius='10px'
      dx.style.fontSize='30px'
      dx.style.fontWeight='bold'
      dx.style.textAlign='center'
      xn.appendChild(dx)
      b--;
      if(b===0){
         b=60
         a--;
         if(a===-1){
            a=2
         }
        
      }

      if(a===0 && b<=30){
         xn.style.backgroundColor='red';
         dx.style.backgroundColor='red';
         xn.style.color='#fff';
      }
      if(a===0 && b===1){
         //xn.style.width='300px'
         //xn.style.padding='20px'
         //dx.style.height='30px'
         dx.style.fontSize='20px'
         dx.style.color='#fff'
         dx.innerHTML='ጨዋታው ሊጀምር ነው!!'
         clearInterval(count)
      }
     


   },1000)

const sx=sessionStorage.getItem('vb');

//const gmval1='man of the year'


////recieve card numbers
socket.on('arraypush',(dat)=>{
   let elemk=true;
   let elemmk=false;
 
 val=`card${dat.data}`
   for(let b=0;b<takencards.length;b++){
      if(val===takencards[b]){
           elemk=false
      }
   } 
    
   for(let mk=0;mk<cardNumbers.length;mk++){
          if(val===cardNumbers[mk].cardname){
             elemmk=true;
          }
   }

   if(elemk===true && elemmk===true){
        takencards.push(val)
   }

})
socket.on('mobiledata-register',(info)=>{
   //console.log(info)
   let elem=true;
   let elemm=false;
 if(info.vx===sx){

 val=`card${info.data}`
   for(let b=0;b<takencards.length;b++){
      if(val===takencards[b]){
           elem=false
      }
   } 
    
   for(let mk=0;mk<cardNumbers.length;mk++){
          if(val===cardNumbers[mk].cardname){
             elemm=true;
          }
   }

if(elem===true && elemm===true){

   takencards.push(`card${info.data}`)
//socket.on('mobile-register',info)     
   let kt=document.querySelector('.registercards')
   let mm=document.createElement('p')
   mm.textContent=`ካርድ ቁጥር ${info.data} ተመዝግቡዋል`
   mm.style.backgroundColor='rgb(13, 40, 45)'
   mm.style.padding='20px'
   mm.style.width='330px'
   mm.style.margin='10px'
   mm.style.color='gold'
   mm.style.borderRadius='5px'
 /*  
   mm.addEventListener('click',()=>{
      for(let a=0;a<takencards.length;a++){
          if(takencards[a]===`card${info.data}`){
            takencards[a]=0;
          }
      }
   })
   */
   const height=kt.clientHeight;
   kt.scroll(0,height);
   kt.appendChild(mm);
   takencards.push(val)
   number_of_players=number_of_players+1;


   document.querySelector(`.card${info.data}`).style.backgroundColor='gold';
   document.querySelector(`.card${info.data}`).style.color='black';
               const wnp=document.createElement('p')
               wnp.textContent=`ለአሸናፊው ${Number(number_of_players)*Number(medeb.slice(0,3))*perc} ብር ሽልማት`
               wnp.style.position='absolute';
               wnp.style.backgroundColor='#083e31';
               wnp.style.width='250px';
               wnp.style.marginLeft='50px';
               wnp.style.color='#fff';
               wnp.style.padding='10px';
               wnp.style.borderRadius='5px'
               document.querySelector('.winprice').appendChild(wnp);

               const wnpx=document.createElement('button')
               wnpx.textContent=number_of_players
               wnpx.style.position='absolute';
               wnpx.style.backgroundColor='gold';
               wnpx.style.width='30px';
               wnpx.style.height='30px';
               wnpx.style.marginLeft='320px';
               wnpx.style.top='130px'
               wnpx.style.color='black';
               //wnpx.style.padding='10px';
               wnpx.style.borderRadius='10px'
               document.querySelector('.winprice').appendChild(wnpx);

            const wnp1=document.createElement('p');   
               wnp1.textContent=`ለአሸናፊው ${Number(number_of_players)*Number(medeb.slice(0,3))*perc} ብር ሽልማት`
               wnp1.style.position='absolute';
               wnp1.style.backgroundColor='#083e31';
               wnp1.style.width='250px';
               wnp1.style.marginLeft='50px';
               wnp1.style.color='#fff';
               wnp1.style.padding='10px';
               wnp1.style.borderRadius='5px'
               document.querySelector('.newshow').appendChild(wnp1);
}
else{
  //alert(`${info.data} ይህ ካርቴላ ቁጥር ተመዝግቡዋል እባክኦ ሌላ ካርቴላ ይምረጡ  `)
}

 }

})

//////erasing data
socket.on('erasegeneralcard',(info)=>{
   //alert(info)
   for(let j=0;j<takencards.length;j++){
      if(takencards[j]===`card${info}`){
         takencards[j]=0;
         document.querySelector(`.card${info}`).style.backgroundColor='black';
         document.querySelector(`.card${info}`).style.color='#fff'
      }
   }
   number_of_players--;
}) 



   //////////////////////////////

socket.on('winners',(data)=>{
   if(data===sx){
      const tg=document.querySelector('.cardNumber')
   
      tg.style.display='block';
      const kk=document.querySelector('.cnone')
      const gm=document.createElement('h2')
      gm.style.position='absolute'
      gm.style.marginTop='-50px';
      gm.style.marginLeft='-150px'
      gm.style.backgroundColor='#fff'
      gm.style.width='200px'
     
      if(bingo[puase]<=15){
       gm.textContent=`B ${bingo[puase]}`;
      }
      else if(bingo[puase]>=16 && bingo[puase]<=30){
       gm.textContent=`I ${bingo[puase]}`;
      }
      else if(bingo[puase]>=31 && bingo[puase]<=45){
       gm.textContent=`N ${bingo[puase]}`;
      }
      else if(bingo[puase]>=46 && bingo[puase]<=60){
       gm.textContent=`G ${bingo[puase]}`;
      }
      else if(bingo[puase]>=61 && bingo[puase]<=75){
       gm.textContent=`O ${bingo[puase]}`;
      }
      else{
       gm.textContent=`Invalid`;
      }
 
      kk.appendChild(gm)
 
     // alert(`index of ${puase} with value of ${bingo[puase]}`)
      clearInterval(caller)
      document.querySelector('.btnn1').style.backgroundColor='gold'
      document.querySelector('.btnn1').style.color='black'
 
      const kbingo=document.querySelector('.allsound3')
      kbingo.play()
   }
})


const ctth=()=>{
   let a=5;
   let cct=setInterval(()=>{
      const ct=document.createElement('p')
      ct.textContent=a;
      ct.style.position='absolute';
      ct.style.backgroundColor='rgb(10, 122, 66)';
      ct.style.padding='20px';
      ct.style.marginTop='-20px';
      ct.style.marginLeft='-80px';
      ct.style.borderRadius='30px';
      ct.style.border='5px solid #fff'
      ct.style.fontSize='35px';
      ct.style.width='70px';
      ct.style.height='70px';
    
      document.querySelector('.num').appendChild(ct)
      a--;
      if(a===-1){
         clearInterval(cct)
      }
   },1000)
}

const clc=()=>{
     ///////////////////// Countinoue /////////////////
            //                                              //
            //                                              //
            //               Continoue Logic                //
            //                                              //
            ///////////////////////////////////////////////////

            a=actual_bingo_number.indexOf(bingo[puase])               
            // let a=0;
              caller=setInterval(()=>{
             //import `b${ctual_bingo_number[a]}` from `./audio/b${actual_bingo_number[a]}`
               let kt=document.createElement('p')
               kt.textContent=actual_bingo_number[a]
               kt.style.position='absolute';
               kt.style.backgroundColor='blue';
               kt.style.padding='20px';
               kt.style.marginTop='-20px'
               kt.style.marginLeft='-10px'
               kt.style.borderRadius='40px'
             
               kt.style.fontSize='35px'
////// starting of intervals of bingo and priviouse call
if(actual_bingo_number[a]<=15){
  const b=document.createElement('p')
  b.textContent='B';
  b.style.position='absolute';
  b.style.backgroundColor='rgb(10, 122, 66)';
  b.style.padding='20px';
  b.style.marginTop='-20px';
  b.style.marginLeft='-80px';
  b.style.borderRadius='30px';
  b.style.border='5px solid #fff'
  b.style.fontSize='35px';
  b.style.width='70px';
  b.style.height='70px';

  document.querySelector('.num').appendChild(b)
}  
else if(actual_bingo_number[a]>15 && actual_bingo_number[a]<=30){
        const i=document.createElement('p')
        i.textContent='I';
        i.style.position='absolute';
        i.style.backgroundColor='rgb(10, 122, 66)';
        i.style.padding='20px';
        i.style.marginTop='-20px';
        i.style.marginLeft='-80px';
        i.style.borderRadius='30px';
        i.style.border='5px solid #fff'
        i.style.fontSize='35px';
        i.style.width='70px';
        i.style.height='70px'
       document.querySelector('.num').appendChild(i)
} 
else if(actual_bingo_number[a]>30 && actual_bingo_number[a]<=45){
  const n=document.createElement('p')
  n.textContent='N';
  n.style.position='absolute';
  n.style.backgroundColor='rgb(10, 122, 66)';
  n.style.padding='20px';
  n.style.marginTop='-20px';
  n.style.marginLeft='-80px';
  n.style.borderRadius='30px';
  n.style.border='5px solid #fff'
  n.style.fontSize='35px';
  n.style.width='70px';
  n.style.height='70px'; 

  document.querySelector('.num').appendChild(n)
}      
else if(actual_bingo_number[a]>45 && actual_bingo_number[a]<=60){
  const g=document.createElement('p')
  g.textContent='G';
  g.style.position='absolute';
  g.style.backgroundColor='rgb(10, 122, 66)';
  g.style.padding='20px';
  g.style.marginTop='-20px';
  g.style.marginLeft='-80px';
  g.style.borderRadius='30px';
  g.style.border='5px solid #fff'
  g.style.fontSize='35px';
  g.style.width='70px';
  g.style.height='70px';

  document.querySelector('.num').appendChild(g)
}
else if(actual_bingo_number[a]>60 && actual_bingo_number[a]<=75){
  const o=document.createElement('p')
  o.textContent='O';
  o.style.position='absolute';
  o.style.backgroundColor='rgb(10, 122, 66)';
  o.style.padding='20px';
  o.style.marginTop='-20px';
  o.style.marginLeft='-80px';
  o.style.borderRadius='30px';
  o.style.border='5px solid #fff'
  o.style.fontSize='35px';
  o.style.width='70px';
  o.style.height='70px'

  document.querySelector('.num').appendChild(o)
}

         document.querySelector('.num').appendChild(kt)


///////////////// starting privouse call ////////////////////
const o1=document.createElement('p')
  o1.textContent=actual_bingo_number[actual_bingo_number.indexOf(actual_bingo_number[a])-1];
  o1.style.position='absolute';
  o1.style.backgroundColor='rgb(151, 13, 47)';
  o1.style.padding='15px';
  o1.style.marginTop='-15px';
  o1.style.textAlign='center';
  o1.style.marginLeft='80px';
  o1.style.borderRadius='30px';
  o1.style.border='5px solid #fff'
  o1.style.fontSize='35px';
  o1.style.width='60px';
  o1.style.height='60px'

  document.querySelector('.num').appendChild(o1)


/////////////////////////ending of privouse call ///////////
///////////ending of interval and privouse call


               document.querySelector('.num').appendChild(kt)
               document.querySelector(`.b${actual_bingo_number[a]}`).style.backgroundColor='gold'
               document.querySelector(`.b${actual_bingo_number[a]}`).style.fontWeight='bold'
               document.querySelector(`.b${actual_bingo_number[a]}`).style.fontSize='25px'
               document.querySelector(`.b${actual_bingo_number[a]}`).style.justifyContent='center'
               document.querySelector(`.b${actual_bingo_number[a]}`).style.color='black'
              // const ktm=document.querySelector('.sound')
              // const roar=document.createElement('source')
              
              //roar.remove(); 
        //your called number logic is here
        if(actual_bingo_number[a]<=15){       
            calledNumber.b.push(actual_bingo_number[a])
              //now add sound for the game
              if(actual_bingo_number[a]===1){
              // roar.remove()
              const ktm1=document.querySelector('.sound1')
               const roar=document.createElement('source')
               roar.setAttribute('src',b1)
            
               //roar.setAttribute('src',b50) 
                ktm1.appendChild(roar)
                 ktm1.play()
                 
              }
              else if(actual_bingo_number[a]===2){
               //roar.remove()
               const ktm2=document.querySelector('.sound2')
               const roar=document.createElement('source')
               roar.setAttribute('src',b2)
            
               //roar.setAttribute('src',b50) 
                ktm2.appendChild(roar)
                 ktm2.play()
                 
              }
              else if(actual_bingo_number[a]===3){
              // roar.remove()
              const ktm3=document.querySelector('.sound3')
               const roar=document.createElement('source')
               roar.setAttribute('src',b3)
                
               //roar.setAttribute('src',b50) 
                ktm3.appendChild(roar)
                 ktm3.play()
                 
              }
              else if(actual_bingo_number[a]===4){
               const ktm4=document.querySelector('.sound4')
               const roar=document.createElement('source')
               roar.setAttribute('src',b4)
            
               //roar.setAttribute('src',b50) 
                ktm4.appendChild(roar)
                 ktm4.play()
                 
              }
              else if(actual_bingo_number[a]===5){
              // roar.remove()
              const ktm5=document.querySelector('.sound5')
               const roar=document.createElement('source')
               roar.setAttribute('src',b5)
            
               //roar.setAttribute('src',b50) 
                ktm5.appendChild(roar)
                 ktm5.play()
                 
              }
              else if(actual_bingo_number[a]===6){
              // roar.remove()
              const ktm6=document.querySelector('.sound6')
               const roar=document.createElement('source')
               roar.setAttribute('src',b6)
            
               //roar.setAttribute('src',b50) 
                ktm6.appendChild(roar)
                 ktm6.play()
                 
              }
              else if(actual_bingo_number[a]===7){

              // roar.remove()
              const ktm7=document.querySelector('.sound7')
               const roar=document.createElement('source')
               roar.setAttribute('src',b7)
            
               //roar.setAttribute('src',b50) 
                ktm7.appendChild(roar)
                 ktm7.play()
                 
              }
              else if(actual_bingo_number[a]===8){
              // roar.remove()
              const ktm8=document.querySelector('.sound8')
               const roar=document.createElement('source')
               roar.setAttribute('src',b8)
            
               //roar.setAttribute('src',b50) 
                ktm8.appendChild(roar)
                 ktm8.play()
                 
              }
              else if(actual_bingo_number[a]===9){
              // roar.remove()
              const ktm9=document.querySelector('.sound9')
               const roar=document.createElement('source')
               roar.setAttribute('src',b9)
            
               //roar.setAttribute('src',b50) 
                ktm9.appendChild(roar)
                 ktm9.play()
               
              }
              else if(actual_bingo_number[a]===10){
              // roar.remove()
              const ktm10=document.querySelector('.sound10')
               const roar=document.createElement('source')
               roar.setAttribute('src',b10)
            
               //roar.setAttribute('src',b50) 
                ktm10.appendChild(roar)
                 ktm10.play()
                 
              }
              else if(actual_bingo_number[a]===11){
              // roar.remove()
              const ktm11=document.querySelector('.sound11')
               const roar=document.createElement('source')
               roar.setAttribute('src',b11)
            
               //roar.setAttribute('src',b50) 
                ktm11.appendChild(roar)
                 ktm11.play()
                 
              }
              else if(actual_bingo_number[a]===12){
              // roar.remove()
              const ktm12=document.querySelector('.sound12')
               const roar=document.createElement('source')
               roar.setAttribute('src',b12)
            
               //roar.setAttribute('src',b50) 
                ktm12.appendChild(roar)
                 ktm12.play()
                 
              }
              else if(actual_bingo_number[a]===13){
              // roar.remove()
              const ktm13=document.querySelector('.sound13')
               const roar=document.createElement('source')
               roar.setAttribute('src',b13)
            
               //roar.setAttribute('src',b50) 
                ktm13.appendChild(roar)
                 ktm13.play()
                 
              }
              else if(actual_bingo_number[a]===14){
              // roar.remove()
              const ktm14=document.querySelector('.sound14')
               const roar=document.createElement('source')
               roar.setAttribute('src',b14)
            
               //roar.setAttribute('src',b50) 
                ktm14.appendChild(roar)
                 ktm14.play()
                 
              }
              else if(actual_bingo_number[a]===15){
              // roar.remove()
              const ktm15=document.querySelector('.sound15')
               const roar=document.createElement('source')
               roar.setAttribute('src',b15)
            
               //roar.setAttribute('src',b50) 
                ktm15.appendChild(roar)
                 ktm15.play()
                
              }
             
          }
        else if(actual_bingo_number[a]>=16 && actual_bingo_number[a]<=30){
           calledNumber.i.push(actual_bingo_number[a])
           if(actual_bingo_number[a]===16){
             // roar.remove()
             const ktm16=document.querySelector('.sound16')
              const roar=document.createElement('source')
              roar.setAttribute('src',b16)
           
              //roar.setAttribute('src',b50) 
               ktm16.appendChild(roar)
                ktm16.play()
                
             }
             else if(actual_bingo_number[a]===17){
              //roar.remove()
              const ktm17=document.querySelector('.sound17')
              const roar=document.createElement('source')
              roar.setAttribute('src',b17)
           
              //roar.setAttribute('src',b50) 
               ktm17.appendChild(roar)
                ktm17.play()
                
             }
             else if(actual_bingo_number[a]===18){
             // roar.remove()
             const ktm18=document.querySelector('.sound18')
              const roar=document.createElement('source')
              roar.setAttribute('src',b18)
               
              //roar.setAttribute('src',b50) 
               ktm18.appendChild(roar)
                ktm18.play()
                
             }
             else if(actual_bingo_number[a]===19){
              const ktm19=document.querySelector('.sound19')
              const roar=document.createElement('source')
              roar.setAttribute('src',b19)
           
              //roar.setAttribute('src',b50) 
               ktm19.appendChild(roar)
                ktm19.play()
                
             }
             else if(actual_bingo_number[a]===20){
             // roar.remove()
             const ktm20=document.querySelector('.sound20')
              const roar=document.createElement('source')
              roar.setAttribute('src',b20)
           
              //roar.setAttribute('src',b50) 
               ktm20.appendChild(roar)
                ktm20.play()
                
             }
             else if(actual_bingo_number[a]===21){
             // roar.remove()
             const ktm21=document.querySelector('.sound21')
              const roar=document.createElement('source')
              roar.setAttribute('src',b21)
           
              //roar.setAttribute('src',b50) 
               ktm21.appendChild(roar)
                ktm21.play()
                
             }
             else if(actual_bingo_number[a]===22){

             // roar.remove()
             const ktm22=document.querySelector('.sound22')
              const roar=document.createElement('source')
              roar.setAttribute('src',b22)
           
              //roar.setAttribute('src',b50) 
               ktm22.appendChild(roar)
                ktm22.play()
                
             }
             else if(actual_bingo_number[a]===23){
             // roar.remove()
             const ktm23=document.querySelector('.sound23')
              const roar=document.createElement('source')
              roar.setAttribute('src',b23)
           
              //roar.setAttribute('src',b50) 
               ktm23.appendChild(roar)
               ktm23.play()
                
             }
             else if(actual_bingo_number[a]===24){
             // roar.remove()
             const ktm24=document.querySelector('.sound24')
              const roar=document.createElement('source')
              roar.setAttribute('src',b24)
           
              //roar.setAttribute('src',b50) 
               ktm24.appendChild(roar)
                ktm24.play()
              
             }
             else if(actual_bingo_number[a]===25){
             // roar.remove()
             const ktm25=document.querySelector('.sound25')
              const roar=document.createElement('source')
              roar.setAttribute('src',b25)
           
              //roar.setAttribute('src',b50) 
               ktm25.appendChild(roar)
                ktm25.play()
                
             }
             else if(actual_bingo_number[a]===26){
             // roar.remove()
             const ktm26=document.querySelector('.sound26')
              const roar=document.createElement('source')
              roar.setAttribute('src',b26)
           
              //roar.setAttribute('src',b50) 
               ktm26.appendChild(roar)
                ktm26.play()
                
             }
             else if(actual_bingo_number[a]===27){
             // roar.remove()
             const ktm27=document.querySelector('.sound27')
              const roar=document.createElement('source')
              roar.setAttribute('src',b27)
           
              //roar.setAttribute('src',b50) 
               ktm27.appendChild(roar)
                ktm27.play()
                
             }
             else if(actual_bingo_number[a]===28){
             // roar.remove()
             const ktm28=document.querySelector('.sound28')
              const roar=document.createElement('source')
              roar.setAttribute('src',b28)
           
              //roar.setAttribute('src',b50) 
               ktm28.appendChild(roar)
                ktm28.play()
                
             }
             else if(actual_bingo_number[a]===29){
             // roar.remove()
             const ktm29=document.querySelector('.sound29')
              const roar=document.createElement('source')
              roar.setAttribute('src',b29)
           
              //roar.setAttribute('src',b50) 
               ktm29.appendChild(roar)
                ktm29.play()
                
             }
             else if(actual_bingo_number[a]===30){
             // roar.remove()
             const ktm30=document.querySelector('.sound30')
              const roar=document.createElement('source')
              roar.setAttribute('src',b30)
           
              //roar.setAttribute('src',b50) 
               ktm30.appendChild(roar)
                ktm30.play()
               
             }
        }
        else if(actual_bingo_number[a]>=31 && actual_bingo_number[a]<=45){
            calledNumber.n.push(actual_bingo_number[a])
            if(actual_bingo_number[a]===31){
             // roar.remove()
             const ktm31=document.querySelector('.sound31')
              const roar=document.createElement('source')
              roar.setAttribute('src',b31)
           
              //roar.setAttribute('src',b50) 
               ktm31.appendChild(roar)
                ktm31.play()
                
             }
             else if(actual_bingo_number[a]===32){
              //roar.remove()
              const ktm32=document.querySelector('.sound32')
              const roar=document.createElement('source')
              roar.setAttribute('src',b32)
           
              //roar.setAttribute('src',b50) 
               ktm32.appendChild(roar)
                ktm32.play()
                
             }
             else if(actual_bingo_number[a]===33){
             // roar.remove()
             const ktm33=document.querySelector('.sound33')
              const roar=document.createElement('source')
              roar.setAttribute('src',b33)
               
              //roar.setAttribute('src',b50) 
               ktm33.appendChild(roar)
                ktm33.play()
                
             }
             else if(actual_bingo_number[a]===34){
              const ktm34=document.querySelector('.sound34')
              const roar=document.createElement('source')
              roar.setAttribute('src',b34)
           
              //roar.setAttribute('src',b50) 
               ktm34.appendChild(roar)
                ktm34.play()
                
             }
             else if(actual_bingo_number[a]===35){
             // roar.remove()
             const ktm35=document.querySelector('.sound35')
              const roar=document.createElement('source')
              roar.setAttribute('src',b35)
           
              //roar.setAttribute('src',b50) 
               ktm35.appendChild(roar)
                ktm35.play()
                
             }
             else if(actual_bingo_number[a]===36){
             // roar.remove()
             const ktm36=document.querySelector('.sound36')
              const roar=document.createElement('source')
              roar.setAttribute('src',b36)
           
              //roar.setAttribute('src',b50) 
               ktm36.appendChild(roar)
                ktm36.play()
                
             }
             else if(actual_bingo_number[a]===37){

             // roar.remove()
             const ktm37=document.querySelector('.sound37')
              const roar=document.createElement('source')
              roar.setAttribute('src',b37)
           
              //roar.setAttribute('src',b50) 
               ktm37.appendChild(roar)
                ktm37.play()
                
             }
             else if(actual_bingo_number[a]===38){
             // roar.remove()
             const ktm38=document.querySelector('.sound38')
              const roar=document.createElement('source')
              roar.setAttribute('src',b38)
           
              //roar.setAttribute('src',b50) 
               ktm38.appendChild(roar)
                ktm38.play()
                
             }
             else if(actual_bingo_number[a]===39){
             // roar.remove()
             const ktm39=document.querySelector('.sound39')
              const roar=document.createElement('source')
              roar.setAttribute('src',b39)
           
              //roar.setAttribute('src',b50) 
               ktm39.appendChild(roar)
                ktm39.play()
              
             }
             else if(actual_bingo_number[a]===40){
             // roar.remove()
             const ktm40=document.querySelector('.sound40')
              const roar=document.createElement('source')
              roar.setAttribute('src',b40)
           
              //roar.setAttribute('src',b50) 
               ktm40.appendChild(roar)
                ktm40.play()
                
             }
             else if(actual_bingo_number[a]===41){
             // roar.remove()
             const ktm41=document.querySelector('.sound41')
              const roar=document.createElement('source')
              roar.setAttribute('src',b41)
           
              //roar.setAttribute('src',b50) 
               ktm41.appendChild(roar)
                ktm41.play()
                
             }
             else if(actual_bingo_number[a]===42){
             // roar.remove()
             const ktm42=document.querySelector('.sound42')
              const roar=document.createElement('source')
              roar.setAttribute('src',b42)
           
              //roar.setAttribute('src',b50) 
               ktm42.appendChild(roar)
                ktm42.play()
                
             }
             else if(actual_bingo_number[a]===43){
             // roar.remove()
             const ktm43=document.querySelector('.sound43')
              const roar=document.createElement('source')
              roar.setAttribute('src',b43)
           
              //roar.setAttribute('src',b50) 
               ktm43.appendChild(roar)
                ktm43.play()
                
             }
             else if(actual_bingo_number[a]===44){
             // roar.remove()
             const ktm44=document.querySelector('.sound44')
              const roar=document.createElement('source')
              roar.setAttribute('src',b44)
           
              //roar.setAttribute('src',b50) 
               ktm44.appendChild(roar)
                ktm44.play()
                
             }
             else if(actual_bingo_number[a]===45){
             // roar.remove()
             const ktm45=document.querySelector('.sound45')
              const roar=document.createElement('source')
              roar.setAttribute('src',b45)
           
              //roar.setAttribute('src',b50) 
               ktm45.appendChild(roar)
                ktm45.play()
               
             }

        }
        else if(actual_bingo_number[a]>=46 && actual_bingo_number[a]<=60){
            calledNumber.g.push(actual_bingo_number[a])
            if(actual_bingo_number[a]===46){
             // roar.remove()
             const ktm46=document.querySelector('.sound46')
              const roar=document.createElement('source')
              roar.setAttribute('src',b46)
           
              //roar.setAttribute('src',b50) 
               ktm46.appendChild(roar)
                ktm46.play()
                
             }
             else if(actual_bingo_number[a]===47){
              //roar.remove()
              const ktm47=document.querySelector('.sound47')
              const roar=document.createElement('source')
              roar.setAttribute('src',b47)
           
              //roar.setAttribute('src',b50) 
               ktm47.appendChild(roar)
                ktm47.play()
                
             }
             else if(actual_bingo_number[a]===48){
             // roar.remove()
             const ktm48=document.querySelector('.sound48')
              const roar=document.createElement('source')
              roar.setAttribute('src',b48)
               
              //roar.setAttribute('src',b50) 
               ktm48.appendChild(roar)
                ktm48.play()
                
             }
             else if(actual_bingo_number[a]===49){
              const ktm49=document.querySelector('.sound49')
              const roar=document.createElement('source')
              roar.setAttribute('src',b49)
           
              //roar.setAttribute('src',b50) 
               ktm49.appendChild(roar)
                ktm49.play()
                
             }
             else if(actual_bingo_number[a]===50){
             // roar.remove()
             const ktm50=document.querySelector('.sound50')
              const roar=document.createElement('source')
              roar.setAttribute('src',b50)
           
              //roar.setAttribute('src',b50) 
               ktm50.appendChild(roar)
                ktm50.play()
                
             }
             else if(actual_bingo_number[a]===51){
             // roar.remove()
             const ktm51=document.querySelector('.sound51')
              const roar=document.createElement('source')
              roar.setAttribute('src',b51)
           
              //roar.setAttribute('src',b50) 
               ktm51.appendChild(roar)
                ktm51.play()
                
             }
             else if(actual_bingo_number[a]===52){

             // roar.remove()
             const ktm52=document.querySelector('.sound52')
              const roar=document.createElement('source')
              roar.setAttribute('src',b52)
           
              //roar.setAttribute('src',b50) 
               ktm52.appendChild(roar)
                ktm52.play()
                
             }
             else if(actual_bingo_number[a]===53){
             // roar.remove()
             const ktm53=document.querySelector('.sound53')
              const roar=document.createElement('source')
              roar.setAttribute('src',b53)
           
              //roar.setAttribute('src',b50) 
               ktm53.appendChild(roar)
                ktm53.play()
                
             }
             else if(actual_bingo_number[a]===54){
             // roar.remove()
             const ktm54=document.querySelector('.sound54')
              const roar=document.createElement('source')
              roar.setAttribute('src',b54)
           
              //roar.setAttribute('src',b50) 
               ktm54.appendChild(roar)
                ktm54.play()
              
             }
             else if(actual_bingo_number[a]===55){
             // roar.remove()
             const ktm55=document.querySelector('.sound55')
              const roar=document.createElement('source')
              roar.setAttribute('src',b55)
           
              //roar.setAttribute('src',b50) 
               ktm55.appendChild(roar)
                ktm55.play()
                
             }
             else if(actual_bingo_number[a]===56){
             // roar.remove()
             const ktm56=document.querySelector('.sound56')
              const roar=document.createElement('source')
              roar.setAttribute('src',b56)
           
              //roar.setAttribute('src',b50) 
               ktm56.appendChild(roar)
                ktm56.play()
                
             }
             else if(actual_bingo_number[a]===57){
             // roar.remove()
             const ktm57=document.querySelector('.sound57')
              const roar=document.createElement('source')
              roar.setAttribute('src',b57)
           
              //roar.setAttribute('src',b50) 
               ktm57.appendChild(roar)
                ktm57.play()
                
             }
             else if(actual_bingo_number[a]===58){
             // roar.remove()
             const ktm58=document.querySelector('.sound58')
              const roar=document.createElement('source')
              roar.setAttribute('src',b58)
           
              //roar.setAttribute('src',b50) 
               ktm58.appendChild(roar)
                ktm58.play()
                
             }
             else if(actual_bingo_number[a]===59){
             // roar.remove()
             const ktm59=document.querySelector('.sound59')
              const roar=document.createElement('source')
              roar.setAttribute('src',b59)
           
              //roar.setAttribute('src',b50) 
               ktm59.appendChild(roar)
                ktm59.play()
                
             }
             else if(actual_bingo_number[a]===60){
             // roar.remove()
             const ktm60=document.querySelector('.sound60')
              const roar=document.createElement('source')
              roar.setAttribute('src',b60)
           
              //roar.setAttribute('src',b50) 
               ktm60.appendChild(roar)
                ktm60.play()
               
             }
        }
        else if(actual_bingo_number[a]>=61 && actual_bingo_number[a]<=75){
            calledNumber.o.push(actual_bingo_number[a])
            if(actual_bingo_number[a]===61){
             // roar.remove()
             const ktm61=document.querySelector('.sound61')
              const roar=document.createElement('source')
              roar.setAttribute('src',b61)
           
              //roar.setAttribute('src',b50) 
               ktm61.appendChild(roar)
                ktm61.play()
                
             }
             else if(actual_bingo_number[a]===62){
              //roar.remove()
              const ktm62=document.querySelector('.sound62')
              const roar=document.createElement('source')
              roar.setAttribute('src',b62)
           
              //roar.setAttribute('src',b50) 
               ktm62.appendChild(roar)
                ktm62.play()
                
             }
             else if(actual_bingo_number[a]===63){
             // roar.remove()
             const ktm63=document.querySelector('.sound63')
              const roar=document.createElement('source')
              roar.setAttribute('src',b63)
               
              //roar.setAttribute('src',b50) 
               ktm63.appendChild(roar)
                ktm63.play()
                
             }
             else if(actual_bingo_number[a]===64){
              const ktm64=document.querySelector('.sound64')
              const roar=document.createElement('source')
              roar.setAttribute('src',b64)
           
              //roar.setAttribute('src',b50) 
               ktm64.appendChild(roar)
                ktm64.play()
                
             }
             else if(actual_bingo_number[a]===65){
             // roar.remove()
             const ktm65=document.querySelector('.sound65')
              const roar=document.createElement('source')
              roar.setAttribute('src',b65)
           
              //roar.setAttribute('src',b50) 
               ktm65.appendChild(roar)
                ktm65.play()
                
             }
             else if(actual_bingo_number[a]===66){
             // roar.remove()
             const ktm66=document.querySelector('.sound66')
              const roar=document.createElement('source')
              roar.setAttribute('src',b66)
           
              //roar.setAttribute('src',b50) 
               ktm66.appendChild(roar)
                ktm66.play()
                
             }
             else if(actual_bingo_number[a]===67){

             // roar.remove()
             const ktm67=document.querySelector('.sound67')
              const roar=document.createElement('source')
              roar.setAttribute('src',b67)
           
              //roar.setAttribute('src',b50) 
               ktm67.appendChild(roar)
                ktm67.play()
                
             }
             else if(actual_bingo_number[a]===68){
             // roar.remove()
             const ktm68=document.querySelector('.sound68')
              const roar=document.createElement('source')
              roar.setAttribute('src',b68)
           
              //roar.setAttribute('src',b50) 
               ktm68.appendChild(roar)
                ktm68.play()
                
             }
             else if(actual_bingo_number[a]===69){
             // roar.remove()
             const ktm69=document.querySelector('.sound69')
              const roar=document.createElement('source')
              roar.setAttribute('src',b69)
           
              //roar.setAttribute('src',b50) 
               ktm69.appendChild(roar)
                ktm69.play()
              
             }
             else if(actual_bingo_number[a]===70){
             // roar.remove()
             const ktm70=document.querySelector('.sound70')
              const roar=document.createElement('source')
              roar.setAttribute('src',b70)
           
              //roar.setAttribute('src',b50) 
               ktm70.appendChild(roar)
                ktm70.play()
                
             }
             else if(actual_bingo_number[a]===71){
             // roar.remove()
             const ktm71=document.querySelector('.sound71')
              const roar=document.createElement('source')
              roar.setAttribute('src',b71)
           
              //roar.setAttribute('src',b50) 
               ktm71.appendChild(roar)
                ktm71.play()
                
             }
             else if(actual_bingo_number[a]===72){
             // roar.remove()
             const ktm72=document.querySelector('.sound72')
              const roar=document.createElement('source')
              roar.setAttribute('src',b72)
           
              //roar.setAttribute('src',b50) 
               ktm72.appendChild(roar)
                ktm72.play()
                
             }
             else if(actual_bingo_number[a]===73){
             // roar.remove()
             const ktm73=document.querySelector('.sound73')
              const roar=document.createElement('source')
              roar.setAttribute('src',b73)
           
              //roar.setAttribute('src',b50) 
               ktm73.appendChild(roar)
                ktm73.play()
                
             }
             else if(actual_bingo_number[a]===74){
             // roar.remove()
             const ktm74=document.querySelector('.sound74')
              const roar=document.createElement('source')
              roar.setAttribute('src',b74)
           
              //roar.setAttribute('src',b50) 
               ktm74.appendChild(roar)
                ktm74.play()
                
             }
             else if(actual_bingo_number[a]===75){
             // roar.remove()
             const ktm75=document.querySelector('.sound75')
              const roar=document.createElement('source')
              roar.setAttribute('src',b75)
           
              //roar.setAttribute('src',b50) 
               ktm75.appendChild(roar)
                ktm75.play()
               
             }
        }
        else{
         console.log('wrong number was decleared...')
        }     
              puase=a;
               a++;

               if(actual_bingo_number[a]===actual_bingo_number[a.length]){
                 console.log(calledNumber)  
                 clearInterval(caller);
                   alert('ጨዋታውን ተፈፀመ')
                   document.querySelector('.str').innerHTML='Game Over With No Winner';
                  // document.querySelector('.str').style.color='gold';
                   for(let k=0;k<actual_bingo_number.length;k++){
                       document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                      // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                       document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                   }
               }
             },5000)




        /////////////////// end of Continoue Logic end code//////////   
        //                                                   //
        //                                                   // 
        //                end of Continoue Logic code        //         
        //                                                   //
        ///////////////////////////////////////////////////////
}




function Game(){
  
   if(!sessionStorage.getItem('vb')){
      //window.location='https://yegnabingo.bingobet.net'
   }
     //example of time counters

   
//count();
   
   /////////////////////////////


const [valuss,setValuss]=useState([]);  
const [mdb,setmdb]=useState([])
const [pec,setPec]=useState([]);

const percVal=(e)=>{
   setPec(e.target.value)
   perc=e.target.value;
}
const allmedeb=(e)=>{
   setmdb(e.target.value);    
   medeb=e.target.value;
}

const gameplay=(e)=>{
   setValuss(e.target.value);
   console.log(e.target.value)
   if(e.target.value==='ጨዋታ 1 ዝግ በተገኘበት'){
      //document.querySelector('.bt1').style.backgroundColor='gold'
      //document.querySelector('.bt1').style.color='black' 
      document.querySelector('.str').innerHTML='ጨዋታ 1 ዝግ በተገኘበት !!\nመልካም እድል'
      document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
      document.querySelector('.str').style.padding='20px'
      document.querySelector('.str').style.margin='5px';
      document.querySelector('.str').style.width='600px'
      document.querySelector('.str').style.borderRadius='10px'
      document.querySelector('.str').style.fontSize='20px'

      const onex=document.querySelector('.playsound1')
      onex.play()
           }
   else if(e.target.value==='ጨዋታ 2 ዝግ በተገኘበት'){
         // document.querySelector('.bt1').style.backgroundColor='gold'
         // document.querySelector('.bt1').style.color='black' 
          document.querySelector('.str').innerHTML='ጨዋታ 2 ዝግ በተገኘበት !!\nመልካም እድል'
          document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
          document.querySelector('.str').style.padding='20px'
          document.querySelector('.str').style.margin='5px';
          document.querySelector('.str').style.width='600px'
          document.querySelector('.str').style.borderRadius='10px'
          document.querySelector('.str').style.fontSize='20px' 
          
          const onex=document.querySelector('.playsound2')
          onex.play();
         }  
   else if(e.target.value==='ጨዋታ 3 ዝግ በተገኘበት'){
           //document.querySelector('.bt1').style.backgroundColor='gold'
           //document.querySelector('.bt1').style.color='black' 
           document.querySelector('.str').innerHTML='ጨዋታ 3 ዝግ በተገኘበት !!\nመልካም እድል'
           document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
           document.querySelector('.str').style.padding='20px'
           document.querySelector('.str').style.margin='5px';
           document.querySelector('.str').style.width='600px'
           document.querySelector('.str').style.borderRadius='10px'
           document.querySelector('.str').style.fontSize='20px'
           
           const onex=document.querySelector('.playsound3')
           onex.play();
         
         }
   else if(e.target.value==='ጨዋታ 2 ወደታች'){
            //document.querySelector('.bt1').style.backgroundColor='gold'
            //document.querySelector('.bt1').style.color='black' 
            document.querySelector('.str').innerHTML='ጨዋታ  2 ወደታች!!\nመልካም እድል'
            document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
            document.querySelector('.str').style.padding='20px'
            document.querySelector('.str').style.margin='5px';
            document.querySelector('.str').style.width='600px'
            document.querySelector('.str').style.borderRadius='10px'
            document.querySelector('.str').style.fontSize='20px'  
            
            const onex=document.querySelector('.playsound4')
            onex.play();
         
         } 
   else if(e.target.value==='ጨዋታ 2 ወደጎን'){
           //document.querySelector('.bt1').style.backgroundColor='gold'
           //document.querySelector('.bt1').style.color='black' 
           document.querySelector('.str').innerHTML='ጨዋታ  2 ወደጎን\nመልካም እድል'
           document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
           document.querySelector('.str').style.padding='20px'
           document.querySelector('.str').style.margin='5px';
           document.querySelector('.str').style.width='600px'
           document.querySelector('.str').style.borderRadius='10px'
           document.querySelector('.str').style.fontSize='20px'  
           
           const onex=document.querySelector('.playsound5')
           onex.play();
         
         }  
   else if(e.target.value==='ጨዋታ 1 ወደጎን 1 ወደታች'){
           //document.querySelector('.bt1').style.backgroundColor='gold'
           //document.querySelector('.bt1').style.color='black' 
           document.querySelector('.str').innerHTML='ጨዋታ  1 ወደጎን 1 ወደታች\nመልካም እድል'
           document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
           document.querySelector('.str').style.padding='20px'
           document.querySelector('.str').style.margin='5px';
           document.querySelector('.str').style.width='600px'
           document.querySelector('.str').style.borderRadius='10px'
           document.querySelector('.str').style.fontSize='20px' 
           
           const onex=document.querySelector('.playsound6')
           onex.play();
         
         }   
  
      else if(e.target.value==='ጨዋታ 2 ዲያጎናል'){
         //document.querySelector('.bt1').style.backgroundColor='gold'
         //document.querySelector('.bt1').style.color='black' 
         document.querySelector('.str').innerHTML='ጨዋታ 2 ዲያጎናል \nመልካም እድል'
         document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
         document.querySelector('.str').style.padding='20px'
         document.querySelector('.str').style.margin='5px';
         document.querySelector('.str').style.width='600px'
         document.querySelector('.str').style.borderRadius='10px'
         document.querySelector('.str').style.fontSize='20px';
         
         const onex=document.querySelector('.playsound7');
         onex.play();
      }
      else if(e.target.value==='ጨዋታ 2 ሰኩዌር የማይገናኝ'){
            //document.querySelector('.bt1').style.color='black' 
         document.querySelector('.str').innerHTML='ጨዋታ  2 ሰኩዌር የማይገናኝ !\nመልካም እድል'
         document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
         document.querySelector('.str').style.padding='20px'
         document.querySelector('.str').style.margin='5px';
         document.querySelector('.str').style.width='600px'
         document.querySelector('.str').style.borderRadius='10px'
         document.querySelector('.str').style.fontSize='20px';
         
        // const onex=document.querySelector('.playsound7');
      }
       
      else if(e.target.value==='ጨዋታ 1 ድያጎናል 2 ስኩዌር የማይገናኝ'){
         //document.querySelector('.bt1').style.color='black' 
      document.querySelector('.str').innerHTML='ጨዋታ 1 ድያጎናል 2 ስኩዌር የማይገናኝ !\nመልካም እድል'
      document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
      document.querySelector('.str').style.padding='20px'
      document.querySelector('.str').style.margin='5px';
      document.querySelector('.str').style.width='600px'
      document.querySelector('.str').style.borderRadius='10px'
      document.querySelector('.str').style.fontSize='20px';
      
    //  const onex=document.querySelector('.playsound7');
   }

   else if(e.target.value==='ጨዋታ 2 ድያጎናል 1 ስኩዌር'){
      //document.querySelector('.bt1').style.color='black' 
   document.querySelector('.str').innerHTML='ጨዋታ 2 ድያጎናል 1 ስኩዌር የማይገናኝ !\nመልካም እድል'
   document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
   document.querySelector('.str').style.padding='20px'
   document.querySelector('.str').style.margin='5px';
   document.querySelector('.str').style.width='600px'
   document.querySelector('.str').style.borderRadius='10px'
   document.querySelector('.str').style.fontSize='20px';
   
  // const onex=document.querySelector('.playsound7');
}
   
else if(e.target.value==='ጨዋታ 2 ወደ ጎን 1 ስኩዌር የማይገናኝ'){
   //document.querySelector('.bt1').style.color='black' 
document.querySelector('.str').innerHTML='ጨዋታ 2 ወደ ጎን 1 ስኩዌር የማይገናኝ !መልካም እድል'
document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
document.querySelector('.str').style.padding='20px'
document.querySelector('.str').style.margin='5px';
document.querySelector('.str').style.width='600px'
document.querySelector('.str').style.borderRadius='10px'
document.querySelector('.str').style.fontSize='20px';

//const onex=document.querySelector('.playsound7');
}

else if(e.target.value==='2 ሰኩዌር ኮርነር ላይ'){
   //document.querySelector('.bt1').style.color='black' 
document.querySelector('.str').innerHTML='2 ሰኩዌር ኮርነር ላይ !መልካም እድል'
document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
document.querySelector('.str').style.padding='20px'
document.querySelector('.str').style.margin='5px';
document.querySelector('.str').style.width='600px'
document.querySelector('.str').style.borderRadius='10px'
document.querySelector('.str').style.fontSize='20px';

//const onex=document.querySelector('.playsound7');
}



      else if(e.target.value==='ጨዋታ ሙሉ ዝግ'){
         //document.querySelector('.bt1').style.backgroundColor='gold'
         //document.querySelector('.bt1').style.color='black' 
         document.querySelector('.str').innerHTML='ጨዋታ ሙሉ ዝግ!! መልካም እድል'
         document.querySelector('.str').style.backgroundColor='rgb(13, 20, 35)'
         document.querySelector('.str').style.padding='20px'
         document.querySelector('.str').style.margin='5px';
         document.querySelector('.str').style.width='600px'
         document.querySelector('.str').style.borderRadius='10px'
         document.querySelector('.str').style.fontSize='20px';
        

      }


}

    return(<div className="game">
                  <div className='mobile-register'>
                       
                       <div className='mobile-card'>
                            <div className='cardplace'>
                               <h2>ተጫዋች የመረጠውን ካርድ ቁጥር ያስገቡ</h2>
                               <input className='mobileinput' placeholder='ካርድ ቁጥር ያስገቡ' alt=''/>
                               <button onClick={()=>{
               let elem=true;
               let elemm=false;
             
             val=`card${document.querySelector('.mobileinput').value}`
               for(let b=0;b<takencards.length;b++){
                  if(val===takencards[b]){
                       elem=false
                  }
               } 
                
               for(let mk=0;mk<cardNumbers.length;mk++){
                      if(val===cardNumbers[mk].cardname){
                         elemm=true;
                      }
               }

      if(elem===true && elemm===true){
         const info={
            data:document.querySelector('.mobileinput').value,
            vx:sx
         }
         socket.emit('mobile-register',info)
             
               let kt=document.querySelector('.mobileregistercards')
               let mm=document.createElement('p')
               mm.textContent=`ካርድ ቁጥር ${document.querySelector('.mobileinput').value} ተመዝግቡዋል`
               mm.style.backgroundColor='rgb(13, 40, 45)'
               mm.style.padding='20px'
               mm.style.width='270px'
               mm.style.margin='10px'
               mm.style.color='gold'
               mm.style.borderRadius='5px'
               mm.addEventListener('click',()=>{
                  //socket.emit('erasecard',`card${document.querySelector('.mobileinput').value}`)
                  document.querySelector('.erase').style.display='block'
                  //mm.remove();
               })
               const height=kt.clientHeight;
               kt.scroll(0,height);
               kt.appendChild(mm)
               takencards.push(val)
      }
      else{
         alert(`${document.querySelector('.mobileinput').value} ይህ ካርቴላ ቁጥር ተመዝግቡዋል እባክኦ ሌላ ካርቴላ ይምረጡ  `)
      }
            }}>መዝግብ</button>

                            </div>
                                <div className='mobileregistercards'></div>  
                                <div className='mobilechewata'></div>
                                <div className='erase'>
                                   <div className='erasedata'>
                                      <input className='delinput' placeholder='የሚጠፋውን ካርድ ቁጥር ያስገቡ'/><br/>
                                      <button onClick={()=>{
                                         let ix=document.querySelector('.delinput').value
                                         for(let j=0;j<takencards.length;j++){
                                           if(takencards[j]===`card${ix}`){
                                              takencards[j]=0;
                                           }
                                         }
                                         socket.emit('erasecard',ix)
                                         //alert('Deleting has been success')
                                         document.querySelector('.erase').style.display='none'
                                         number_of_players--;
                                      }} style={{backgroundColor:'red',color:'#fff'}}>አጥፋ</button>
                                      <button onClick={()=>{
                                       document.querySelector('.erase').style.display='none'
                                      }}>ተው</button>
                                   </div>
                                </div>
                       </div>
                           <div className='mobilefooter'>
                               <button onClick={()=>{
                                 document.querySelector('.mobileregistercards').style.display='block'
                                 document.querySelector('.cardplace').style.display='block'
                                 document.querySelector('.mobilechewata').innerHTML=''
                              }}>ምዝገባ</button>
                               <button onClick={()=>{
                                 document.querySelector('.mobileregistercards').innerHTML='';
                                 takencards=[]
                               }}>ቦታውን አጥፋ</button>
                               <button onClick={()=>{
                                 document.querySelector('.mobilechewata').innerHTML=''
                                 takencards=[]
                                 document.querySelector('.mobileregistercards').style.display='none'
                                 document.querySelector('.cardplace').style.display='none'
                                 let bx=document.querySelector('.mobilefooter')
                                 bx.style.marginTop='-80px'

                                const gb=document.querySelector('.mobilechewata')
                                const gi=document.createElement('img')
                                gi.setAttribute('src',manwinning3)
                                gi.style.width='250px';
                                gi.style.width='250px';
                                gi.style.borderRadius='10px';
                                gi.style.margin='20px';
                                gb.appendChild(gi);
                                
                                const win=document.createElement('button')
                                win.textContent='BINGO';
                                win.style.width='250px';
                                win.style.padding='15px'
                                win.style.backgroundColor='#0478ed';
                                win.style.color='gold';
                                win.style.border='none';
                                win.style.alignContent='center';
                                win.style.margin='10px';
                                win.addEventListener('click',()=>{
                                 win.style.backgroundColor='gold';
                                 win.style.color='black';
                                  socket.emit('winners',sx)
                                })

                                gb.appendChild(win);


                               }}>ጨዋታ</button>
                           </div>
                  </div>
        <nav className='nav'>
          <button className='btc' onClick={()=>{
             const hack=document.querySelector('.userscard')
             hack.style.display='block'
             for(let k=1250;k>850;k--){
                  setTimeout(()=>{
                     for(let n=1250;n>k;n--){
                           document.querySelector('.usersca').style.marginLeft=`${n}px`  
                     }
                  })
             }
          }}>
             <div></div>
             <div></div>
             <div></div>
          </button>
          
              <div className="nav-links">
                
              </div>
              <br/>
              <div className="play-btn">
                <ul>
                   
                </ul>
            </div> 
        </nav>
        


        <div className="player-area">
            <h1 className="str">BINGO ጨዋታ</h1>
            <div className="num">
            </div>
       <div>        
         <audio className='allsound' controls style={{display:'none'}}>
          <source src={win}></source>
         </audio> 

         <audio className='allsound2' controls style={{display:'none'}}>
          <source src={bingowin}></source>
         </audio> 

         <audio className='allsound3' controls style={{display:'none'}}>
          <source src={yotbingo}></source>
         </audio> 
         <audio className='allsound4' controls style={{display:'none'}}>
          <source src={not}></source>
         </audio>

          <audio className='playsound1' controls style={{display:'none'}}>
          <source src={oneany}></source>
         </audio>

         <audio className='playsound2' controls style={{display:'none'}}>
          <source src={twoany}></source>
         </audio>

         <audio className='playsound3' controls style={{display:'none'}}>
          <source src={threeany}></source>
         </audio>

         <audio className='playsound4' controls style={{display:'none'}}>
          <source src={twovertical}></source>
         </audio> 

         <audio className='playsound5' controls style={{display:'none'}}>
          <source src={twohorizontal}></source>
         </audio>

         <audio className='playsound6' controls style={{display:'none'}}>
          <source src={horizontalvertical}></source>
         </audio> 

         <audio className='playsound7' controls style={{display:'none'}}>
          <source src={twodiagonal}></source>
         </audio>



         <audio className='sound1' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound2' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound3' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound4' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound5' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound6' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound7' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound8' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound9' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound10' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound11' controls style={{display:'none'}}>  
          </audio>  
          <audio className='sound12' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound13' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound14' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound15' controls style={{display:'none'}}>  
          </audio>


          <audio className='sound16' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound17' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound18' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound19' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound20' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound21' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound22' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound23' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound24' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound25' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound26' controls style={{display:'none'}}>  
          </audio>  
          <audio className='sound27' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound28' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound29' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound30' controls style={{display:'none'}}>  
          </audio>


          <audio className='sound31' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound32' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound33' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound34' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound35' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound36' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound37' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound38' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound39' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound40' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound41' controls style={{display:'none'}}>  
          </audio>  
          <audio className='sound42' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound43' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound44' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound45' controls style={{display:'none'}}>  
          </audio>


          <audio className='sound46' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound47' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound48' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound49' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound50' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound51' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound52' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound53' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound54' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound55' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound56' controls style={{display:'none'}}>  
          </audio>  
          <audio className='sound57' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound58' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound59' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound60' controls style={{display:'none'}}>  
          </audio>


          <audio className='sound61' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound62' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound63' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound64' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound65' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound66' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound67' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound68' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound69' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound70' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound71' controls style={{display:'none'}}>  
          </audio>  
          <audio className='sound72' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound73' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound74' controls style={{display:'none'}}>  
          </audio>
          <audio className='sound75' controls style={{display:'none'}}>  
          </audio> 
          </div>


           
            <div className="area">
                 <ul>
                    <li><button>B</button>
                        <ul className="b">
                           <li className="b1">1</li>
                           <li className="b2">2</li>
                           <li className="b3">3</li>
                           <li className="b4">4</li>
                           <li className="b5">5</li>
                           <li className="b6">6</li>
                           <li className="b7">7</li>
                           <li className="b8">8</li>
                           <li className="b9">9</li>
                           <li className="b10">10</li>
                           <li className="b11">11</li>
                           <li className="b12">12</li>
                           <li className="b13">13</li>
                           <li className="b14">14</li>
                           <li className="b15">15</li>
                        </ul>
                    </li>
                    <li><button>I</button>
                      <ul className="i">
                        <li className="b16">16</li>
                        <li className="b17">17</li>
                        <li className="b18">18</li>
                        <li className="b19">19</li>
                        <li className="b20">20</li>
                        <li className="b21">21</li>
                        <li className="b22">22</li>
                        <li className="b23">23</li>
                        <li className="b24">24</li>
                        <li className="b25">25</li>
                        <li className="b26">26</li>
                        <li className="b27">27</li>
                        <li className="b28">28</li>
                        <li className="b29">29</li>
                        <li className="b30">30</li>
                     </ul>
                    </li>
                    <li><button>N</button>
                      <ul className="n">
                        <li className="b31">31</li>
                        <li className="b32">32</li>
                        <li className="b33">33</li>
                        <li className="b34">34</li>
                        <li className="b35">35</li>
                        <li className="b36">36</li>
                        <li className="b37">37</li>
                        <li className="b38">38</li>
                        <li className="b39">39</li>
                        <li className="b40">40</li>
                        <li className="b41">41</li>
                        <li className="b42">42</li>
                        <li className="b43">43</li>
                        <li className="b44">44</li>
                        <li className="b45">45</li>
                     </ul>
                    </li>
                    <li><button>G</button>
                      <ul className="g">
                        <li className="b46">46</li>
                        <li className="b47">47</li>
                        <li className="b48">48</li>
                        <li className="b49">49</li>
                        <li className="b50">50</li>
                        <li className="b51">51</li>
                        <li className="b52">52</li>
                        <li className="b53">53</li>
                        <li className="b54">54</li>
                        <li className="b55">55</li>
                        <li className="b56">56</li>
                        <li className="b57">57</li>
                        <li className="b58">58</li>
                        <li className="b59">59</li>
                        <li className="b60">60</li>
                     </ul>
                    </li>
                    <li><button>O</button>
                      <ul className="o">
                        <li className="b61">61</li>
                        <li className="b62">62</li>
                        <li className="b63">63</li>
                        <li className="b64">64</li>
                        <li className="b65">65</li>
                        <li className="b66">66</li>
                        <li className="b67">67</li>
                        <li className="b68">68</li>
                        <li className="b69">69</li>
                        <li className="b70">70</li>
                        <li className="b71">71</li>
                        <li className="b72">72</li>
                        <li className="b73">73</li>
                        <li className="b74">74</li>
                        <li className="b75">75</li>
                     </ul>
                    </li>
                 </ul>
            </div>
        <div> 

<div className='newshow' style={{marginLeft:'250px'}}></div>

        <div className='cardNumber'>
  <div className='cardname'>
    <h2 onClick={()=>{
      const gt=document.querySelector('.cardNumber')
      gt.style.display='none';
    }} style={{padding:'5px',marginLeft:'450px',cursor:'pointer'}}>X</h2>
  <div className="login-content">
        
          <img src={avat2} style={{borderRadius: '30px',marginTop:'-200px',marginLeft:'200px'}} alt=""/>
           <div className='cnone'></div>
           <br/><br/><br/>
          <div className="callerNumberOne">
            <div className="i">
              <i className="fas fa-user"></i>
            </div>
            <div className="div">
              <br/>
              <input type="text" className="cardinput" placeholder='የአሸናፊውን ካርድ ቁጥር ያስገቡ'/><br/>
            </div>
          </div>
         
         <button style={{marginLeft:'-690px',width:'200px',marginTop:'250px',fontSize:'16px'}} onClick={()=>{
           document.querySelector('.cardNumber').style.display='block'
           const cheek=`card${document.querySelector('.cardinput').value}`
           let yam=false; 
           if(takencards.length===0){
                alert('ተጫዋች የለም  ጨዋታው አልጀመረም ')
           }else{
               //ahun zgun be game status aregagte
               
               if(sessionStorage.getItem('game')===1){

               }
               else if(sessionStorage.getItem('game')===2){

               }
               

               else{
                  for(let mv=0;mv<takencards.length+1;mv++){
                      if(cheek===takencards[mv]){
                           yam=true;
                      }
                  }
                  if(yam===true){
                       /////////////////////////////
                       //      Your Logic Is her  //
                       /////////////////////////////
                       let wincard=Number(document.querySelector('.cardinput').value)-1
                       //console.log(cardNumbers[document.querySelector('.cardinput').value-1])
                       //now cheak weather the number is called or not
                      
                 //write starting new code that erase gold color cardNumbers
                
                   //  document.querySelector('.closecheakcard p').style.backgroundColor='green';
                      
               //ending of gold color cardNumber called       
                       if(bingo[puase]<=15){
                          let tre=false;
                            for(let x=0;x<cardNumbers[wincard].b.length;x++){
                                if(bingo[puase]===cardNumbers[wincard].b[x]){
                                   //alert(`${bingo[puase]} is not exist in your card`)
                                    tre=true
                                    break;
                                 }                               
                            }
                            if(tre===true){
                                ////////////////////////////////
                               //   two vertical close Logic //         
                               //                           //
                               //////////////////////////////
                                  //   alert(`${bingo[puase]} is exist on these card`)
                                //now display user choosen card number
                                let xm=document.querySelector('.closecheakcard')
                                xm.style.display='block'    
//////////////////////////////////////////////////
            

                const each=['one1','two2','three3','four4','five5']; 
                const each1=['one1','two2','three3','four4','five5'];  
                for(let u=0;u<cardNumbers[wincard].b.length;u++){
                       //let bgg=false
                       each[u]=document.createElement('p');
                       each[u].textContent=cardNumbers[wincard].b[u]
                //game called number cheaking.....
      

                       each[u].style.backgroundColor='green'
                       each[u].style.color='#fff'
                for(let mr=0;mr<calledNumber.b.length;mr++){
                   
                     if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                        each[u].style.backgroundColor='gold'
                        each[u].style.color='black'
                     }
                 
                }  
                      // n.style.backgroundColor='green';
                       //each[u].style.position='absolute'
                       
                       each[u].style.color='#fff';
                       each[u].style.padding='10px';
                       each[u].style.width='40px'
                       //n.style.height='50px'
                       each[u].style.borderRadius='10px';
                       each[u].style.margin='0px'
                       //each[u].style.paddingTop='15px'
                       //each[u].style.marginLeft='40px'
                       //each[u].style.marginTop='130px';
                      // xm.style.position='absolute'
                      // xm.style.marginTop='50px'
                       xm.appendChild(each[u])
                        
                      const brr=document.createElement('br')
                      xm.appendChild(brr)  
                  
                       
                    }

                    for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
                     
                     each1[ui]=document.createElement('p');
                     each1[ui].textContent=cardNumbers[wincard].i[ui]
                      each1[ui].style.backgroundColor='green';
                      each[ui].style.color='#fff'    
                for(let mrr=0;mrr<calledNumber.i.length;mrr++){
                     if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mrr])){
                        each1[ui].style.backgroundColor='gold'
                        each[ui].style.color='black'
                     }
                } 

                    
                     each1[ui].style.position='absolute'
                     //each1[ui].style.color='#fff';
                     each1[ui].style.padding='10px';
                     each1[ui].style.width='40px'
                     each1[ui].style.borderRadius='10px';
                     each1[ui].style.marginLeft='55px'
                     each1[ui].style.marginTop='-275px';
                     //n.style.marginBottom='10px'
                     xm.appendChild(each1[ui])

                     const brr=document.createElement('br')
                     xm.appendChild(brr)

                     const brrr=document.createElement('br')
                     xm.appendChild(brrr)

                     const brrrr=document.createElement('br')
                     xm.appendChild(brrrr)

                    
                  }   


                  for(let un=0;un<cardNumbers[wincard].n.length;un++){
                     
                     const ni=document.createElement('p');
                     ni.textContent=cardNumbers[wincard].n[un]
                     ni.style.backgroundColor='green';
                     ni.style.color='#fff'
               for(let mr=0;mr<calledNumber.n.length;mr++){
                     if(cardNumbers[wincard].n[un]===calledNumber.n[mr]){
                        ni.style.backgroundColor='gold'
                        ni.style.color='black'
                     }
                     if(cardNumbers[wincard].n[un]==='free'){
                          ni.style.backgroundColor='gold';
                          ni.style.color='black'
                     }

                }
                     ni.style.position='absolute'
                    // ni.style.color='#fff';
                     ni.style.padding='10px';
                     ni.style.width='40px'
                     ni.style.borderRadius='10px';
                     ni.style.marginLeft='110px'
                     ni.style.marginTop='-545px'
                     xm.appendChild(ni)

                     const brr=document.createElement('br')
                     xm.appendChild(brr)

                     const brrr=document.createElement('br')
                     xm.appendChild(brrr)

                     const brrrr=document.createElement('br')
                     xm.appendChild(brrrr)

                  
                  }   
                    

                  for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
                     
                     const ng=document.createElement('p');
                     ng.textContent=cardNumbers[wincard].g[ug]
                     ng.style.position='absolute'
                     ng.style.backgroundColor='green';
                     ng.style.color='#fff'
                 for(let mr=0;mr<calledNumber.g.length;mr++){
                     if(cardNumbers[wincard].g[ug]===calledNumber.g[mr]){
                        ng.style.backgroundColor='gold'
                        ng.style.color='black'
                     }
                }

                    // ng.style.color='#fff';
                     ng.style.padding='10px';
                     ng.style.width='40px'
                     ng.style.borderRadius='10px';
                     ng.style.marginLeft='165px'
                     ng.style.marginTop='-815px';
                     xm.appendChild(ng)

                     const brr=document.createElement('br')
                     xm.appendChild(brr)

                     const brrr=document.createElement('br')
                     xm.appendChild(brrr)

                     const brrrr=document.createElement('br')
                     xm.appendChild(brrrr)
                    
                  }   



                  for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
                     
                     const no=document.createElement('p');
                     no.textContent=cardNumbers[wincard].o[uo]
                     no.style.position='absolute'
                     no.style.backgroundColor='green';
                     no.style.color='#fff'
               for(let mr=0;mr<calledNumber.o.length;mr++){
                   if(cardNumbers[wincard].o[uo]===calledNumber.o[mr]){
                        no.style.backgroundColor='gold'
                        no.style.color='black'
                     }
                }
                     //no.style.color='#fff';
                     no.style.padding='10px';
                     no.style.width='40px'
                     no.style.borderRadius='10px';
                     no.style.marginLeft='220px'
                     no.style.marginTop='-1085px';
                     xm.appendChild(no)

                     const brr=document.createElement('br')
                     xm.appendChild(brr)

                     const brrr=document.createElement('br')
                     xm.appendChild(brrr)

                     const brrrr=document.createElement('br')
                     xm.appendChild(brrrr)
                    
                  }   


                  const crdname=document.createElement('p')
                  crdname.textContent=cardNumbers[wincard].cardname
                  crdname.style.color='black'
                  crdname.style.backgroundColor='rgb(211, 218, 248)';
                  crdname.style.padding='10px'
                  crdname.style.color="black";
                  crdname.style.width='230px';
                  crdname.style.textAlign='center';
                  crdname.style.fontFamily='arial'
                  crdname.style.border='none'
                  crdname.style.marginTop='-1100px'
                  xm.appendChild(crdname);
     
                 const winbtn=document.createElement('button')
                 winbtn.textContent='ዘግትዋል';
                 winbtn.style.backgroundColor='blue';
                 winbtn.style.padding='10px';
                 winbtn.style.fontSize='15px';
                 winbtn.style.borderRadius='0px';
                 winbtn.style.border='none';
                 winbtn.style.width='100px'
                 winbtn.style.margin='5px';
                 winbtn.style.color='#fff';
                 winbtn.style.cursor='pointer'
                 winbtn.addEventListener('click',()=>{
                    number_of_players=0;                    
                 
                     calledNumber.b=[]
                     calledNumber.i=[]
                     calledNumber.n=[]
                     calledNumber.g=[]
                     calledNumber.o=[]
                 
                  const winn2=document.querySelector('.allsound2');
                  winn2.play();
                  

                   const yai=document.createElement('img')
                   yai.setAttribute('src',gamepictwo)
                   yai.style.width='200px';
                   yai.style.height='200px';
                   yai.style.borderRadius='80px';
                   yai.style.position='absolute';
                   yai.style.marginLeft='130px';
                   yai.style.marginTop='-400px';
                   yai.style.border='4px solid gold'
                   document.querySelector('.cardNumber').appendChild(yai)

                   const ya=document.createElement('p');
                   ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
                   ya.style.padding='20px';
                   ya.style.backgroundColor='rgb(247, 170, 25)'
                   ya.style.color='black';
                   ya.style.position='absolute';
                   ya.style.marginLeft='80px';
                   ya.style.marginTop='-230px'
                   ya.style.border='3px solid gold'
                   document.querySelector('.cardNumber').appendChild(ya);
                  
                    

                  const newgame=document.createElement('button')
                  newgame.textContent='አዲስ ጨዋታ ጀምር';
                  newgame.style.backgroundColor='gold';
                  newgame.style.textAlign='center';
                  newgame.style.position='absolute';
                  newgame.style.marginLeft='120px';
                  newgame.style.marginTop='-160px'
                  newgame.style.padding='20px'
                  newgame.style.cursor='pointer'
                  newgame.style.width='200px';
                  newgame.style.border='none'
                  newgame.style.backgroundColor='rgb(151, 14, 201)'
                  newgame.style.color='#fff';
                  newgame.addEventListener('click',()=>{
                     number_of_players=0;
                     gameIndicatorValue=1
                     for(let k=0;k<actual_bingo_number.length;k++){
                        document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                       // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                        document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                    }
                    
                     
                    //document.querySelector('.num').innerHTML=''
                     //newgame.innerHTML='';
                     //xm.innerHTML='';
                     actual_bingo_number=[];
                     takencards=[];
                     xm.innerHTML=''
                     
                     document.querySelector('.registercards').innerHTML=''
                     //clearInterval(caller);
                     document.querySelector('.cardNumber').style.display='none'
                     ya.remove()
                     yai.remove()
                     newgame.remove();
                     document.querySelector('.bt1').style.backgroundColor='black';
                  document.querySelector('.btnn1').style.backgroundColor='black';
                  document.querySelector('.bt1').style.color='gold';
                  document.querySelector('.btnn1').style.color='gold';


////////////////////// Time count Starting session //////////////////////
/*
let a=2;
let b=60;
document.querySelector('.counterone').innerHTML=''
let count=setInterval(()=>{
      let xn=document.querySelector('.counterone')
      xn.style.marginLeft='-200px'
      let dx=document.createElement('p')
      dx.textContent=`${a}:${b}`;
      dx.style.position='absolute'
      dx.style.backgroundColor='gold'
      dx.style.width='200px';
      dx.style.color='black'
      dx.style.height='50px'
      dx.style.padding='auto';
      dx.style.borderRadius='10px'
      dx.style.fontSize='30px'
      dx.style.fontWeight='bold'
      dx.style.textAlign='center'
      xn.appendChild(dx)
      b--;
      if(b===0){
         b=60
         a--;
         if(a===-1){
            a=2
         }
        
      }

      if(a===0 && b<=30){
         xn.style.backgroundColor='red';
         dx.style.backgroundColor='red';
         xn.style.color='#fff';
      }
      if(a===0 && b===1){
         //xn.style.width='300px'
         //xn.style.padding='20px'
         //dx.style.height='30px'
         dx.style.fontSize='20px'
         dx.style.color='#fff'
         dx.innerHTML='ጨዋታው ሊጀምር ነው!!'
         clearInterval(count)
      }
     


   },1000)



*/
//////////////////// ending time counting session ///////////////////
                 document.querySelector('.num').innerHTML=''

                  })
                  document.querySelector('.cardNumber').appendChild(newgame); 
                  
               
                  })
                 xm.appendChild(winbtn);
     
                 const loss=document.createElement('button')
                 loss.textContent='አልዘጋም';
                 loss.style.backgroundColor='red';
                 loss.style.cursor='pointer'
                 loss.style.fontSize='15px';
                 loss.style.width='100px'
                 loss.style.padding='10px';
                 loss.style.border='none';
                 loss.style.marginLeft='20px';
                 loss.style.position='absolute'
                 loss.style.marginTop='8px'
                 loss.style.borderRadius='0px';
                 loss.style.color='#fff';
loss.addEventListener('click',()=>{
   ////////////////////not closed...////////////////
    
   for(let j=0;j<takencards.length;j++){
       if(takencards[j]===cardNumbers[wincard].cardname){
         takencards[j]=0;
       }
   }
   const yai=document.createElement('img')
   yai.setAttribute('src',loss)
   yai.style.width='200px';
   yai.style.height='200px';
   yai.style.borderRadius='80px';
   yai.style.position='absolute';
   yai.style.marginLeft='130px';
   yai.style.marginTop='-400px';
   yai.style.border='4px solid gold'
   document.querySelector('.cardNumber').appendChild(yai)

   const ya=document.createElement('p');
   ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
   ya.style.padding='20px';
   ya.style.backgroundColor='red'
   ya.style.color='#fff';
   ya.style.position='absolute';
   ya.style.marginLeft='80px';
   ya.style.marginTop='-230px'
   ya.style.border='3px solid gold'
   document.querySelector('.cardNumber').appendChild(ya);
   
   document.querySelector('.allsound4').play();
   /////////////////////end of not closed...//////////
})
                 xm.appendChild(loss);
     
                 const xx=document.createElement('br')
                 xm.appendChild(xx)
     
                 const xxx=document.createElement('br')
                 xm.appendChild(xxx)
     
                 const xxxx=document.createElement('br')
                 xm.appendChild(xxxx)
     
                 const xxxxx=document.createElement('br')
                 xm.appendChild(xxxxx)                 
////////////////////////////////////////////
                              
                                 }
                            else{
                              //alert(`${bingo[puase]} is not exist in your card`)

                              /////////////  starting of displaying card number
                              //             even if the number is not called or passed
                              //
                              /////////////////////////////////////////////////////////

                              let xm=document.querySelector('.closecheakcard')
                              xm.style.display='block'    
    //////////////////////////////////////////////////
                
              for(let u=0;u<cardNumbers[wincard].b.length;u++){
                   
                     const n=document.createElement('p');
                     n.textContent=cardNumbers[wincard].b[u]
                     n.style.backgroundColor='green';
                     n.style.color='#fff';
    
                for(let mr=0;mr<calledNumber.b.length;mr++){
                       
                         if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                            n.style.backgroundColor='gold'
                            n.style.color='black'
                         }
                     
                    } 
    
                     n.style.padding='10px';
                     n.style.width='40px'
                     n.style.borderRadius='10px';
                     n.style.margin='0px'
                     xm.appendChild(n)
    
                     const brr=document.createElement('br')
                     xm.appendChild(brr)
                
                  }
    
                  for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
                   
                   const nx=document.createElement('p');
                   nx.textContent=cardNumbers[wincard].i[ui]
                   nx.style.backgroundColor='green';
                   nx.style.position='absolute'
                   nx.style.color='#fff';
    
                     for(let mr=0;mr<calledNumber.i.length;mr++){
                       
                         if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                            nx.style.backgroundColor='gold'
                            nx.style.color='black'
                         }
                     
                    } 
                   nx.style.padding='10px';
                   nx.style.width='40px'
                   nx.style.borderRadius='10px';
                   nx.style.marginLeft='55px'
                   nx.style.marginTop='-275px';
                   //n.style.marginBottom='10px'
                   xm.appendChild(nx)
    
                   const brr=document.createElement('br')
                   xm.appendChild(brr)
    
                   const brrr=document.createElement('br')
                   xm.appendChild(brrr)
    
                   const brrrr=document.createElement('br')
                   xm.appendChild(brrrr)
    
                  
                }   
    
    
                for(let un=0;un<cardNumbers[wincard].n.length;un++){
                   
                   const ni=document.createElement('p');
                   ni.textContent=cardNumbers[wincard].n[un]
                   ni.style.backgroundColor='green';
                   ni.style.position='absolute'
                   ni.style.color='#fff';
    
                    for(let mr=0;mr<calledNumber.n.length;mr++){
                       
                         if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                            ni.style.backgroundColor='gold'
                            ni.style.color='black'
                         }
                         if(cardNumbers[wincard].n[un]==='free'){ 
                              ni.style.backgroundColor='gold';
                              ni.style.color='black'
                         }
                     
                    } 
    
                   ni.style.padding='10px';
                   ni.style.width='40px'
                   ni.style.borderRadius='10px';
                   ni.style.marginLeft='110px'
                   ni.style.marginTop='-545px'
                   xm.appendChild(ni)
    
                   const brr=document.createElement('br')
                   xm.appendChild(brr)
    
                   const brrr=document.createElement('br')
                   xm.appendChild(brrr)
    
                   const brrrr=document.createElement('br')
                   xm.appendChild(brrrr)
    
                
                }   
                  
    
                for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
                   
                   const ng=document.createElement('p');
                   ng.textContent=cardNumbers[wincard].g[ug]
                   ng.style.position='absolute'
                   ng.style.backgroundColor='green';
                   ng.style.color='#fff';
    
                 for(let mr=0;mr<calledNumber.g.length;mr++){
                       
                         if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                            ng.style.backgroundColor='gold'
                            ng.style.color='black'
                         }
                     
                    } 
    
                   ng.style.padding='10px';
                   ng.style.width='40px'
                   ng.style.borderRadius='10px';
                   ng.style.marginLeft='165px'
                   ng.style.marginTop='-815px';
                   xm.appendChild(ng)
    
                   const brr=document.createElement('br')
                   xm.appendChild(brr)
    
                   const brrr=document.createElement('br')
                   xm.appendChild(brrr)
    
                   const brrrr=document.createElement('br')
                   xm.appendChild(brrrr)
                  
                }   
    
    
    
                for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
                   
                   const no=document.createElement('p');
                   no.textContent=cardNumbers[wincard].o[uo]
                   no.style.position='absolute'
                   no.style.backgroundColor='green';
                   no.style.color='#fff';
    
                for(let mr=0;mr<calledNumber.o.length;mr++){
                       
                         if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                            no.style.backgroundColor='gold'
                            no.style.color='black'
                         }
                     
                    } 
    
                   no.style.padding='10px';
                   no.style.width='40px'
                   no.style.borderRadius='10px';
                   no.style.marginLeft='220px'
                   no.style.marginTop='-1085px';
                   xm.appendChild(no)
    
                   const brr=document.createElement('br')
                   xm.appendChild(brr)
    
                   const brrr=document.createElement('br')
                   xm.appendChild(brrr)
    
                   const brrrr=document.createElement('br')
                   xm.appendChild(brrrr)
                  
                }   
    
    
                const crdname=document.createElement('p')
                crdname.textContent=cardNumbers[wincard].cardname
                crdname.style.color='black'
                crdname.style.backgroundColor='rgb(211, 218, 248)';
                crdname.style.padding='10px'
                crdname.style.color="black";
                crdname.style.width='230px';
                crdname.style.textAlign='center';
                crdname.style.fontFamily='arial'
                crdname.style.border='none'
                crdname.style.marginTop='-1100px'
                xm.appendChild(crdname);
    
               const winbtn=document.createElement('button')
               winbtn.textContent='ዘግትዋል';
               winbtn.style.backgroundColor='blue';
               winbtn.style.padding='10px';
               winbtn.style.fontSize='15px';
               winbtn.style.borderRadius='0px';
               winbtn.style.border='none';
               winbtn.style.width='100px'
               winbtn.style.margin='5px';
               winbtn.style.color='#fff';
               winbtn.style.cursor='pointer'
               winbtn.addEventListener('click',()=>{
                  document.querySelector('.num').innerHTML=''
                calledNumber.b=[]
                calledNumber.i=[]
                calledNumber.n=[]
                calledNumber.g=[]
                calledNumber.o=[]
                
                const winn2=document.querySelector('.allsound2');
                winn2.play();
    
                 const yai=document.createElement('img')
                 yai.setAttribute('src',gamepictwo)
                 yai.style.width='200px';
                 yai.style.height='200px';
                 yai.style.borderRadius='80px';
                 yai.style.position='absolute';
                 yai.style.marginLeft='130px';
                 yai.style.marginTop='-400px';
                 yai.style.border='4px solid gold'
                 document.querySelector('.cardNumber').appendChild(yai)
    
                 const ya=document.createElement('p');
                 ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
                 ya.style.padding='20px';
                 ya.style.backgroundColor='rgb(247, 170, 25)'
                 ya.style.color='black';
                 ya.style.position='absolute';
                 ya.style.marginLeft='80px';
                 ya.style.marginTop='-230px'
                 ya.style.border='3px solid gold'
                 document.querySelector('.cardNumber').appendChild(ya);
                
                  
    
                const newgame=document.createElement('button')
                newgame.textContent='አዲስ ጨዋታ ጀምር';
                newgame.style.backgroundColor='gold';
                newgame.style.textAlign='center';
                newgame.style.position='absolute';
                newgame.style.marginLeft='120px';
                newgame.style.marginTop='-160px'
                newgame.style.padding='20px'
                newgame.style.cursor='pointer'
                newgame.style.width='200px';
                newgame.style.border='none'
                newgame.style.backgroundColor='rgb(151, 14, 201)'
                newgame.style.color='#fff';
                newgame.addEventListener('click',()=>{
                  document.querySelector('.num').innerHTML=''
                   number_of_players=0;
                   gameIndicatorValue=1
                   for(let k=0;k<actual_bingo_number.length;k++){
                      document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                     // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                      document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                  }
                  
                   
                  //document.querySelector('.num').innerHTML=''
                   //newgame.innerHTML='';
                   //xm.innerHTML='';
                   actual_bingo_number=[];
                   takencards=[];
                   xm.innerHTML=''
                   
                   document.querySelector('.registercards').innerHTML=''
                   //clearInterval(caller);
                   document.querySelector('.cardNumber').style.display='none'
                   ya.remove()
                   yai.remove()
                   newgame.remove();
                   document.querySelector('.bt1').style.backgroundColor='black';
                   document.querySelector('.btnn1').style.backgroundColor='black';
                   document.querySelector('.bt1').style.color='gold';
                   document.querySelector('.btnn1').style.color='gold';  
     ////////////////////// Time count Starting session //////////////////////
  
    
    ///////////////////// ending time counting session ///////////////////
    
                })
                document.querySelector('.cardNumber').appendChild(newgame); 
                
             
                })
    
               xm.appendChild(winbtn);
    
               const loss=document.createElement('button')
               loss.textContent='አልዘጋም';
               loss.style.backgroundColor='red';
               loss.style.cursor='pointer'
               loss.style.fontSize='15px';
               loss.style.width='100px'
               loss.style.padding='10px';
               loss.style.border='none';
               loss.style.marginLeft='20px';
               loss.style.position='absolute'
               loss.style.marginTop='8px'
               loss.style.borderRadius='0px';
               loss.style.color='#fff';
               loss.addEventListener('click',()=>{
                ////////////////////not closed...////////////////
                for(let j=0;j<takencards.length;j++){
                  if(takencards[j]===cardNumbers[wincard].cardname){
                    takencards[j]=0;
                  }
              }

                const yai=document.createElement('img')
                yai.setAttribute('src',lossx)
                yai.style.width='200px';
                yai.style.height='200px';
                yai.style.borderRadius='80px';
                yai.style.position='absolute';
                yai.style.marginLeft='130px';
                yai.style.marginTop='-400px';
                yai.style.border='4px solid gold'
                document.querySelector('.cardNumber').appendChild(yai)
             
                const ya=document.createElement('p');
                ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
                ya.style.padding='20px';
                ya.style.backgroundColor='red'
                ya.style.color='#fff';
                ya.style.position='absolute';
                ya.style.marginLeft='80px';
                ya.style.marginTop='-230px'
                ya.style.border='3px solid gold'
                document.querySelector('.cardNumber').appendChild(ya);
                
                document.querySelector('.allsound4').play();
                /////////////////////end of not closed...//////////
             })
               xm.appendChild(loss);
    
               const xx4=document.createElement('br')
               xm.appendChild(xx4)
    
               const xxx4=document.createElement('br')
               xm.appendChild(xxx4)
    
               const xxxx4=document.createElement('br')
               xm.appendChild(xxxx4)
    
               const xxxxx4=document.createElement('br')
               xm.appendChild(xxxxx4)

                              /////////////   ending of displaying the card number is not exist
                              //
                              ////////////////////////////////////////////////////////////////
                              let xx=document.querySelector('.closecheak')
                              xx.style.position='absolute'
                              xx.style.marginLeft='500px';
                              xx.style.marginTop='-400px'
                              let xp=document.createElement('p')
                              xp.textContent=`${bingo[puase]} ቁጥር ካርድ ${document.querySelector('.cardinput').value} ቁጥር ላይ የለም`
                              xp.style.backgroundColor='red';
                              xp.style.color='#fff'
                              xp.style.margin='20px'
                              xp.style.padding='20px';
                              xp.style.borderRadius='10px'
                              xx.appendChild(xp)

                               let kx=document.createElement('p')
                              kx.textContent='X';
                              kx.style.position='absolute';
                              kx.style.color='#fff'
                              kx.style.marginTop='-75px';
                              kx.style.marginLeft='240px';
                              kx.style.borderRadius='10px';
                              kx.style.backgroundColor='gray'
                              kx.style.padding='5px'
                              kx.style.cursor='pointer'
                              kx.addEventListener('click',()=>{
                                 xx.innerHTML='';    
                              })

                              xx.appendChild(kx)

                            }
                      }

                      else if(bingo[puase]>=16 && bingo[puase]<=30){
                        let tre=false;
                        for(let x=0;x<cardNumbers[wincard].i.length;x++){
                            if(bingo[puase]===cardNumbers[wincard].i[x]){
                               //alert(`${bingo[puase]} is not exist in your card`)
                                tre=true
                                break;
                             }                               
                        }
                        if(tre===true){
                            ////////////////////////////////
                           //   two vertical close Logic //         
                           //                           //
                           //////////////////////////////
                          // alert(`${bingo[puase]} is exist on these card`)
                       
                           let xm=document.querySelector('.closecheakcard')
                           xm.style.display='block'    
//////////////////////////////////////////////////
             
           for(let u=0;u<cardNumbers[wincard].b.length;u++){
                
                  const n=document.createElement('p');
                  n.textContent=cardNumbers[wincard].b[u]
                  n.style.backgroundColor='green';
                  n.style.color='#fff';
                     //each[u].style.color='#fff'
                for(let mr=0;mr<calledNumber.b.length;mr++){
                   
                     if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                        n.style.backgroundColor='gold'
                        n.style.color='black'
                     }
                 
                }  

                  n.style.padding='10px';
                  n.style.width='40px'
                  n.style.borderRadius='10px';
                  n.style.margin='0px'
                  xm.appendChild(n)

                  const brr=document.createElement('br')
                  xm.appendChild(brr)

             
               }

               for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
                
                const nx=document.createElement('p');
                nx.textContent=cardNumbers[wincard].i[ui]
                nx.style.backgroundColor='green';
                nx.style.position='absolute'
                nx.style.color='#fff';

                  for(let mr=0;mr<calledNumber.i.length;mr++){
                   
                     if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                        nx.style.backgroundColor='gold'
                        nx.style.color='black'
                     }
                 
                } 
                nx.style.padding='10px';
                nx.style.width='40px'
                nx.style.borderRadius='10px';
                nx.style.marginLeft='55px'
                nx.style.marginTop='-275px';
                //n.style.marginBottom='10px'
                xm.appendChild(nx)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)

               
             }   


             for(let un=0;un<cardNumbers[wincard].n.length;un++){
                
                const ni=document.createElement('p');
                ni.textContent=cardNumbers[wincard].n[un]
                ni.style.backgroundColor='green';
                ni.style.position='absolute'
                ni.style.color='#fff';
            for(let mr=0;mr<calledNumber.n.length;mr++){
                   
                     if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                        ni.style.backgroundColor='gold'
                        ni.style.color='black'
                     }

                     if(cardNumbers[wincard].n[un]==='free'){
                          ni.style.backgroundColor='gold';
                          ni.style.color='black'
                     }
                 
                } 

                ni.style.padding='10px';
                ni.style.width='40px'
                ni.style.borderRadius='10px';
                ni.style.marginLeft='110px'
                ni.style.marginTop='-545px'
                xm.appendChild(ni)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)

             
             }   
               

             for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
                
                const ng=document.createElement('p');
                ng.textContent=cardNumbers[wincard].g[ug]
                ng.style.position='absolute'
                ng.style.backgroundColor='green';
                ng.style.color='#fff';
            
              for(let mr=0;mr<calledNumber.g.length;mr++){
                   
                     if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                        ng.style.backgroundColor='gold'
                        ng.style.color='black'
                     }
                 
                }     

                ng.style.padding='10px';
                ng.style.width='40px'
                ng.style.borderRadius='10px';
                ng.style.marginLeft='165px'
                ng.style.marginTop='-815px';
                xm.appendChild(ng)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)
               
             }   



             for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
                
                const no=document.createElement('p');
                no.textContent=cardNumbers[wincard].o[uo]
                no.style.position='absolute'
                no.style.backgroundColor='green';
                no.style.color='#fff';

            for(let mr=0;mr<calledNumber.o.length;mr++){
                   
                     if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                        no.style.backgroundColor='gold'
                        no.style.color='black'
                     }
                 
                } 
                no.style.padding='10px';
                no.style.width='40px'
                no.style.borderRadius='10px';
                no.style.marginLeft='220px'
                no.style.marginTop='-1085px';
                xm.appendChild(no)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)
               
             }   



             const crdname=document.createElement('p')
             crdname.textContent=cardNumbers[wincard].cardname
             crdname.style.color='black'
             crdname.style.backgroundColor='rgb(211, 218, 248)';
             crdname.style.padding='10px'
             crdname.style.color="black";
             crdname.style.width='230px';
             crdname.style.textAlign='center';
             crdname.style.fontFamily='arial'
             crdname.style.border='none'
             crdname.style.marginTop='-1100px'
             xm.appendChild(crdname);

            const winbtn=document.createElement('button')
            winbtn.textContent='ዘግትዋል';
            winbtn.style.backgroundColor='blue';
            winbtn.style.padding='10px';
            winbtn.style.fontSize='15px';
            winbtn.style.borderRadius='0px';
            winbtn.style.border='none';
            winbtn.style.width='100px'
            winbtn.style.margin='5px';
            winbtn.style.color='#fff';
            winbtn.style.cursor='pointer'
            winbtn.addEventListener('click',()=>{
               document.querySelector('.num').innerHTML=''
               number_of_players=0;
               calledNumber.b=[]
               calledNumber.i=[]
               calledNumber.n=[]
               calledNumber.g=[]
               calledNumber.o=[]

               const winn2=document.querySelector('.allsound2');
               winn2.play();
               
                const yai=document.createElement('img')
                yai.setAttribute('src',gamepictwo)
                yai.style.width='200px';
                yai.style.height='200px';
                yai.style.borderRadius='80px';
                yai.style.position='absolute';
                yai.style.marginLeft='130px';
                yai.style.marginTop='-400px';
                yai.style.border='4px solid gold'
                document.querySelector('.cardNumber').appendChild(yai)

                const ya=document.createElement('p');
                ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
                ya.style.padding='20px';
                ya.style.backgroundColor='rgb(247, 170, 25)'
                ya.style.color='black';
                ya.style.position='absolute';
                ya.style.marginLeft='80px';
                ya.style.marginTop='-230px'
                ya.style.border='3px solid gold'
                document.querySelector('.cardNumber').appendChild(ya);
               
                 

               const newgame=document.createElement('button')
               newgame.textContent='አዲስ ጨዋታ ጀምር';
               newgame.style.backgroundColor='gold';
               newgame.style.textAlign='center';
               newgame.style.position='absolute';
               newgame.style.marginLeft='120px';
               newgame.style.marginTop='-160px'
               newgame.style.padding='20px'
               newgame.style.cursor='pointer'
               newgame.style.width='200px';
               newgame.style.border='none'
               newgame.style.backgroundColor='rgb(151, 14, 201)'
               newgame.style.color='#fff';
               newgame.addEventListener('click',()=>{
                  number_of_players=0;
                  for(let k=0;k<actual_bingo_number.length;k++){
                     document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                    // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                     document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                 }
                 
                  
                 //document.querySelector('.num').innerHTML=''
                  //newgame.innerHTML='';
                  //xm.innerHTML='';
                  actual_bingo_number=[];
                  takencards=[];
                  xm.innerHTML=''
                  
                  document.querySelector('.registercards').innerHTML=''
                  //clearInterval(caller);
                  document.querySelector('.cardNumber').style.display='none'
                  ya.remove()
                  yai.remove()
                  newgame.remove();
                  document.querySelector('.bt1').style.backgroundColor='black';
                  document.querySelector('.btnn1').style.backgroundColor='black';
                  document.querySelector('.bt1').style.color='gold';
                  document.querySelector('.btnn1').style.color='gold';
////////////////////// Time count Starting session //////////////////////
/*
let a=2;
let b=60;
document.querySelector('.counterone').innerHTML=''
let count=setInterval(()=>{
   let xn=document.querySelector('.counterone')
   xn.style.marginLeft='-200px'
   let dx=document.createElement('p')
   dx.textContent=`${a}:${b}`;
   dx.style.position='absolute'
   dx.style.backgroundColor='gold'
   dx.style.width='200px';
   dx.style.color='black'
   dx.style.height='50px'
   dx.style.padding='auto';
   dx.style.borderRadius='10px'
   dx.style.fontSize='30px'
   dx.style.fontWeight='bold'
   dx.style.textAlign='center'
   xn.appendChild(dx)
   b--;
   if(b===0){
      b=60
      a--;
      if(a===-1){
         a=2
      }
     
   }

   if(a===0 && b<=30){
      xn.style.backgroundColor='red';
      dx.style.backgroundColor='red';
      xn.style.color='#fff';
   }
   if(a===0 && b===1){
      //xn.style.width='300px'
      //xn.style.padding='20px'
      //dx.style.height='30px'
      dx.style.fontSize='20px'
      dx.style.color='#fff'
      dx.innerHTML='ጨዋታው ሊጀምር ነው!!'
      clearInterval(count)
   }
  


},1000)

*/
///////////////////// ending time counting session ///////////////////

               })
               document.querySelector('.cardNumber').appendChild(newgame); 
               
            
               })

            xm.appendChild(winbtn);

            const loss=document.createElement('button')
            loss.textContent='አልዘጋም';
            loss.style.backgroundColor='red';
            loss.style.cursor='pointer'
            loss.style.fontSize='15px';
            loss.style.width='100px'
            loss.style.padding='10px';
            loss.style.border='none';
            loss.style.marginLeft='20px';
            loss.style.position='absolute'
            loss.style.marginTop='8px'
            loss.style.borderRadius='0px';
            loss.style.color='#fff';
            loss.addEventListener('click',()=>{
               ////////////////////not closed...////////////////
               for(let j=0;j<takencards.length;j++){
                  if(takencards[j]===cardNumbers[wincard].cardname){
                    takencards[j]=0;
                  }
              } 

               const yai=document.createElement('img')
               yai.setAttribute('src',lossx)
               yai.style.width='200px';
               yai.style.height='200px';
               yai.style.borderRadius='80px';
               yai.style.position='absolute';
               yai.style.marginLeft='130px';
               yai.style.marginTop='-400px';
               yai.style.border='4px solid gold'
               document.querySelector('.cardNumber').appendChild(yai)
            
               const ya=document.createElement('p');
               ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
               ya.style.padding='20px';
               ya.style.backgroundColor='red'
               ya.style.color='#fff';
               ya.style.position='absolute';
               ya.style.marginLeft='80px';
               ya.style.marginTop='-230px'
               ya.style.border='3px solid gold'
               document.querySelector('.cardNumber').appendChild(ya);
               
               document.querySelector('.allsound4').play();
               /////////////////////end of not closed...//////////
            })

            xm.appendChild(loss);

            const xx=document.createElement('br')
            xm.appendChild(xx)

            const xxx=document.createElement('br')
            xm.appendChild(xxx)

            const xxxx=document.createElement('br')
            xm.appendChild(xxxx)

            const xxxxx=document.createElement('br')
            xm.appendChild(xxxxx)            
////////////////////////////////////////////
                       
                       
                       
                       
                        }
                        else{
                         /// alert(`${bingo[puase]} is not exist in your card`)

                         /// starting, displaying the card number even if ///
                         //
                         /////////////////////////////////////////////////

                         let xm=document.querySelector('.closecheakcard')
                         xm.style.display='block'    
//////////////////////////////////////////////////
           
         for(let u=0;u<cardNumbers[wincard].b.length;u++){
              
                const n=document.createElement('p');
                n.textContent=cardNumbers[wincard].b[u]
                n.style.backgroundColor='green';
                n.style.color='#fff';

           for(let mr=0;mr<calledNumber.b.length;mr++){
                  
                    if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                       n.style.backgroundColor='gold'
                       n.style.color='black'
                    }
                
               } 

                n.style.padding='10px';
                n.style.width='40px'
                n.style.borderRadius='10px';
                n.style.margin='0px'
                xm.appendChild(n)

                const brr=document.createElement('br')
                xm.appendChild(brr)
           
             }

             for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
              
              const nx=document.createElement('p');
              nx.textContent=cardNumbers[wincard].i[ui]
              nx.style.backgroundColor='green';
              nx.style.position='absolute'
              nx.style.color='#fff';

                for(let mr=0;mr<calledNumber.i.length;mr++){
                  
                    if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                       nx.style.backgroundColor='gold'
                       nx.style.color='black'
                    }
                
               } 
              nx.style.padding='10px';
              nx.style.width='40px'
              nx.style.borderRadius='10px';
              nx.style.marginLeft='55px'
              nx.style.marginTop='-275px';
              //n.style.marginBottom='10px'
              xm.appendChild(nx)

              const brr=document.createElement('br')
              xm.appendChild(brr)

              const brrr=document.createElement('br')
              xm.appendChild(brrr)

              const brrrr=document.createElement('br')
              xm.appendChild(brrrr)

             
           }   


           for(let un=0;un<cardNumbers[wincard].n.length;un++){
              
              const ni=document.createElement('p');
              ni.textContent=cardNumbers[wincard].n[un]
              ni.style.backgroundColor='green';
              ni.style.position='absolute'
              ni.style.color='#fff';

               for(let mr=0;mr<calledNumber.n.length;mr++){
                  
                    if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                       ni.style.backgroundColor='gold'
                       ni.style.color='black'
                    }
                    if(cardNumbers[wincard].n[un]==='free'){
                          ni.style.backgroundColor='gold';
                          ni.style.color='black';
                        }
                
               } 

              ni.style.padding='10px';
              ni.style.width='40px'
              ni.style.borderRadius='10px';
              ni.style.marginLeft='110px'
              ni.style.marginTop='-545px'
              xm.appendChild(ni)

              const brr=document.createElement('br')
              xm.appendChild(brr)

              const brrr=document.createElement('br')
              xm.appendChild(brrr)

              const brrrr=document.createElement('br')
              xm.appendChild(brrrr)

           
           }   
             

           for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
              
              const ng=document.createElement('p');
              ng.textContent=cardNumbers[wincard].g[ug]
              ng.style.position='absolute'
              ng.style.backgroundColor='green';
              ng.style.color='#fff';

            for(let mr=0;mr<calledNumber.g.length;mr++){
                  
                    if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                       ng.style.backgroundColor='gold'
                       ng.style.color='black'
                    }
                
               } 

              ng.style.padding='10px';
              ng.style.width='40px'
              ng.style.borderRadius='10px';
              ng.style.marginLeft='165px'
              ng.style.marginTop='-815px';
              xm.appendChild(ng)

              const brr=document.createElement('br')
              xm.appendChild(brr)

              const brrr=document.createElement('br')
              xm.appendChild(brrr)

              const brrrr=document.createElement('br')
              xm.appendChild(brrrr)
             
           }   



           for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
              
              const no=document.createElement('p');
              no.textContent=cardNumbers[wincard].o[uo]
              no.style.position='absolute'
              no.style.backgroundColor='green';
              no.style.color='#fff';

           for(let mr=0;mr<calledNumber.o.length;mr++){
                  
                    if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                       no.style.backgroundColor='gold'
                       no.style.color='black'
                    }
                
               } 

              no.style.padding='10px';
              no.style.width='40px'
              no.style.borderRadius='10px';
              no.style.marginLeft='220px'
              no.style.marginTop='-1085px';
              xm.appendChild(no)

              const brr=document.createElement('br')
              xm.appendChild(brr)

              const brrr=document.createElement('br')
              xm.appendChild(brrr)

              const brrrr=document.createElement('br')
              xm.appendChild(brrrr)
             
           }   


           const crdname=document.createElement('p')
           crdname.textContent=cardNumbers[wincard].cardname
           crdname.style.color='black'
           crdname.style.backgroundColor='rgb(211, 218, 248)';
           crdname.style.padding='10px'
           crdname.style.color="black";
           crdname.style.width='230px';
           crdname.style.textAlign='center';
           crdname.style.fontFamily='arial'
           crdname.style.border='none'
           crdname.style.marginTop='-1100px'
           xm.appendChild(crdname);

          const winbtn=document.createElement('button')
          winbtn.textContent='ዘግትዋል';
          winbtn.style.backgroundColor='blue';
          winbtn.style.padding='10px';
          winbtn.style.fontSize='15px';
          winbtn.style.borderRadius='0px';
          winbtn.style.border='none';
          winbtn.style.width='100px'
          winbtn.style.margin='5px';
          winbtn.style.color='#fff';
          winbtn.style.cursor='pointer'
          winbtn.addEventListener('click',()=>{
            document.querySelector('.num').innerHTML=''
            number_of_players=0;
           calledNumber.b=[]
           calledNumber.i=[]
           calledNumber.n=[]
           calledNumber.g=[]
           calledNumber.o=[]
           
           const winn2=document.querySelector('.allsound2');
           winn2.play();

            const yai=document.createElement('img')
            yai.setAttribute('src',gamepictwo)
            yai.style.width='200px';
            yai.style.height='200px';
            yai.style.borderRadius='80px';
            yai.style.position='absolute';
            yai.style.marginLeft='130px';
            yai.style.marginTop='-400px';
            yai.style.border='4px solid gold'
            document.querySelector('.cardNumber').appendChild(yai)

            const ya=document.createElement('p');
            ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
            ya.style.padding='20px';
            ya.style.backgroundColor='rgb(247, 170, 25)'
            ya.style.color='black';
            ya.style.position='absolute';
            ya.style.marginLeft='80px';
            ya.style.marginTop='-230px'
            ya.style.border='3px solid gold'
            document.querySelector('.cardNumber').appendChild(ya);
           
             

           const newgame=document.createElement('button')
           newgame.textContent='አዲስ ጨዋታ ጀምር';
           newgame.style.backgroundColor='gold';
           newgame.style.textAlign='center';
           newgame.style.position='absolute';
           newgame.style.marginLeft='120px';
           newgame.style.marginTop='-160px'
           newgame.style.padding='20px'
           newgame.style.cursor='pointer'
           newgame.style.width='200px';
           newgame.style.border='none'
           newgame.style.backgroundColor='rgb(151, 14, 201)'
           newgame.style.color='#fff';
           newgame.addEventListener('click',()=>{
              number_of_players=0;
              gameIndicatorValue=1
              for(let k=0;k<actual_bingo_number.length;k++){
                 document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                 document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
             }
             
              
             //document.querySelector('.num').innerHTML=''
              //newgame.innerHTML='';
              //xm.innerHTML='';
              actual_bingo_number=[];
              takencards=[];
              xm.innerHTML=''
              
              document.querySelector('.registercards').innerHTML=''
              //clearInterval(caller);
              document.querySelector('.cardNumber').style.display='none'
              ya.remove()
              yai.remove()
              newgame.remove();
              document.querySelector('.bt1').style.backgroundColor='black';
              document.querySelector('.btnn1').style.backgroundColor='black';
              document.querySelector('.bt1').style.color='gold';
              document.querySelector('.btnn1').style.color='gold';  
////////////////////// Time count Starting session //////////////////////


///////////////////// ending time counting session ///////////////////

           })
           document.querySelector('.cardNumber').appendChild(newgame); 
           
        
           })

          xm.appendChild(winbtn);

          const loss=document.createElement('button')
          loss.textContent='አልዘጋም';
          loss.style.backgroundColor='red';
          loss.style.cursor='pointer'
          loss.style.fontSize='15px';
          loss.style.width='100px'
          loss.style.padding='10px';
          loss.style.border='none';
          loss.style.marginLeft='20px';
          loss.style.position='absolute'
          loss.style.marginTop='8px'
          loss.style.borderRadius='0px';
          loss.style.color='#fff';
          loss.addEventListener('click',()=>{
           ////////////////////not closed...////////////////
           for(let j=0;j<takencards.length;j++){
            if(takencards[j]===cardNumbers[wincard].cardname){
              takencards[j]=0;
            }
        }

           const yai=document.createElement('img')
           yai.setAttribute('src',lossx)
           yai.style.width='200px';
           yai.style.height='200px';
           yai.style.borderRadius='80px';
           yai.style.position='absolute';
           yai.style.marginLeft='130px';
           yai.style.marginTop='-400px';
           yai.style.border='4px solid gold'
           document.querySelector('.cardNumber').appendChild(yai)
        
           const ya=document.createElement('p');
           ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
           ya.style.padding='20px';
           ya.style.backgroundColor='red'
           ya.style.color='#fff';
           ya.style.position='absolute';
           ya.style.marginLeft='80px';
           ya.style.marginTop='-230px'
           ya.style.border='3px solid gold'
           document.querySelector('.cardNumber').appendChild(ya);
           
           document.querySelector('.allsound4').play();
           /////////////////////end of not closed...//////////
        })
          xm.appendChild(loss);

          const xx3=document.createElement('br')
          xm.appendChild(xx3)

          const xxx3=document.createElement('br')
          xm.appendChild(xxx3)

          const xxxx3=document.createElement('br')
          xm.appendChild(xxxx3)

          const xxxxx3=document.createElement('br')
          xm.appendChild(xxxxx3)

                         ////////// Ending of the card number even if the card 
                         ///        is not exist
                         //
                         ///////////////////////////////////////////////////
                          let xx=document.querySelector('.closecheak')
                              xx.style.position='absolute'
                              xx.style.marginLeft='500px';
                              xx.style.marginTop='-400px'
                              let xp=document.createElement('p')
                             xp.textContent=`${bingo[puase]} ቁጥር በ ${document.querySelector('.cardinput').value} ካርቴላ ላይ የለም`
                              xp.style.backgroundColor='red';
                              xp.style.color='#fff'
                              xp.style.margin='20px'
                              xp.style.padding='20px';
                              xp.style.borderRadius='10px'
                              xx.appendChild(xp)

                               let kx=document.createElement('p')
                              kx.textContent='X'
                              kx.style.position='absolute'
                              kx.style.marginTop='-75px';
                              kx.style.marginLeft='240px';
                              kx.style.borderRadius='20px';
                              kx.style.backgroundColor='gray'
                              kx.style.padding='5px'
                              kx.style.cursor='pointer'
                              kx.addEventListener('click',()=>{
                                 xx.innerHTML='';    
                              })

                              xx.appendChild(kx)
                            
                        }    

                      }


                      else if(bingo[puase]>=31 && bingo[puase]<=45){
                        let tre=false;
                        for(let x=0;x<cardNumbers[wincard].g.length;x++){
                            if(bingo[puase]===cardNumbers[wincard].n[x]){
                               //alert(`${bingo[puase]} is not exist in your card`)
                                tre=true
                                break;
                             }                               
                        }
                        if(tre===true){
                            ////////////////////////////////
                           //   two vertical close Logic //         
                           //                           //
                           //////////////////////////////
                           //alert(`${bingo[puase]} is exist on these card`)
                        
                           let xm=document.querySelector('.closecheakcard')
                           xm.style.display='block'    
//////////////////////////////////////////////////
             
           for(let u=0;u<cardNumbers[wincard].b.length;u++){
                
                  const n=document.createElement('p');
                  n.textContent=cardNumbers[wincard].b[u]
                  n.style.backgroundColor='green';
                  n.style.color='#fff';

               for(let mr=0;mr<calledNumber.b.length;mr++){
                   
                     if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                        n.style.backgroundColor='gold'
                        n.style.color='black'
                     }
                 
                } 
                  n.style.padding='10px';
                  n.style.width='40px'
                  n.style.borderRadius='10px';
                  n.style.margin='0px'
                  xm.appendChild(n)
                    
                 const bre=document.createElement('br')
                 xm.appendChild(bre)  
               }

               for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
                
                const nx=document.createElement('p');
                nx.textContent=cardNumbers[wincard].i[ui]
                nx.style.backgroundColor='green';
                nx.style.position='absolute'
                nx.style.color='#fff';

              for(let mr=0;mr<calledNumber.i.length;mr++){
                   
                     if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                        nx.style.backgroundColor='gold'
                        nx.style.color='black'
                     }
                 
                }     

                nx.style.padding='10px';
                nx.style.width='40px'
                nx.style.borderRadius='10px';
                nx.style.marginLeft='55px'
                nx.style.marginTop='-275px';
                //n.style.marginBottom='10px'
                xm.appendChild(nx)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)

               
             }   


             for(let un=0;un<cardNumbers[wincard].n.length;un++){
                
                const ni=document.createElement('p');
                ni.textContent=cardNumbers[wincard].n[un]
                ni.style.backgroundColor='green';
                ni.style.position='absolute'
                ni.style.color='#fff';

                  for(let mr=0;mr<calledNumber.n.length;mr++){
                   
                     if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                        ni.style.backgroundColor='gold'
                        ni.style.color='black'
                     }
                     if(cardNumbers[wincard].n[un]==='free'){
                        ni.style.backgroundColor='gold';
                        ni.style.color='black';
                     }
                 
                } 

                ni.style.padding='10px';
                ni.style.width='40px'
                ni.style.borderRadius='10px';
                ni.style.marginLeft='110px'
                ni.style.marginTop='-545px'
                xm.appendChild(ni)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)

             
             }   
               

             for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
                
                const ng=document.createElement('p');
                ng.textContent=cardNumbers[wincard].g[ug]
                ng.style.position='absolute'
                ng.style.backgroundColor='green';
                ng.style.color='#fff';

            for(let mr=0;mr<calledNumber.g.length;mr++){
                   
                     if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                        ng.style.backgroundColor='gold'
                        ng.style.color='black'
                     }
                 
                } 

                ng.style.padding='10px';
                ng.style.width='40px'
                ng.style.borderRadius='10px';
                ng.style.marginLeft='165px'
                ng.style.marginTop='-815px';
                xm.appendChild(ng)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)
               
             }   



             for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
                
                const no=document.createElement('p');
                no.textContent=cardNumbers[wincard].o[uo]
                no.style.position='absolute'
                no.style.backgroundColor='green';
                no.style.color='#fff';

            for(let mr=0;mr<calledNumber.o.length;mr++){
                   
                     if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                        no.style.backgroundColor='gold'
                        no.style.color='black'
                     }
                 
                } 

                no.style.padding='10px';
                no.style.width='40px'
                no.style.borderRadius='10px';
                no.style.marginLeft='220px'
                no.style.marginTop='-1085px';
                xm.appendChild(no)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)
               
             }   


             const crdname=document.createElement('p')
             crdname.textContent=cardNumbers[wincard].cardname
             crdname.style.color='black'
             crdname.style.backgroundColor='rgb(211, 218, 248)';
             crdname.style.padding='10px'
             crdname.style.color="black";
             crdname.style.width='230px';
             crdname.style.textAlign='center';
             crdname.style.fontFamily='arial'
             crdname.style.border='none'
             crdname.style.marginTop='-1100px'
             xm.appendChild(crdname);

            const winbtn=document.createElement('button')
            winbtn.textContent='ዘግትዋል';
            winbtn.style.backgroundColor='blue';
            winbtn.style.padding='10px';
            winbtn.style.fontSize='15px';
            winbtn.style.borderRadius='0px';
            winbtn.style.border='none';
            winbtn.style.width='100px'
            winbtn.style.margin='5px';
            winbtn.style.color='#fff';
            winbtn.style.cursor='pointer'
            winbtn.addEventListener('click',()=>{
               document.querySelector('.num').innerHTML=''
                number_of_players=0;
               const winn2=document.querySelector('.allsound2');
               winn2.play();

                const yai=document.createElement('img')
                yai.setAttribute('src',gamepictwo)
                yai.style.width='200px';
                yai.style.height='200px';
                yai.style.borderRadius='80px';
                yai.style.position='absolute';
                yai.style.marginLeft='130px';
                yai.style.marginTop='-400px';
                yai.style.border='4px solid gold'
                document.querySelector('.cardNumber').appendChild(yai)

                const ya=document.createElement('p');
                ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
                ya.style.padding='20px';
                ya.style.backgroundColor='rgb(247, 170, 25)'
                ya.style.color='black';
                ya.style.position='absolute';
                ya.style.marginLeft='80px';
                ya.style.marginTop='-230px'
                ya.style.border='3px solid gold'
                document.querySelector('.cardNumber').appendChild(ya);
               
                 

               const newgame=document.createElement('button')
               newgame.textContent='አዲስ ጨዋታ ጀምር';
               newgame.style.backgroundColor='gold';
               newgame.style.textAlign='center';
               newgame.style.position='absolute';
               newgame.style.marginLeft='120px';
               newgame.style.marginTop='-160px'
               newgame.style.padding='20px'
               newgame.style.cursor='pointer'
               newgame.style.width='200px';
               newgame.style.border='none'
               newgame.style.backgroundColor='rgb(151, 14, 201)'
               newgame.style.color='#fff';
               newgame.addEventListener('click',()=>{
                  document.querySelector('.num').innerHTML=''
                  number_of_players=0;
                  gameIndicatorValue=1
                  calledNumber.b=[]
                  calledNumber.i=[]
                  calledNumber.n=[]
                  calledNumber.g=[]
                  calledNumber.o=[]

                  for(let k=0;k<actual_bingo_number.length;k++){
                     document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                    // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                     document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                 }
                 
                  
                 //document.querySelector('.num').innerHTML=''
                  //newgame.innerHTML='';
                  //xm.innerHTML='';
                  actual_bingo_number=[];
                  takencards=[];
                  xm.innerHTML=''
                  
                  document.querySelector('.registercards').innerHTML=''
                  //clearInterval(caller);
                  document.querySelector('.cardNumber').style.display='none'
                  ya.remove()
                  yai.remove()
                  newgame.remove();
                  document.querySelector('.bt1').style.backgroundColor='black';
                  document.querySelector('.btnn1').style.backgroundColor='black'; 
                  document.querySelector('.bt1').style.color='gold';
                  document.querySelector('.btnn1').style.color='gold';
////////////////////// Time count Starting session //////////////////////
/*
let a=2;
let b=60;
document.querySelector('.counterone').innerHTML=''
let count=setInterval(()=>{
   let xn=document.querySelector('.counterone')
   xn.style.marginLeft='-200px'
   let dx=document.createElement('p')
   dx.textContent=`${a}:${b}`;
   dx.style.position='absolute'
   dx.style.backgroundColor='gold'
   dx.style.width='200px';
   dx.style.color='black'
   dx.style.height='50px'
   dx.style.padding='auto';
   dx.style.borderRadius='10px'
   dx.style.fontSize='30px'
   dx.style.fontWeight='bold'
   dx.style.textAlign='center'
   xn.appendChild(dx)
   b--;
   if(b===0){
      b=60
      a--;
      if(a===-1){
         a=2
      }
     
   }

   if(a===0 && b<=30){
      xn.style.backgroundColor='red';
      dx.style.backgroundColor='red';
      xn.style.color='#fff';
   }
   if(a===0 && b===1){
      //xn.style.width='300px'
      //xn.style.padding='20px'
      //dx.style.height='30px'
      dx.style.fontSize='20px'
      dx.style.color='#fff'
      dx.innerHTML='ጨዋታው ሊጀምር ነው!!'
      clearInterval(count)
   }
  


},1000)
*/
///////////////////// ending time counting session ///////////////////

               })
               document.querySelector('.cardNumber').appendChild(newgame); 
               
            
               })

            xm.appendChild(winbtn);

            const loss=document.createElement('button')
            loss.textContent='አልዘጋም';
            loss.style.backgroundColor='red';
            loss.style.cursor='pointer'
            loss.style.fontSize='15px';
            loss.style.width='100px'
            loss.style.padding='10px';
            loss.style.border='none';
            loss.style.marginLeft='20px';
            loss.style.position='absolute'
            loss.style.marginTop='8px'
            loss.style.borderRadius='0px';
            loss.style.color='#fff';
            loss.addEventListener('click',()=>{
               ////////////////////not closed...////////////////
               for(let j=0;j<takencards.length;j++){
                  if(takencards[j]===cardNumbers[wincard].cardname){
                    takencards[j]=0;
                  }
              } 

               const yai=document.createElement('img')
               yai.setAttribute('src',lossx)
               yai.style.width='200px';
               yai.style.height='200px';
               yai.style.borderRadius='80px';
               yai.style.position='absolute';
               yai.style.marginLeft='130px';
               yai.style.marginTop='-400px';
               yai.style.border='4px solid gold'
               document.querySelector('.cardNumber').appendChild(yai)
            
               const ya=document.createElement('p');
               ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
               ya.style.padding='20px';
               ya.style.backgroundColor='red'
               ya.style.color='#fff';
               ya.style.position='absolute';
               ya.style.marginLeft='80px';
               ya.style.marginTop='-230px'
               ya.style.border='3px solid gold'
               document.querySelector('.cardNumber').appendChild(ya);
               
               document.querySelector('.allsound4').play();
               /////////////////////end of not closed...//////////
            })

            xm.appendChild(loss);

            const xx=document.createElement('br')
            xm.appendChild(xx)

            const xxx=document.createElement('br')
            xm.appendChild(xxx)

            const xxxx=document.createElement('br')
            xm.appendChild(xxxx)

            const xxxxx=document.createElement('br')
            xm.appendChild(xxxxx)  

////////////////////////////////////////////  

                        
                        }
                        else{
                          //alert(`${bingo[puase]} is not exist in your card`)

                          /////////////// starting of displaying card number even if they
                          //               are not called
                          //
                          /////////////////////////////////////////////

                          let xm=document.querySelector('.closecheakcard')
                          xm.style.display='block'    
//////////////////////////////////////////////////
            
          for(let u=0;u<cardNumbers[wincard].b.length;u++){
               
                 const n=document.createElement('p');
                 n.textContent=cardNumbers[wincard].b[u]
                 n.style.backgroundColor='green';
                 n.style.color='#fff';

            for(let mr=0;mr<calledNumber.b.length;mr++){
                   
                     if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                        n.style.backgroundColor='gold'
                        n.style.color='black'
                     }
                 
                } 

                 n.style.padding='10px';
                 n.style.width='40px'
                 n.style.borderRadius='10px';
                 n.style.margin='0px'
                 xm.appendChild(n)

                 const brr=document.createElement('br')
                 xm.appendChild(brr)
            
              }

              for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
               
               const nx=document.createElement('p');
               nx.textContent=cardNumbers[wincard].i[ui]
               nx.style.backgroundColor='green';
               nx.style.position='absolute'
               nx.style.color='#fff';

                 for(let mr=0;mr<calledNumber.i.length;mr++){
                   
                     if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                        nx.style.backgroundColor='gold'
                        nx.style.color='black'
                     }
                 
                } 
               nx.style.padding='10px';
               nx.style.width='40px'
               nx.style.borderRadius='10px';
               nx.style.marginLeft='55px'
               nx.style.marginTop='-275px';
               //n.style.marginBottom='10px'
               xm.appendChild(nx)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)

              
            }   


            for(let un=0;un<cardNumbers[wincard].n.length;un++){
               
               const ni=document.createElement('p');
               ni.textContent=cardNumbers[wincard].n[un]
               ni.style.backgroundColor='green';
               ni.style.position='absolute'
               ni.style.color='#fff';

                for(let mr=0;mr<calledNumber.n.length;mr++){
                   
                     if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                        ni.style.backgroundColor='gold'
                        ni.style.color='black'
                     }
                     if(cardNumbers[wincard].n[un]==='free'){
                        ni.style.backgroundColor='gold';
                        ni.style.color='black';
                     }
                 
                } 

               ni.style.padding='10px';
               ni.style.width='40px'
               ni.style.borderRadius='10px';
               ni.style.marginLeft='110px'
               ni.style.marginTop='-545px'
               xm.appendChild(ni)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)

            
            }   
              

            for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
               
               const ng=document.createElement('p');
               ng.textContent=cardNumbers[wincard].g[ug]
               ng.style.position='absolute'
               ng.style.backgroundColor='green';
               ng.style.color='#fff';

             for(let mr=0;mr<calledNumber.g.length;mr++){
                   
                     if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                        ng.style.backgroundColor='gold'
                        ng.style.color='black'
                     }
                 
                } 

               ng.style.padding='10px';
               ng.style.width='40px'
               ng.style.borderRadius='10px';
               ng.style.marginLeft='165px'
               ng.style.marginTop='-815px';
               xm.appendChild(ng)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)
              
            }   



            for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
               
               const no=document.createElement('p');
               no.textContent=cardNumbers[wincard].o[uo]
               no.style.position='absolute'
               no.style.backgroundColor='green';
               no.style.color='#fff';

            for(let mr=0;mr<calledNumber.o.length;mr++){
                   
                     if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                        no.style.backgroundColor='gold'
                        no.style.color='black'
                     }
                 
                } 

               no.style.padding='10px';
               no.style.width='40px'
               no.style.borderRadius='10px';
               no.style.marginLeft='220px'
               no.style.marginTop='-1085px';
               xm.appendChild(no)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)
              
            }   


            const crdname=document.createElement('p')
            crdname.textContent=cardNumbers[wincard].cardname
            crdname.style.color='black'
            crdname.style.backgroundColor='rgb(211, 218, 248)';
            crdname.style.padding='10px'
            crdname.style.color="black";
            crdname.style.width='230px';
            crdname.style.textAlign='center';
            crdname.style.fontFamily='arial'
            crdname.style.border='none'
            crdname.style.marginTop='-1100px'
            xm.appendChild(crdname);

           const winbtn=document.createElement('button')
           winbtn.textContent='ዘግትዋል';
           winbtn.style.backgroundColor='blue';
           winbtn.style.padding='10px';
           winbtn.style.fontSize='15px';
           winbtn.style.borderRadius='0px';
           winbtn.style.border='none';
           winbtn.style.width='100px'
           winbtn.style.margin='5px';
           winbtn.style.color='#fff';
           winbtn.style.cursor='pointer'
           winbtn.addEventListener('click',()=>{
            document.querySelector('.num').innerHTML='' 
            number_of_players=0;
            calledNumber.b=[]
            calledNumber.i=[]
            calledNumber.n=[]
            calledNumber.g=[]
            calledNumber.o=[]
            
            const winn2=document.querySelector('.allsound2');
            winn2.play();

             const yai=document.createElement('img')
             yai.setAttribute('src',gamepictwo)
             yai.style.width='200px';
             yai.style.height='200px';
             yai.style.borderRadius='80px';
             yai.style.position='absolute';
             yai.style.marginLeft='130px';
             yai.style.marginTop='-400px';
             yai.style.border='4px solid gold'
             document.querySelector('.cardNumber').appendChild(yai)

             const ya=document.createElement('p');
             ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
             ya.style.padding='20px';
             ya.style.backgroundColor='rgb(247, 170, 25)'
             ya.style.color='black';
             ya.style.position='absolute';
             ya.style.marginLeft='80px';
             ya.style.marginTop='-230px'
             ya.style.border='3px solid gold'
             document.querySelector('.cardNumber').appendChild(ya);
            
              

            const newgame=document.createElement('button')
            newgame.textContent='አዲስ ጨዋታ ጀምር';
            newgame.style.backgroundColor='gold';
            newgame.style.textAlign='center';
            newgame.style.position='absolute';
            newgame.style.marginLeft='120px';
            newgame.style.marginTop='-160px'
            newgame.style.padding='20px'
            newgame.style.cursor='pointer'
            newgame.style.width='200px';
            newgame.style.border='none'
            newgame.style.backgroundColor='rgb(151, 14, 201)'
            newgame.style.color='#fff';
            newgame.addEventListener('click',()=>{
               number_of_players=0;
               gameIndicatorValue=1
               for(let k=0;k<actual_bingo_number.length;k++){
                  document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                 // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                  document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
              }
              
               
              //document.querySelector('.num').innerHTML=''
               //newgame.innerHTML='';
               //xm.innerHTML='';
               actual_bingo_number=[];
               takencards=[];
               xm.innerHTML=''
               
               document.querySelector('.registercards').innerHTML=''
               //clearInterval(caller);
               document.querySelector('.cardNumber').style.display='none'
               ya.remove()
               yai.remove()
               newgame.remove();
               document.querySelector('.bt1').style.backgroundColor='black';
               document.querySelector('.btnn1').style.backgroundColor='black';
               document.querySelector('.bt1').style.color='gold';
               document.querySelector('.btnn1').style.color='gold';  
 ////////////////////// Time count Starting session //////////////////////


///////////////////// ending time counting session ///////////////////

            })
            document.querySelector('.cardNumber').appendChild(newgame); 
            
         
            })

           xm.appendChild(winbtn);

           const loss=document.createElement('button')
           loss.textContent='አልዘጋም';
           loss.style.backgroundColor='red';
           loss.style.cursor='pointer'
           loss.style.fontSize='15px';
           loss.style.width='100px'
           loss.style.padding='10px';
           loss.style.border='none';
           loss.style.marginLeft='20px';
           loss.style.position='absolute'
           loss.style.marginTop='8px'
           loss.style.borderRadius='0px';
           loss.style.color='#fff';
           loss.addEventListener('click',()=>{
            ////////////////////not closed...////////////////
            for(let j=0;j<takencards.length;j++){
               if(takencards[j]===cardNumbers[wincard].cardname){
                 takencards[j]=0;
               }
           }


            const yai=document.createElement('img')
            yai.setAttribute('src',lossx)
            yai.style.width='200px';
            yai.style.height='200px';
            yai.style.borderRadius='80px';
            yai.style.position='absolute';
            yai.style.marginLeft='130px';
            yai.style.marginTop='-400px';
            yai.style.border='4px solid gold'
            document.querySelector('.cardNumber').appendChild(yai)
         
            const ya=document.createElement('p');
            ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
            ya.style.padding='20px';
            ya.style.backgroundColor='red'
            ya.style.color='#fff';
            ya.style.position='absolute';
            ya.style.marginLeft='80px';
            ya.style.marginTop='-230px'
            ya.style.border='3px solid gold'
            document.querySelector('.cardNumber').appendChild(ya);
            
            document.querySelector('.allsound4').play();
            /////////////////////end of not closed...//////////
         })
           xm.appendChild(loss);

           const xx5=document.createElement('br')
           xm.appendChild(xx5)

           const xxx5=document.createElement('br')
           xm.appendChild(xxx5)

           const xxxx5=document.createElement('br')
           xm.appendChild(xxxx5)

           const xxxxx5=document.createElement('br')
           xm.appendChild(xxxxx5)


                          //////////// ending of displaying card numbers even if they are 
                          //           not being called
                          //
                          //////////////////////////////////////////////////////

                          let xx=document.querySelector('.closecheak')
                              xx.style.position='absolute'
                              xx.style.marginLeft='500px';
                              xx.style.marginTop='-400px'
                              let xp=document.createElement('p')
                              xp.textContent=`${bingo[puase]} ቁጥር በ ${document.querySelector('.cardinput').value} ካርቴላ ላይ የለም`
                              xp.style.backgroundColor='red';
                              xp.style.color='#fff'
                              xp.style.margin='20px'
                              xp.style.padding='20px';
                              xp.style.borderRadius='10px'
                              xx.appendChild(xp)

                               let kx=document.createElement('p')
                              kx.textContent='X'
                              kx.style.position='absolute'
                              kx.style.marginTop='-75px';
                              kx.style.marginLeft='260px';
                              kx.style.borderRadius='20px';
                              kx.style.backgroundColor='gray'
                              kx.style.padding='5px'
                              kx.style.borderRadius='20px'
                              kx.style.cursor='pointer'
                              kx.addEventListener('click',()=>{
                                 xx.innerHTML='';    
                              })
                              xx.appendChild(kx)
                            
                          
                          }     
                      }
                      else if(bingo[puase]>=46 && bingo[puase]<=60){
                        let tre=false;
                        for(let x=0;x<cardNumbers[wincard].g.length;x++){
                            if(bingo[puase]===cardNumbers[wincard].g[x]){
                               //alert(`${bingo[puase]} is not exist in your card`)
                                tre=true
                                break;
                             }                               
                        }
                        if(tre===true){
                            ////////////////////////////////
                           //   two vertical close Logic //         
                           //                           //
                           //////////////////////////////
                          //alert(`${bingo[puase]} is exist on these card`) 
                        
                          let xm=document.querySelector('.closecheakcard')
                          xm.style.display='block'    
//////////////////////////////////////////////////
            
          for(let u=0;u<cardNumbers[wincard].b.length;u++){
               
                 const n=document.createElement('p');
                 n.textContent=cardNumbers[wincard].b[u]
                 n.style.backgroundColor='green';
                 n.style.color='#fff';

            for(let mr=0;mr<calledNumber.b.length;mr++){
                   
                     if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                        n.style.backgroundColor='gold'
                        n.style.color='black'
                     }
                 
                } 

                 n.style.padding='10px';
                 n.style.width='40px'
                 n.style.borderRadius='10px';
                 n.style.margin='0px'
                 xm.appendChild(n)

                 const brr=document.createElement('br')
                 xm.appendChild(brr)
            
              }

              for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
               
               const nx=document.createElement('p');
               nx.textContent=cardNumbers[wincard].i[ui]
               nx.style.backgroundColor='green';
               nx.style.position='absolute'
               nx.style.color='#fff';

                 for(let mr=0;mr<calledNumber.i.length;mr++){
                   
                     if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                        nx.style.backgroundColor='gold'
                        nx.style.color='black'
                     }
                 
                } 
               nx.style.padding='10px';
               nx.style.width='40px'
               nx.style.borderRadius='10px';
               nx.style.marginLeft='55px'
               nx.style.marginTop='-275px';
               //n.style.marginBottom='10px'
               xm.appendChild(nx)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)

              
            }   


            for(let un=0;un<cardNumbers[wincard].n.length;un++){
               
               const ni=document.createElement('p');
               ni.textContent=cardNumbers[wincard].n[un]
               ni.style.backgroundColor='green';
               ni.style.position='absolute'
               ni.style.color='#fff';

                for(let mr=0;mr<calledNumber.n.length;mr++){
                   
                     if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                        ni.style.backgroundColor='gold'
                        ni.style.color='black'
                     }

                     if(cardNumbers[wincard].n[un]==='free'){
                           ni.style.backgroundColor='gold';
                           ni.style.color='black'
                     }
                 
                } 

               ni.style.padding='10px';
               ni.style.width='40px'
               ni.style.borderRadius='10px';
               ni.style.marginLeft='110px'
               ni.style.marginTop='-545px'
               xm.appendChild(ni)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)

            
            }   
              

            for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
               
               const ng=document.createElement('p');
               ng.textContent=cardNumbers[wincard].g[ug]
               ng.style.position='absolute'
               ng.style.backgroundColor='green';
               ng.style.color='#fff';

             for(let mr=0;mr<calledNumber.g.length;mr++){
                   
                     if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                        ng.style.backgroundColor='gold'
                        ng.style.color='black'
                     }
                 
                } 

               ng.style.padding='10px';
               ng.style.width='40px'
               ng.style.borderRadius='10px';
               ng.style.marginLeft='165px'
               ng.style.marginTop='-815px';
               xm.appendChild(ng)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)
              
            }   



            for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
               
               const no=document.createElement('p');
               no.textContent=cardNumbers[wincard].o[uo]
               no.style.position='absolute'
               no.style.backgroundColor='green';
               no.style.color='#fff';

            for(let mr=0;mr<calledNumber.o.length;mr++){
                   
                     if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                        no.style.backgroundColor='gold'
                        no.style.color='black'
                     }
                 
                } 

               no.style.padding='10px';
               no.style.width='40px'
               no.style.borderRadius='10px';
               no.style.marginLeft='220px'
               no.style.marginTop='-1085px';
               xm.appendChild(no)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)
              
            }   


            const crdname=document.createElement('p')
            crdname.textContent=cardNumbers[wincard].cardname
            crdname.style.color='black'
            crdname.style.backgroundColor='rgb(211, 218, 248)';
            crdname.style.padding='10px'
            crdname.style.color="black";
            crdname.style.width='230px';
            crdname.style.textAlign='center';
            crdname.style.fontFamily='arial'
            crdname.style.border='none'
            crdname.style.marginTop='-1100px'
            xm.appendChild(crdname);

           const winbtn=document.createElement('button')
           winbtn.textContent='ዘግትዋል';
           winbtn.style.backgroundColor='blue';
           winbtn.style.padding='10px';
           winbtn.style.fontSize='15px';
           winbtn.style.borderRadius='0px';
           winbtn.style.border='none';
           winbtn.style.width='100px'
           winbtn.style.margin='5px';
           winbtn.style.color='#fff';
           winbtn.style.cursor='pointer'
           winbtn.addEventListener('click',()=>{
            document.querySelector('.num').innerHTML=''
             number_of_players=0;
            calledNumber.b=[]
            calledNumber.i=[]
            calledNumber.n=[]
            calledNumber.g=[]
            calledNumber.o=[]
            
            const winn2=document.querySelector('.allsound2');
            winn2.play();

             const yai=document.createElement('img')
             yai.setAttribute('src',gamepictwo)
             yai.style.width='200px';
             yai.style.height='200px';
             yai.style.borderRadius='80px';
             yai.style.position='absolute';
             yai.style.marginLeft='130px';
             yai.style.marginTop='-400px';
             yai.style.border='4px solid gold'
             document.querySelector('.cardNumber').appendChild(yai)

             const ya=document.createElement('p');
             ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
             ya.style.padding='20px';
             ya.style.backgroundColor='rgb(247, 170, 25)'
             ya.style.color='black';
             ya.style.position='absolute';
             ya.style.marginLeft='80px';
             ya.style.marginTop='-230px'
             ya.style.border='3px solid gold'
             document.querySelector('.cardNumber').appendChild(ya);
            
              

            const newgame=document.createElement('button')
            newgame.textContent='አዲስ ጨዋታ ጀምር';
            newgame.style.backgroundColor='gold';
            newgame.style.textAlign='center';
            newgame.style.position='absolute';
            newgame.style.marginLeft='120px';
            newgame.style.marginTop='-160px'
            newgame.style.padding='20px'
            newgame.style.cursor='pointer'
            newgame.style.width='200px';
            newgame.style.border='none'
            newgame.style.backgroundColor='rgb(151, 14, 201)'
            newgame.style.color='#fff';
            newgame.addEventListener('click',()=>{
               
               number_of_players=0;
               gameIndicatorValue=1
               for(let k=0;k<actual_bingo_number.length;k++){
                  document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                 // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                  document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
              }
              
               
              //document.querySelector('.num').innerHTML=''
               //newgame.innerHTML='';
               //xm.innerHTML='';
               actual_bingo_number=[];
               takencards=[];
               xm.innerHTML=''
               
               document.querySelector('.registercards').innerHTML=''
               //clearInterval(caller);
               document.querySelector('.cardNumber').style.display='none'
               ya.remove()
               yai.remove()
               newgame.remove();
               document.querySelector('.bt1').style.backgroundColor='black';
               document.querySelector('.btnn1').style.backgroundColor='black';
               document.querySelector('.bt1').style.color='gold';
               document.querySelector('.btnn1').style.color='gold';  
 ////////////////////// Time count Starting session //////////////////////
/*
 let a=2;
let b=60;
document.querySelector('.counterone').innerHTML=''
let count=setInterval(()=>{
let xn=document.querySelector('.counterone')
xn.style.marginLeft='-200px'
let dx=document.createElement('p')
dx.textContent=`${a}:${b}`;
dx.style.position='absolute'
dx.style.backgroundColor='gold'
dx.style.width='200px';
dx.style.color='black'
dx.style.height='50px'
dx.style.padding='auto';
dx.style.borderRadius='10px'
dx.style.fontSize='30px'
dx.style.fontWeight='bold'
dx.style.textAlign='center'
xn.appendChild(dx)
b--;
if(b===0){
   b=60
   a--;
   if(a===-1){
      a=2
   }
  
}

if(a===0 && b<=30){
   xn.style.backgroundColor='red';
   dx.style.backgroundColor='red';
   xn.style.color='#fff';
}
if(a===0 && b===1){
   //xn.style.width='300px'
   //xn.style.padding='20px'
   //dx.style.height='30px'
   dx.style.fontSize='20px'
   dx.style.color='#fff'
   dx.innerHTML='ጨዋታው ሊጀምር ነው!!'
   clearInterval(count)
}



},1000)
*/
///////////////////// ending time counting session ///////////////////

            })
            document.querySelector('.cardNumber').appendChild(newgame); 
            
         
            })

           xm.appendChild(winbtn);

           const loss=document.createElement('button')
           loss.textContent='አልዘጋም';
           loss.style.backgroundColor='red';
           loss.style.cursor='pointer'
           loss.style.fontSize='15px';
           loss.style.width='100px'
           loss.style.padding='10px';
           loss.style.border='none';
           loss.style.marginLeft='20px';
           loss.style.position='absolute'
           loss.style.marginTop='8px'
           loss.style.borderRadius='0px';
           loss.style.color='#fff';
           loss.addEventListener('click',()=>{
            ////////////////////not closed...////////////////
            for(let j=0;j<takencards.length;j++){
               if(takencards[j]===cardNumbers[wincard].cardname){
                 takencards[j]=0;
               }
           }


            const yai=document.createElement('img')
            yai.setAttribute('src',lossx)
            yai.style.width='200px';
            yai.style.height='200px';
            yai.style.borderRadius='80px';
            yai.style.position='absolute';
            yai.style.marginLeft='130px';
            yai.style.marginTop='-400px';
            yai.style.border='4px solid gold'
            document.querySelector('.cardNumber').appendChild(yai)
         
            const ya=document.createElement('p');
            ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
            ya.style.padding='20px';
            ya.style.backgroundColor='red'
            ya.style.color='#fff';
            ya.style.position='absolute';
            ya.style.marginLeft='80px';
            ya.style.marginTop='-230px'
            ya.style.border='3px solid gold'
            document.querySelector('.cardNumber').appendChild(ya);
            
            document.querySelector('.allsound4').play();
            /////////////////////end of not closed...//////////
         })
           xm.appendChild(loss);

           const xx=document.createElement('br')
           xm.appendChild(xx)

           const xxx=document.createElement('br')
           xm.appendChild(xxx)

           const xxxx=document.createElement('br')
           xm.appendChild(xxxx)

           const xxxxx=document.createElement('br')
           xm.appendChild(xxxxx)

////////////////////////////////////////////
                        
                        
                        
                        }
                        else{
                          //alert(`${bingo[puase]} ቁጥር በ ${document.querySelector('.cardinput').value} ካርቴላ ላይ የለም `)

                          /////////// starting displaying card number even if the number is not called
                          //
                          //
                          ///////////////////////

                          let xm=document.querySelector('.closecheakcard')
                          xm.style.display='block'    
//////////////////////////////////////////////////
            
          for(let u=0;u<cardNumbers[wincard].b.length;u++){
               
                 const n=document.createElement('p');
                 n.textContent=cardNumbers[wincard].b[u]
                 n.style.backgroundColor='green';
                 n.style.color='#fff';

            for(let mr=0;mr<calledNumber.b.length;mr++){
                   
                     if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                        n.style.backgroundColor='gold'
                        n.style.color='black'
                     }
                 
                } 

                 n.style.padding='10px';
                 n.style.width='40px'
                 n.style.borderRadius='10px';
                 n.style.margin='0px'
                 xm.appendChild(n)

                 const brr=document.createElement('br')
                 xm.appendChild(brr)
            
              }

              for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
               
               const nx=document.createElement('p');
               nx.textContent=cardNumbers[wincard].i[ui]
               nx.style.backgroundColor='green';
               nx.style.position='absolute'
               nx.style.color='#fff';

                 for(let mr=0;mr<calledNumber.i.length;mr++){
                   
                     if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                        nx.style.backgroundColor='gold'
                        nx.style.color='black'
                     }
                 
                } 
               nx.style.padding='10px';
               nx.style.width='40px'
               nx.style.borderRadius='10px';
               nx.style.marginLeft='55px'
               nx.style.marginTop='-275px';
               //n.style.marginBottom='10px'
               xm.appendChild(nx)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)

              
            }   


            for(let un=0;un<cardNumbers[wincard].n.length;un++){
               
               const ni=document.createElement('p');
               ni.textContent=cardNumbers[wincard].n[un]
               ni.style.backgroundColor='green';
               ni.style.position='absolute'
               ni.style.color='#fff';

                for(let mr=0;mr<calledNumber.n.length;mr++){
                   
                     if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                        ni.style.backgroundColor='gold'
                        ni.style.color='black'
                     }
                     if(cardNumbers[wincard].n[un]==='free'){
                        ni.style.backgroundColor='gold';
                        ni.style.color='black';
                     }
                 
                } 

               ni.style.padding='10px';
               ni.style.width='40px'
               ni.style.borderRadius='10px';
               ni.style.marginLeft='110px'
               ni.style.marginTop='-545px'
               xm.appendChild(ni)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)

            
            }   
              

            for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
               
               const ng=document.createElement('p');
               ng.textContent=cardNumbers[wincard].g[ug]
               ng.style.position='absolute'
               ng.style.backgroundColor='green';
               ng.style.color='#fff';

             for(let mr=0;mr<calledNumber.g.length;mr++){
                   
                     if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                        ng.style.backgroundColor='gold'
                        ng.style.color='black'
                     }
                 
                } 

               ng.style.padding='10px';
               ng.style.width='40px'
               ng.style.borderRadius='10px';
               ng.style.marginLeft='165px'
               ng.style.marginTop='-815px';
               xm.appendChild(ng)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)
              
            }   



            for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
               
               const no=document.createElement('p');
               no.textContent=cardNumbers[wincard].o[uo]
               no.style.position='absolute'
               no.style.backgroundColor='green';
               no.style.color='#fff';

            for(let mr=0;mr<calledNumber.o.length;mr++){
                   
                     if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                        no.style.backgroundColor='gold'
                        no.style.color='black'
                     }
                 
                } 

               no.style.padding='10px';
               no.style.width='40px'
               no.style.borderRadius='10px';
               no.style.marginLeft='220px'
               no.style.marginTop='-1085px';
               xm.appendChild(no)

               const brr=document.createElement('br')
               xm.appendChild(brr)

               const brrr=document.createElement('br')
               xm.appendChild(brrr)

               const brrrr=document.createElement('br')
               xm.appendChild(brrrr)
              
            }   


            const crdname=document.createElement('p')
            crdname.textContent=cardNumbers[wincard].cardname
            crdname.style.color='black'
            crdname.style.backgroundColor='rgb(211, 218, 248)';
            crdname.style.padding='10px'
            crdname.style.color="black";
            crdname.style.width='230px';
            crdname.style.textAlign='center';
            crdname.style.fontFamily='arial'
            crdname.style.border='none'
            crdname.style.marginTop='-1100px'
            xm.appendChild(crdname);

           const winbtn=document.createElement('button')
           winbtn.textContent='ዘግትዋል';
           winbtn.style.backgroundColor='blue';
           winbtn.style.padding='10px';
           winbtn.style.fontSize='15px';
           winbtn.style.borderRadius='0px';
           winbtn.style.border='none';
           winbtn.style.width='100px'
           winbtn.style.margin='5px';
           winbtn.style.color='#fff';
           winbtn.style.cursor='pointer'
           winbtn.addEventListener('click',()=>{
              number_of_players=0;
            calledNumber.b=[]
            calledNumber.i=[]
            calledNumber.n=[]
            calledNumber.g=[]
            calledNumber.o=[]
            
            const winn2=document.querySelector('.allsound2');
            winn2.play();

             const yai=document.createElement('img')
             yai.setAttribute('src',gamepictwo)
             yai.style.width='200px';
             yai.style.height='200px';
             yai.style.borderRadius='80px';
             yai.style.position='absolute';
             yai.style.marginLeft='130px';
             yai.style.marginTop='-400px';
             yai.style.border='4px solid gold'
             document.querySelector('.cardNumber').appendChild(yai)

             const ya=document.createElement('p');
             ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
             ya.style.padding='20px';
             ya.style.backgroundColor='rgb(247, 170, 25)'
             ya.style.color='black';
             ya.style.position='absolute';
             ya.style.marginLeft='80px';
             ya.style.marginTop='-230px'
             ya.style.border='3px solid gold'
             document.querySelector('.cardNumber').appendChild(ya);
            
              

            const newgame=document.createElement('button')
            newgame.textContent='አዲስ ጨዋታ ጀምር';
            newgame.style.backgroundColor='gold';
            newgame.style.textAlign='center';
            newgame.style.position='absolute';
            newgame.style.marginLeft='120px';
            newgame.style.marginTop='-160px'
            newgame.style.padding='20px'
            newgame.style.cursor='pointer'
            newgame.style.width='200px';
            newgame.style.border='none'
            newgame.style.backgroundColor='rgb(151, 14, 201)'
            newgame.style.color='#fff';
            newgame.addEventListener('click',()=>{
               document.querySelector('.num').innerHTML=''
               number_of_players=0;
               gameIndicatorValue=1
               for(let k=0;k<actual_bingo_number.length;k++){
                  document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                 // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                  document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
              }
              
               
              //document.querySelector('.num').innerHTML=''
               //newgame.innerHTML='';
               //xm.innerHTML='';
               actual_bingo_number=[];
               takencards=[];
               xm.innerHTML=''
               
               document.querySelector('.registercards').innerHTML=''
               //clearInterval(caller);
               document.querySelector('.cardNumber').style.display='none'
               ya.remove()
               yai.remove()
               newgame.remove();
               document.querySelector('.bt1').style.backgroundColor='black';
               document.querySelector('.btnn1').style.backgroundColor='black';
               document.querySelector('.bt1').style.color='gold';
               document.querySelector('.btnn1').style.color='gold';  
 ////////////////////// Time count Starting session //////////////////////


///////////////////// ending time counting session ///////////////////

            })
            document.querySelector('.cardNumber').appendChild(newgame); 
            
         
            })

           xm.appendChild(winbtn);

           const loss=document.createElement('button')
           loss.textContent='አልዘጋም';
           loss.style.backgroundColor='red';
           loss.style.cursor='pointer'
           loss.style.fontSize='15px';
           loss.style.width='100px'
           loss.style.padding='10px';
           loss.style.border='none';
           loss.style.marginLeft='20px';
           loss.style.position='absolute'
           loss.style.marginTop='8px'
           loss.style.borderRadius='0px';
           loss.style.color='#fff';
           loss.addEventListener('click',()=>{
            ////////////////////not closed...////////////////
            const yai=document.createElement('img')
            yai.setAttribute('src',lossx)
            yai.style.width='200px';
            yai.style.height='200px';
            yai.style.borderRadius='80px';
            yai.style.position='absolute';
            yai.style.marginLeft='130px';
            yai.style.marginTop='-400px';
            yai.style.border='4px solid gold'
            document.querySelector('.cardNumber').appendChild(yai)
         
            const ya=document.createElement('p');
            ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
            ya.style.padding='20px';
            ya.style.backgroundColor='red'
            ya.style.color='#fff';
            ya.style.position='absolute';
            ya.style.marginLeft='80px';
            ya.style.marginTop='-230px'
            ya.style.border='3px solid gold'
            document.querySelector('.cardNumber').appendChild(ya);
            
            document.querySelector('.allsound4').play();
            /////////////////////end of not closed...//////////
         })
           xm.appendChild(loss);

           const xx2=document.createElement('br')
           xm.appendChild(xx2)

           const xxx2=document.createElement('br')
           xm.appendChild(xxx2)

           const xxxx2=document.createElement('br')
           xm.appendChild(xxxx2)

           const xxxxx2=document.createElement('br')
           xm.appendChild(xxxxx2)
                       
                          ////////// Ending of the card number even if the number is not called
                          //
                          //////////////////
                          let xx=document.querySelector('.closecheak')
                              xx.style.position='absolute'
                              xx.style.marginLeft='500px';
                              xx.style.marginTop='-400px'
                              let xp=document.createElement('p')
                             xp.textContent=`${bingo[puase]} ቁጥር በ ${document.querySelector('.cardinput').value} ካርቴላ ላይ የለም`
                              xp.style.backgroundColor='red';
                              xp.style.color='#fff'
                              xp.style.margin='20px'
                              xp.style.padding='20px';
                              xp.style.borderRadius='10px'
                              xx.appendChild(xp)

                               let kx=document.createElement('p')
                              kx.textContent='X'
                              kx.style.position='absolute'
                              kx.style.marginTop='-75px';
                              kx.style.marginLeft='260px';
                              kx.style.borderRadius='20px';
                              kx.style.backgroundColor='gray';
                              kx.style.borderRadius='20px';
                              kx.style.padding='5px';
                              kx.style.cursor='pointer';
                              kx.addEventListener('click',()=>{
                                 xx.innerHTML='';    
                              })
                              xx.appendChild(kx)

                            
                        }
                            
                         }

                      else if(bingo[puase]>=61 && bingo[puase]<=75){
                        let tre=false;
                        for(let x=0;x<cardNumbers[wincard].o.length;x++){
                            if(bingo[puase]===cardNumbers[wincard].o[x]){
                               //alert(`${bingo[puase]} is not exist in your card`)
                                tre=true
                                break;
                             }                               
                        }
                        if(tre===true){
                            ////////////////////////////////
                           //   two vertical close Logic //         
                           //                           //
                           //////////////////////////////
                           //alert(`${bingo[puase]} is exist on these card`)
                          // alert(`${document.querySelector('.cardinput').value} is ok`)
                        
                           let xm=document.querySelector('.closecheakcard')
                           xm.style.display='block'    
//////////////////////////////////////////////////
             
           for(let u=0;u<cardNumbers[wincard].b.length;u++){
                
                  const n=document.createElement('p');
                  n.textContent=cardNumbers[wincard].b[u]
                  n.style.backgroundColor='green';
                  n.style.color='#fff';

          for(let mr=0;mr<calledNumber.b.length;mr++){
                   
                     if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                        n.style.backgroundColor='gold'
                        n.style.color='black'
                     }
                 
                } 
                  n.style.padding='10px';
                  n.style.width='40px'
                  n.style.borderRadius='10px';
                  n.style.margin='0px'
                  xm.style.position='absolute'
                  xm.appendChild(n)

                  const brr=document.createElement('br')
                  xm.appendChild(brr)
               }

               for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
                
                const nx=document.createElement('p');
                nx.textContent=cardNumbers[wincard].i[ui]
                nx.style.backgroundColor='green';
                nx.style.position='absolute'
                nx.style.color='#fff';

            for(let mr=0;mr<calledNumber.i.length;mr++){
                   
                     if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                        nx.style.backgroundColor='gold'
                        nx.style.color='black'
                     }
                 
                } 

                nx.style.padding='10px';
                nx.style.width='40px'
                nx.style.borderRadius='10px';
                nx.style.marginLeft='55px'
                nx.style.marginTop='-275px';
                //n.style.marginBottom='10px'
                xm.appendChild(nx)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)

               
             }   


         for(let un=0;un<cardNumbers[wincard].n.length;un++){
                
                const ni=document.createElement('p');
                ni.textContent=cardNumbers[wincard].n[un]
                ni.style.backgroundColor='green';
                ni.style.position='absolute'
                ni.style.color='#fff';

         for(let mr=0;mr<calledNumber.n.length;mr++){
                   
                     if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                        ni.style.backgroundColor='gold'
                        ni.style.color='black'
                     }
                     if(cardNumbers[wincard].n[un]==='free'){
                           ni.style.backgroundColor='gold';
                           ni.style.color='black';
                     }
                 
                } 
                ni.style.padding='10px';
                ni.style.width='40px'
                ni.style.borderRadius='10px';
                ni.style.marginLeft='110px'
                ni.style.marginTop='-545px'
                xm.appendChild(ni)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)

             
             }   
               

             for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
                
                const ng=document.createElement('p');
                ng.textContent=cardNumbers[wincard].g[ug]
                ng.style.position='absolute'
                ng.style.backgroundColor='green';
                ng.style.color='#fff';

            for(let mr=0;mr<calledNumber.g.length;mr++){
                   
                     if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                        ng.style.backgroundColor='gold'
                        ng.style.color='black'
                     }
                 
                } 

                ng.style.padding='10px';
                ng.style.width='40px'
                ng.style.borderRadius='10px';
                ng.style.marginLeft='165px'
                ng.style.marginTop='-815px';
                xm.appendChild(ng)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)
               
             }   



             for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
                
                const no=document.createElement('p');
                no.textContent=cardNumbers[wincard].o[uo]
                no.style.position='absolute'
                no.style.backgroundColor='green';
                no.style.color='#fff';

            for(let mr=0;mr<calledNumber.o.length;mr++){
                   
                     if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                        no.style.backgroundColor='gold'
                        no.style.color='black'
                     }
                 
                } 
                no.style.padding='10px';
                no.style.width='40px'
                no.style.borderRadius='10px';
                no.style.marginLeft='220px'
                no.style.marginTop='-1085px';
                xm.appendChild(no)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)
               
             }   

         
             const crdname=document.createElement('p')
             crdname.textContent=cardNumbers[wincard].cardname
             crdname.style.color='black'
             crdname.style.backgroundColor='rgb(211, 218, 248)';
             crdname.style.padding='10px'
             crdname.style.color="black";
             crdname.style.width='230px';
             crdname.style.textAlign='center';
             crdname.style.fontFamily='arial'
             crdname.style.border='none'
             crdname.style.marginTop='-1100px'
             xm.appendChild(crdname);

            const winbtn=document.createElement('button')
            winbtn.textContent='ዘግትዋል';
            winbtn.style.backgroundColor='blue';
            winbtn.style.padding='10px';
            winbtn.style.fontSize='15px';
            winbtn.style.borderRadius='0px';
            winbtn.style.border='none';
            winbtn.style.width='100px'
            winbtn.style.margin='5px';
            winbtn.style.color='#fff';
            winbtn.style.cursor='pointer'
            winbtn.addEventListener('click',()=>{
               document.querySelector('.num').innerHTML=''
               number_of_players=0;
               calledNumber.b=[]
               calledNumber.i=[]
               calledNumber.n=[]
               calledNumber.g=[]
               calledNumber.o=[]

               const winn2=document.querySelector('.allsound2');
               winn2.play();
               

                const yai=document.createElement('img')
                yai.setAttribute('src',gamepictwo)
                yai.style.width='200px';
                yai.style.height='200px';
                yai.style.borderRadius='80px';
                yai.style.position='absolute';
                yai.style.marginLeft='130px';
                yai.style.marginTop='-400px';
                yai.style.border='4px solid gold'
                document.querySelector('.cardNumber').appendChild(yai)

                const ya=document.createElement('p');
                ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
                ya.style.padding='20px';
                ya.style.backgroundColor='rgb(247, 170, 25)'
                ya.style.color='black';
                ya.style.position='absolute';
                ya.style.marginLeft='80px';
                ya.style.marginTop='-230px'
                ya.style.border='3px solid gold'
                document.querySelector('.cardNumber').appendChild(ya);
               
                 

               const newgame=document.createElement('button')
               newgame.textContent='አዲስ ጨዋታ ጀምር';
               newgame.style.backgroundColor='gold';
               newgame.style.textAlign='center';
               newgame.style.position='absolute';
               newgame.style.marginLeft='120px';
               newgame.style.marginTop='-160px'
               newgame.style.padding='20px'
               newgame.style.cursor='pointer'
               newgame.style.width='200px';
               newgame.style.border='none'
               newgame.style.backgroundColor='rgb(151, 14, 201)'
               newgame.style.color='#fff';
               newgame.addEventListener('click',()=>{
                  document.querySelector('.num').innerHTML=''
                  number_of_players=0;
                  gameIndicatorValue=1
                  for(let k=0;k<actual_bingo_number.length;k++){
                     document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                    // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                     document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                 }
                 
                  
                 

                 //newgame.innerHTML='';
                  //xm.innerHTML='';
                  actual_bingo_number=[];
                  takencards=[];
                  xm.innerHTML=''
                  
                  document.querySelector('.registercards').innerHTML=''
                  //clearInterval(caller);
                  document.querySelector('.cardNumber').style.display='none'
                  ya.remove()
                  yai.remove()
                  newgame.remove();
                  document.querySelector('.bt1').style.backgroundColor='black';
                  document.querySelector('.btnn1').style.backgroundColor='black';
                  document.querySelector('.bt1').style.color='gold';
                  document.querySelector('.btnn1').style.color='gold';
////////////////////// Time count Starting session //////////////////////
/*
let a=2;
let b=60;
document.querySelector('.counterone').innerHTML=''
let count=setInterval(()=>{
   let xn=document.querySelector('.counterone')
   xn.style.marginLeft='-200px'
   let dx=document.createElement('p')
   dx.textContent=`${a}:${b}`;
   dx.style.position='absolute'
   dx.style.backgroundColor='gold'
   dx.style.width='200px';
   dx.style.color='black'
   dx.style.height='50px'
   dx.style.padding='auto';
   dx.style.borderRadius='10px'
   dx.style.fontSize='30px'
   dx.style.fontWeight='bold'
   dx.style.textAlign='center'
   xn.appendChild(dx)
   b--;
   if(b===0){
      b=60
      a--;
      if(a===-1){
         a=2
      }
     
   }

   if(a===0 && b<=30){
      xn.style.backgroundColor='red';
      dx.style.backgroundColor='red';
      xn.style.color='#fff';
   }
   if(a===0 && b===1){
      //xn.style.width='300px'
      //xn.style.padding='20px'
      //dx.style.height='30px'
      dx.style.fontSize='20px'
      dx.style.color='#fff'
      dx.innerHTML='ጨዋታው ሊጀምር ነው!!'
      clearInterval(count)
   }
  


},1000)

*/
///////////////////// ending time counting session ///////////////////

               })
               document.querySelector('.cardNumber').appendChild(newgame); 
               
            
               })

            xm.appendChild(winbtn);

            const loss=document.createElement('button')
            loss.textContent='አልዘጋም';
            loss.style.backgroundColor='red';
            loss.style.cursor='pointer'
            loss.style.fontSize='15px';
            loss.style.width='100px'
            loss.style.padding='10px';
            loss.style.border='none';
            loss.style.marginLeft='20px';
            loss.style.position='absolute'
            loss.style.marginTop='8px'
            loss.style.borderRadius='0px';
            loss.style.color='#fff';
            loss.addEventListener('click',()=>{
               ////////////////////not closed...////////////////
               for(let j=0;j<takencards.length;j++){
                  if(takencards[j]===cardNumbers[wincard].cardname){
                    takencards[j]=0;
                  }
              }


               const yai=document.createElement('img')
               yai.setAttribute('src',lossx)
               yai.style.width='200px';
               yai.style.height='200px';
               yai.style.borderRadius='80px';
               yai.style.position='absolute';
               yai.style.marginLeft='130px';
               yai.style.marginTop='-400px';
               yai.style.border='4px solid gold'
               document.querySelector('.cardNumber').appendChild(yai)
            
               const ya=document.createElement('p');
               ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
               ya.style.padding='20px';
               ya.style.backgroundColor='red'
               ya.style.color='#fff';
               ya.style.position='absolute';
               ya.style.marginLeft='80px';
               ya.style.marginTop='-230px'
               ya.style.border='3px solid gold'
               document.querySelector('.cardNumber').appendChild(ya);
               
               document.querySelector('.allsound4').play();
               /////////////////////end of not closed...//////////
            })
            xm.appendChild(loss);

            const xx=document.createElement('br')
            xm.appendChild(xx)

            const xxx=document.createElement('br')
            xm.appendChild(xxx)

            const xxxx=document.createElement('br')
            xm.appendChild(xxxx)

            const xxxxx=document.createElement('br')
            xm.appendChild(xxxxx)
////////////////////////////////////////////
                        
                        
                        
                        }
                        else{
                            ////////////// starting , displaying the card numbers even if
                            //            the called number is not exist
                            //
                            //
                            //////////////////////////////////////////////////////////
                            
                               ////////////////////////////////
                           //   two vertical close Logic //         
                           //                           //
                           //////////////////////////////
                           //alert(`${bingo[puase]} is exist on these card`)
                          // alert(`${document.querySelector('.cardinput').value} is ok`)
                        
                           let xm=document.querySelector('.closecheakcard')
                           xm.style.display='block'    
//////////////////////////////////////////////////
             
           for(let u=0;u<cardNumbers[wincard].b.length;u++){
                
                  const n=document.createElement('p');
                  n.textContent=cardNumbers[wincard].b[u]
                  n.style.backgroundColor='green';
                  n.style.color='#fff';

          for(let mr=0;mr<calledNumber.b.length;mr++){
                   
                     if(Number(cardNumbers[wincard].b[u])===Number(calledNumber.b[mr])){
                        n.style.backgroundColor='gold'
                        n.style.color='black'
                     }
                 
                } 
                  n.style.padding='10px';
                  n.style.width='40px'
                  n.style.borderRadius='10px';
                  n.style.margin='0px'
                  xm.style.position='absolute'
                  xm.appendChild(n)

                  const brr=document.createElement('br')
                  xm.appendChild(brr)
               }

               for(let ui=0;ui<cardNumbers[wincard].i.length;ui++){
                
                const nx=document.createElement('p');
                nx.textContent=cardNumbers[wincard].i[ui]
                nx.style.backgroundColor='green';
                nx.style.position='absolute'
                nx.style.color='#fff';

            for(let mr=0;mr<calledNumber.i.length;mr++){
                   
                     if(Number(cardNumbers[wincard].i[ui])===Number(calledNumber.i[mr])){
                        nx.style.backgroundColor='gold'
                        nx.style.color='black'
                     }
                 
                } 

                nx.style.padding='10px';
                nx.style.width='40px'
                nx.style.borderRadius='10px';
                nx.style.marginLeft='55px'
                nx.style.marginTop='-275px';
                //n.style.marginBottom='10px'
                xm.appendChild(nx)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)

               
             }   


         for(let un=0;un<cardNumbers[wincard].n.length;un++){
                
                const ni=document.createElement('p');
                ni.textContent=cardNumbers[wincard].n[un]
                ni.style.backgroundColor='green';
                ni.style.position='absolute'
                ni.style.color='#fff';

         for(let mr=0;mr<calledNumber.n.length;mr++){
                   
                     if(Number(cardNumbers[wincard].n[un])===Number(calledNumber.n[mr])){
                        ni.style.backgroundColor='gold'
                        ni.style.color='black'
                     }
                 if(cardNumbers[wincard].n[un]==='free'){
                     ni.style.backgroundColor='gold'
                     ni.style.color='black'
                 }    
                 
                } 
                ni.style.padding='10px';
                ni.style.width='40px'
                ni.style.borderRadius='10px';
                ni.style.marginLeft='110px'
                ni.style.marginTop='-545px'
                xm.appendChild(ni)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)

             
             }   
               

             for(let ug=0;ug<cardNumbers[wincard].g.length;ug++){
                
                const ng=document.createElement('p');
                ng.textContent=cardNumbers[wincard].g[ug]
                ng.style.position='absolute'
                ng.style.backgroundColor='green';
                ng.style.color='#fff';

            for(let mr=0;mr<calledNumber.g.length;mr++){
                   
                     if(Number(cardNumbers[wincard].g[ug])===Number(calledNumber.g[mr])){
                        ng.style.backgroundColor='gold'
                        ng.style.color='black'
                     }
                 
                } 

                ng.style.padding='10px';
                ng.style.width='40px'
                ng.style.borderRadius='10px';
                ng.style.marginLeft='165px'
                ng.style.marginTop='-815px';
                xm.appendChild(ng)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)
               
             }   



             for(let uo=0;uo<cardNumbers[wincard].o.length;uo++){
                
                const no=document.createElement('p');
                no.textContent=cardNumbers[wincard].o[uo]
                no.style.position='absolute'
                no.style.backgroundColor='green';
                no.style.color='#fff';

            for(let mr=0;mr<calledNumber.o.length;mr++){
                   
                     if(Number(cardNumbers[wincard].o[uo])===Number(calledNumber.o[mr])){
                        no.style.backgroundColor='gold'
                        no.style.color='black'
                     }
                 
                } 
                no.style.padding='10px';
                no.style.width='40px'
                no.style.borderRadius='10px';
                no.style.marginLeft='220px'
                no.style.marginTop='-1085px';
                xm.appendChild(no)

                const brr=document.createElement('br')
                xm.appendChild(brr)

                const brrr=document.createElement('br')
                xm.appendChild(brrr)

                const brrrr=document.createElement('br')
                xm.appendChild(brrrr)
               
             }   

         
             const crdname=document.createElement('p')
             crdname.textContent=cardNumbers[wincard].cardname
             crdname.style.color='black'
             crdname.style.backgroundColor='rgb(211, 218, 248)';
             crdname.style.padding='10px'
             crdname.style.color="black";
             crdname.style.width='230px';
             crdname.style.textAlign='center';
             crdname.style.fontFamily='arial'
             crdname.style.border='none'
             crdname.style.marginTop='-1100px'
             xm.appendChild(crdname);

            const winbtn=document.createElement('button')
            winbtn.textContent='ዘግትዋል';
            winbtn.style.backgroundColor='blue';
            winbtn.style.padding='10px';
            winbtn.style.fontSize='15px';
            winbtn.style.borderRadius='0px';
            winbtn.style.border='none';
            winbtn.style.width='100px'
            winbtn.style.margin='5px';
            winbtn.style.color='#fff';
            winbtn.style.cursor='pointer'
            winbtn.addEventListener('click',()=>{
               document.querySelector('.num').innerHTML=''
               number_of_players=0;
               calledNumber.b=[]
               calledNumber.i=[]
               calledNumber.n=[]
               calledNumber.g=[]
               calledNumber.o=[]

               const winn2=document.querySelector('.allsound2');
               winn2.play();
               

                const yai=document.createElement('img')
                yai.setAttribute('src',gamepictwo)
                yai.style.width='200px';
                yai.style.height='200px';
                yai.style.borderRadius='80px';
                yai.style.position='absolute';
                yai.style.marginLeft='130px';
                yai.style.marginTop='-400px';
                yai.style.border='4px solid gold'
                document.querySelector('.cardNumber').appendChild(yai)

                const ya=document.createElement('p');
                ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} በትክክል ዘግትዋል`
                ya.style.padding='20px';
                ya.style.backgroundColor='rgb(247, 170, 25)'
                ya.style.color='black';
                ya.style.position='absolute';
                ya.style.marginLeft='80px';
                ya.style.marginTop='-230px'
                ya.style.border='3px solid gold'
                document.querySelector('.cardNumber').appendChild(ya);
               
                 

               const newgame=document.createElement('button')
               newgame.textContent='አዲስ ጨዋታ ጀምር';
               newgame.style.backgroundColor='gold';
               newgame.style.textAlign='center';
               newgame.style.position='absolute';
               newgame.style.marginLeft='120px';
               newgame.style.marginTop='-160px'
               newgame.style.padding='20px'
               newgame.style.cursor='pointer'
               newgame.style.width='200px';
               newgame.style.border='none'
               newgame.style.backgroundColor='rgb(151, 14, 201)'
               newgame.style.color='#fff';
               newgame.addEventListener('click',()=>{
                  document.querySelector('.num').innerHTML=''
                  number_of_players=0;
                  gameIndicatorValue=1
                  for(let k=0;k<actual_bingo_number.length;k++){
                     document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                    // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                     document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                 }
                 
                  
                 

                 //newgame.innerHTML='';
                  //xm.innerHTML='';
                  actual_bingo_number=[];
                  takencards=[];
                  xm.innerHTML=''
                  
                  document.querySelector('.registercards').innerHTML=''
                  //clearInterval(caller);
                  document.querySelector('.cardNumber').style.display='none'
                  ya.remove()
                  yai.remove()
                  newgame.remove();
                  document.querySelector('.bt1').style.backgroundColor='black';
                  document.querySelector('.btnn1').style.backgroundColor='black';
                  document.querySelector('.bt1').style.color='gold';
                  document.querySelector('.btnn1').style.color='gold';
////////////////////// Time count Starting session //////////////////////


///////////////////// ending time counting session ///////////////////

               })
               document.querySelector('.cardNumber').appendChild(newgame); 
               
            
               })

            xm.appendChild(winbtn);

            const loss=document.createElement('button')
            loss.textContent='አልዘጋም';
            loss.style.backgroundColor='red';
            loss.style.cursor='pointer'
            loss.style.fontSize='15px';
            loss.style.width='100px'
            loss.style.padding='10px';
            loss.style.border='none';
            loss.style.marginLeft='20px';
            loss.style.position='absolute'
            loss.style.marginTop='8px'
            loss.style.borderRadius='0px';
            loss.style.color='#fff';
            loss.addEventListener('click',()=>{
               ////////////////////not closed...////////////////
               for(let j=0;j<takencards.length;j++){
                  if(takencards[j]===cardNumbers[wincard].cardname){
                    takencards[j]=0;
                  }
              }


               const yai=document.createElement('img')
               yai.setAttribute('src',lossx)
               yai.style.width='200px';
               yai.style.height='200px';
               yai.style.borderRadius='80px';
               yai.style.position='absolute';
               yai.style.marginLeft='130px';
               yai.style.marginTop='-400px';
               yai.style.border='4px solid gold'
               document.querySelector('.cardNumber').appendChild(yai)
            
               const ya=document.createElement('p');
               ya.textContent=`ካርቴላ ቁጥር ${cardNumbers[wincard].cardname} አልዘጋም`
               ya.style.padding='20px';
               ya.style.backgroundColor='red'
               ya.style.color='#fff';
               ya.style.position='absolute';
               ya.style.marginLeft='80px';
               ya.style.marginTop='-230px'
               ya.style.border='3px solid gold'
               document.querySelector('.cardNumber').appendChild(ya);
               
               document.querySelector('.allsound4').play();
               /////////////////////end of not closed...//////////
            })
            xm.appendChild(loss);

            const xx1=document.createElement('br')
            xm.appendChild(xx1)

            const xxx1=document.createElement('br')
            xm.appendChild(xxx1)

            const xxxx1=document.createElement('br')
            xm.appendChild(xxxx1)

            const xxxxx=document.createElement('br')
            xm.appendChild(xxxxx)


                            /////////// ending of displaying the card numbers even if 
                            //          they are not called or passed
                            //
                            //////////////////////////////////////////////////////////
                      

                          //alert(`${bingo[puase]} is not exist in your card`)
                          document.querySelector('.closecheak').innerHTML=''
                          let xx=document.querySelector('.closecheak')
                              xx.style.position='absolute'
                              xx.style.marginLeft='500px';
                              xx.style.marginTop='-400px';
                              xx.style.height='500px';
                              let xp=document.createElement('p')
                              xp.textContent=`${bingo[puase]} ቁጥር ካርድ ${document.querySelector('.cardinput').value} ቁጥር ላይ የለም`
                              xp.style.backgroundColor='red';
                              xp.style.color='#fff'
                              xp.style.margin='20px'
                              xp.style.padding='20px';
                              xp.style.borderRadius='10px'
                              xx.appendChild(xp)

                              let kx=document.createElement('p')
                              kx.textContent='X'
                              kx.style.position='absolute'
                              kx.style.marginTop='-75px';
                              kx.style.marginLeft='260px';
                              kx.style.borderRadius='20px';
                              kx.style.backgroundColor='gray'
                              kx.style.borderRadius='20px';
                              kx.style.padding='5px'
                              kx.style.cursor='pointer'
                              kx.addEventListener('click',()=>{
                                 xx.innerHTML='';    
                              })
                              xx.appendChild(kx);
                            
                        }

                          }

                  }else{
                     alert(`ካርቴል ቁጥር ${document.querySelector('.cardinput').value} አልተመዘገበም`)
                  }
               }
            }
           
for(let n=0;n<takencards.length;n++){
   document.querySelector(`.${takencards[n]}`).style.backgroundColor='rgb(30, 7, 25)';
   document.querySelector(`.${takencards[n]}`).style.color='white';

}

         }}>መዝጋቱን ያረጋግጡ</button>
         <button style={{marginLeft:'30px',width:'200px',marginTop:'250px',fontSize:'16px',backgroundColor:'rgb(125, 13, 109)'}} onClick={()=>{
            document.querySelector('.closecheakcard').innerHTML=''
            document.querySelector('.cardNumber').style.display='none'
            
            ///////////////////// Countinoue /////////////////
            //                                              //
            //                                              //
            //               Continoue Logic                //
            //                                              //
            ///////////////////////////////////////////////////

             a=actual_bingo_number.indexOf(bingo[puase])               
             // let a=0;
               caller=setInterval(()=>{
              //import `b${ctual_bingo_number[a]}` from `./audio/b${actual_bingo_number[a]}`
                let kt=document.createElement('p')
                kt.textContent=actual_bingo_number[a]
                kt.style.position='absolute';
                kt.style.backgroundColor='blue';
                kt.style.padding='20px';
                kt.style.marginTop='-20px'
                kt.style.marginLeft='-10px'
                kt.style.borderRadius='40px'
              
                kt.style.fontSize='35px'
////// starting of intervals of bingo and priviouse call
if(actual_bingo_number[a]<=15){
   const b=document.createElement('p')
   b.textContent='B';
   b.style.position='absolute';
   b.style.backgroundColor='rgb(10, 122, 66)';
   b.style.padding='20px';
   b.style.marginTop='-20px';
   b.style.marginLeft='-80px';
   b.style.borderRadius='30px';
   b.style.fontSize='35px';
   b.style.width='70px';
   b.style.height='70px';

   document.querySelector('.num').appendChild(b)
 }  
 else if(actual_bingo_number[a]>15 && actual_bingo_number[a]<=30){
         const i=document.createElement('p')
         i.textContent='I';
         i.style.position='absolute';
         i.style.backgroundColor='rgb(10, 122, 66)';
         i.style.padding='20px';
         i.style.marginTop='-20px';
         i.style.marginLeft='-80px';
         i.style.borderRadius='30px';
         i.style.fontSize='35px';
         i.style.width='70px';
         i.style.height='70px'
        document.querySelector('.num').appendChild(i)
 } 
 else if(actual_bingo_number[a]>30 && actual_bingo_number[a]<=45){
   const n=document.createElement('p')
   n.textContent='N';
   n.style.position='absolute';
   n.style.backgroundColor='rgb(10, 122, 66)';
   n.style.padding='20px';
   n.style.marginTop='-20px';
   n.style.marginLeft='-80px';
   n.style.borderRadius='30px';
   n.style.fontSize='35px';
   n.style.width='70px';
   n.style.height='70px'; 

   document.querySelector('.num').appendChild(n)
 }      
 else if(actual_bingo_number[a]>45 && actual_bingo_number[a]<=60){
   const g=document.createElement('p')
   g.textContent='G';
   g.style.position='absolute';
   g.style.backgroundColor='rgb(10, 122, 66)';
   g.style.padding='20px';
   g.style.marginTop='-20px';
   g.style.marginLeft='-80px';
   g.style.borderRadius='30px';
   g.style.fontSize='35px';
   g.style.width='70px';
   g.style.height='70px';

   document.querySelector('.num').appendChild(g)
 }
 else if(actual_bingo_number[a]>60 && actual_bingo_number[a]<=75){
   const o=document.createElement('p')
   o.textContent='O';
   o.style.position='absolute';
   o.style.backgroundColor='rgb(10, 122, 66)';
   o.style.padding='20px';
   o.style.marginTop='-20px';
   o.style.marginLeft='-80px';
   o.style.borderRadius='30px';
   o.style.fontSize='35px';
   o.style.width='70px';
   o.style.height='70px'

   document.querySelector('.num').appendChild(o)
 }

          document.querySelector('.num').appendChild(kt)


///////////////// starting privouse call ////////////////////
const o1=document.createElement('p')
   o1.textContent=actual_bingo_number[actual_bingo_number.indexOf(actual_bingo_number[a])-1];
   o1.style.position='absolute';
   o1.style.backgroundColor='rgb(151, 13, 47)';
   o1.style.padding='15px';
   o1.style.marginTop='-15px';
   o1.style.textAlign='center';
   o1.style.marginLeft='80px';
   o1.style.borderRadius='30px';
   o1.style.fontSize='35px';
   o1.style.width='60px';
   o1.style.height='60px'

   document.querySelector('.num').appendChild(o1)


/////////////////////////ending of privouse call ///////////
///////////ending of interval and privouse call


                document.querySelector('.num').appendChild(kt)
                document.querySelector(`.b${actual_bingo_number[a]}`).style.backgroundColor='gold'
                document.querySelector(`.b${actual_bingo_number[a]}`).style.fontWeight='bold'
                document.querySelector(`.b${actual_bingo_number[a]}`).style.fontSize='25px'
                document.querySelector(`.b${actual_bingo_number[a]}`).style.justifyContent='center'
                document.querySelector(`.b${actual_bingo_number[a]}`).style.color='black'
               // const ktm=document.querySelector('.sound')
               // const roar=document.createElement('source')
               
               //roar.remove(); 
         //your called number logic is here
         if(actual_bingo_number[a]<=15){       
             calledNumber.b.push(actual_bingo_number[a])
               //now add sound for the game
               if(actual_bingo_number[a]===1){
               // roar.remove()
               const ktm1=document.querySelector('.sound1')
                const roar=document.createElement('source')
                roar.setAttribute('src',b1)
             
                //roar.setAttribute('src',b50) 
                 ktm1.appendChild(roar)
                  ktm1.play()
                  
               }
               else if(actual_bingo_number[a]===2){
                //roar.remove()
                const ktm2=document.querySelector('.sound2')
                const roar=document.createElement('source')
                roar.setAttribute('src',b2)
             
                //roar.setAttribute('src',b50) 
                 ktm2.appendChild(roar)
                  ktm2.play()
                  
               }
               else if(actual_bingo_number[a]===3){
               // roar.remove()
               const ktm3=document.querySelector('.sound3')
                const roar=document.createElement('source')
                roar.setAttribute('src',b3)
                 
                //roar.setAttribute('src',b50) 
                 ktm3.appendChild(roar)
                  ktm3.play()
                  
               }
               else if(actual_bingo_number[a]===4){
                const ktm4=document.querySelector('.sound4')
                const roar=document.createElement('source')
                roar.setAttribute('src',b4)
             
                //roar.setAttribute('src',b50) 
                 ktm4.appendChild(roar)
                  ktm4.play()
                  
               }
               else if(actual_bingo_number[a]===5){
               // roar.remove()
               const ktm5=document.querySelector('.sound5')
                const roar=document.createElement('source')
                roar.setAttribute('src',b5)
             
                //roar.setAttribute('src',b50) 
                 ktm5.appendChild(roar)
                  ktm5.play()
                  
               }
               else if(actual_bingo_number[a]===6){
               // roar.remove()
               const ktm6=document.querySelector('.sound6')
                const roar=document.createElement('source')
                roar.setAttribute('src',b6)
             
                //roar.setAttribute('src',b50) 
                 ktm6.appendChild(roar)
                  ktm6.play()
                  
               }
               else if(actual_bingo_number[a]===7){

               // roar.remove()
               const ktm7=document.querySelector('.sound7')
                const roar=document.createElement('source')
                roar.setAttribute('src',b7)
             
                //roar.setAttribute('src',b50) 
                 ktm7.appendChild(roar)
                  ktm7.play()
                  
               }
               else if(actual_bingo_number[a]===8){
               // roar.remove()
               const ktm8=document.querySelector('.sound8')
                const roar=document.createElement('source')
                roar.setAttribute('src',b8)
             
                //roar.setAttribute('src',b50) 
                 ktm8.appendChild(roar)
                  ktm8.play()
                  
               }
               else if(actual_bingo_number[a]===9){
               // roar.remove()
               const ktm9=document.querySelector('.sound9')
                const roar=document.createElement('source')
                roar.setAttribute('src',b9)
             
                //roar.setAttribute('src',b50) 
                 ktm9.appendChild(roar)
                  ktm9.play()
                
               }
               else if(actual_bingo_number[a]===10){
               // roar.remove()
               const ktm10=document.querySelector('.sound10')
                const roar=document.createElement('source')
                roar.setAttribute('src',b10)
             
                //roar.setAttribute('src',b50) 
                 ktm10.appendChild(roar)
                  ktm10.play()
                  
               }
               else if(actual_bingo_number[a]===11){
               // roar.remove()
               const ktm11=document.querySelector('.sound11')
                const roar=document.createElement('source')
                roar.setAttribute('src',b11)
             
                //roar.setAttribute('src',b50) 
                 ktm11.appendChild(roar)
                  ktm11.play()
                  
               }
               else if(actual_bingo_number[a]===12){
               // roar.remove()
               const ktm12=document.querySelector('.sound12')
                const roar=document.createElement('source')
                roar.setAttribute('src',b12)
             
                //roar.setAttribute('src',b50) 
                 ktm12.appendChild(roar)
                  ktm12.play()
                  
               }
               else if(actual_bingo_number[a]===13){
               // roar.remove()
               const ktm13=document.querySelector('.sound13')
                const roar=document.createElement('source')
                roar.setAttribute('src',b13)
             
                //roar.setAttribute('src',b50) 
                 ktm13.appendChild(roar)
                  ktm13.play()
                  
               }
               else if(actual_bingo_number[a]===14){
               // roar.remove()
               const ktm14=document.querySelector('.sound14')
                const roar=document.createElement('source')
                roar.setAttribute('src',b14)
             
                //roar.setAttribute('src',b50) 
                 ktm14.appendChild(roar)
                  ktm14.play()
                  
               }
               else if(actual_bingo_number[a]===15){
               // roar.remove()
               const ktm15=document.querySelector('.sound15')
                const roar=document.createElement('source')
                roar.setAttribute('src',b15)
             
                //roar.setAttribute('src',b50) 
                 ktm15.appendChild(roar)
                  ktm15.play()
                 
               }
              
           }
         else if(actual_bingo_number[a]>=16 && actual_bingo_number[a]<=30){
            calledNumber.i.push(actual_bingo_number[a])
            if(actual_bingo_number[a]===16){
              // roar.remove()
              const ktm16=document.querySelector('.sound16')
               const roar=document.createElement('source')
               roar.setAttribute('src',b16)
            
               //roar.setAttribute('src',b50) 
                ktm16.appendChild(roar)
                 ktm16.play()
                 
              }
              else if(actual_bingo_number[a]===17){
               //roar.remove()
               const ktm17=document.querySelector('.sound17')
               const roar=document.createElement('source')
               roar.setAttribute('src',b17)
            
               //roar.setAttribute('src',b50) 
                ktm17.appendChild(roar)
                 ktm17.play()
                 
              }
              else if(actual_bingo_number[a]===18){
              // roar.remove()
              const ktm18=document.querySelector('.sound18')
               const roar=document.createElement('source')
               roar.setAttribute('src',b18)
                
               //roar.setAttribute('src',b50) 
                ktm18.appendChild(roar)
                 ktm18.play()
                 
              }
              else if(actual_bingo_number[a]===19){
               const ktm19=document.querySelector('.sound19')
               const roar=document.createElement('source')
               roar.setAttribute('src',b19)
            
               //roar.setAttribute('src',b50) 
                ktm19.appendChild(roar)
                 ktm19.play()
                 
              }
              else if(actual_bingo_number[a]===20){
              // roar.remove()
              const ktm20=document.querySelector('.sound20')
               const roar=document.createElement('source')
               roar.setAttribute('src',b20)
            
               //roar.setAttribute('src',b50) 
                ktm20.appendChild(roar)
                 ktm20.play()
                 
              }
              else if(actual_bingo_number[a]===21){
              // roar.remove()
              const ktm21=document.querySelector('.sound21')
               const roar=document.createElement('source')
               roar.setAttribute('src',b21)
            
               //roar.setAttribute('src',b50) 
                ktm21.appendChild(roar)
                 ktm21.play()
                 
              }
              else if(actual_bingo_number[a]===22){

              // roar.remove()
              const ktm22=document.querySelector('.sound22')
               const roar=document.createElement('source')
               roar.setAttribute('src',b22)
            
               //roar.setAttribute('src',b50) 
                ktm22.appendChild(roar)
                 ktm22.play()
                 
              }
              else if(actual_bingo_number[a]===23){
              // roar.remove()
              const ktm23=document.querySelector('.sound23')
               const roar=document.createElement('source')
               roar.setAttribute('src',b23)
            
               //roar.setAttribute('src',b50) 
                ktm23.appendChild(roar)
                ktm23.play()
                 
              }
              else if(actual_bingo_number[a]===24){
              // roar.remove()
              const ktm24=document.querySelector('.sound24')
               const roar=document.createElement('source')
               roar.setAttribute('src',b24)
            
               //roar.setAttribute('src',b50) 
                ktm24.appendChild(roar)
                 ktm24.play()
               
              }
              else if(actual_bingo_number[a]===25){
              // roar.remove()
              const ktm25=document.querySelector('.sound25')
               const roar=document.createElement('source')
               roar.setAttribute('src',b25)
            
               //roar.setAttribute('src',b50) 
                ktm25.appendChild(roar)
                 ktm25.play()
                 
              }
              else if(actual_bingo_number[a]===26){
              // roar.remove()
              const ktm26=document.querySelector('.sound26')
               const roar=document.createElement('source')
               roar.setAttribute('src',b26)
            
               //roar.setAttribute('src',b50) 
                ktm26.appendChild(roar)
                 ktm26.play()
                 
              }
              else if(actual_bingo_number[a]===27){
              // roar.remove()
              const ktm27=document.querySelector('.sound27')
               const roar=document.createElement('source')
               roar.setAttribute('src',b27)
            
               //roar.setAttribute('src',b50) 
                ktm27.appendChild(roar)
                 ktm27.play()
                 
              }
              else if(actual_bingo_number[a]===28){
              // roar.remove()
              const ktm28=document.querySelector('.sound28')
               const roar=document.createElement('source')
               roar.setAttribute('src',b28)
            
               //roar.setAttribute('src',b50) 
                ktm28.appendChild(roar)
                 ktm28.play()
                 
              }
              else if(actual_bingo_number[a]===29){
              // roar.remove()
              const ktm29=document.querySelector('.sound29')
               const roar=document.createElement('source')
               roar.setAttribute('src',b29)
            
               //roar.setAttribute('src',b50) 
                ktm29.appendChild(roar)
                 ktm29.play()
                 
              }
              else if(actual_bingo_number[a]===30){
              // roar.remove()
              const ktm30=document.querySelector('.sound30')
               const roar=document.createElement('source')
               roar.setAttribute('src',b30)
            
               //roar.setAttribute('src',b50) 
                ktm30.appendChild(roar)
                 ktm30.play()
                
              }
         }
         else if(actual_bingo_number[a]>=31 && actual_bingo_number[a]<=45){
             calledNumber.n.push(actual_bingo_number[a])
             if(actual_bingo_number[a]===31){
              // roar.remove()
              const ktm31=document.querySelector('.sound31')
               const roar=document.createElement('source')
               roar.setAttribute('src',b31)
            
               //roar.setAttribute('src',b50) 
                ktm31.appendChild(roar)
                 ktm31.play()
                 
              }
              else if(actual_bingo_number[a]===32){
               //roar.remove()
               const ktm32=document.querySelector('.sound32')
               const roar=document.createElement('source')
               roar.setAttribute('src',b32)
            
               //roar.setAttribute('src',b50) 
                ktm32.appendChild(roar)
                 ktm32.play()
                 
              }
              else if(actual_bingo_number[a]===33){
              // roar.remove()
              const ktm33=document.querySelector('.sound33')
               const roar=document.createElement('source')
               roar.setAttribute('src',b33)
                
               //roar.setAttribute('src',b50) 
                ktm33.appendChild(roar)
                 ktm33.play()
                 
              }
              else if(actual_bingo_number[a]===34){
               const ktm34=document.querySelector('.sound34')
               const roar=document.createElement('source')
               roar.setAttribute('src',b34)
            
               //roar.setAttribute('src',b50) 
                ktm34.appendChild(roar)
                 ktm34.play()
                 
              }
              else if(actual_bingo_number[a]===35){
              // roar.remove()
              const ktm35=document.querySelector('.sound35')
               const roar=document.createElement('source')
               roar.setAttribute('src',b35)
            
               //roar.setAttribute('src',b50) 
                ktm35.appendChild(roar)
                 ktm35.play()
                 
              }
              else if(actual_bingo_number[a]===36){
              // roar.remove()
              const ktm36=document.querySelector('.sound36')
               const roar=document.createElement('source')
               roar.setAttribute('src',b36)
            
               //roar.setAttribute('src',b50) 
                ktm36.appendChild(roar)
                 ktm36.play()
                 
              }
              else if(actual_bingo_number[a]===37){

              // roar.remove()
              const ktm37=document.querySelector('.sound37')
               const roar=document.createElement('source')
               roar.setAttribute('src',b37)
            
               //roar.setAttribute('src',b50) 
                ktm37.appendChild(roar)
                 ktm37.play()
                 
              }
              else if(actual_bingo_number[a]===38){
              // roar.remove()
              const ktm38=document.querySelector('.sound38')
               const roar=document.createElement('source')
               roar.setAttribute('src',b38)
            
               //roar.setAttribute('src',b50) 
                ktm38.appendChild(roar)
                 ktm38.play()
                 
              }
              else if(actual_bingo_number[a]===39){
              // roar.remove()
              const ktm39=document.querySelector('.sound39')
               const roar=document.createElement('source')
               roar.setAttribute('src',b39)
            
               //roar.setAttribute('src',b50) 
                ktm39.appendChild(roar)
                 ktm39.play()
               
              }
              else if(actual_bingo_number[a]===40){
              // roar.remove()
              const ktm40=document.querySelector('.sound40')
               const roar=document.createElement('source')
               roar.setAttribute('src',b40)
            
               //roar.setAttribute('src',b50) 
                ktm40.appendChild(roar)
                 ktm40.play()
                 
              }
              else if(actual_bingo_number[a]===41){
              // roar.remove()
              const ktm41=document.querySelector('.sound41')
               const roar=document.createElement('source')
               roar.setAttribute('src',b41)
            
               //roar.setAttribute('src',b50) 
                ktm41.appendChild(roar)
                 ktm41.play()
                 
              }
              else if(actual_bingo_number[a]===42){
              // roar.remove()
              const ktm42=document.querySelector('.sound42')
               const roar=document.createElement('source')
               roar.setAttribute('src',b42)
            
               //roar.setAttribute('src',b50) 
                ktm42.appendChild(roar)
                 ktm42.play()
                 
              }
              else if(actual_bingo_number[a]===43){
              // roar.remove()
              const ktm43=document.querySelector('.sound43')
               const roar=document.createElement('source')
               roar.setAttribute('src',b43)
            
               //roar.setAttribute('src',b50) 
                ktm43.appendChild(roar)
                 ktm43.play()
                 
              }
              else if(actual_bingo_number[a]===44){
              // roar.remove()
              const ktm44=document.querySelector('.sound44')
               const roar=document.createElement('source')
               roar.setAttribute('src',b44)
            
               //roar.setAttribute('src',b50) 
                ktm44.appendChild(roar)
                 ktm44.play()
                 
              }
              else if(actual_bingo_number[a]===45){
              // roar.remove()
              const ktm45=document.querySelector('.sound45')
               const roar=document.createElement('source')
               roar.setAttribute('src',b45)
            
               //roar.setAttribute('src',b50) 
                ktm45.appendChild(roar)
                 ktm45.play()
                
              }

         }
         else if(actual_bingo_number[a]>=46 && actual_bingo_number[a]<=60){
             calledNumber.g.push(actual_bingo_number[a])
             if(actual_bingo_number[a]===46){
              // roar.remove()
              const ktm46=document.querySelector('.sound46')
               const roar=document.createElement('source')
               roar.setAttribute('src',b46)
            
               //roar.setAttribute('src',b50) 
                ktm46.appendChild(roar)
                 ktm46.play()
                 
              }
              else if(actual_bingo_number[a]===47){
               //roar.remove()
               const ktm47=document.querySelector('.sound47')
               const roar=document.createElement('source')
               roar.setAttribute('src',b47)
            
               //roar.setAttribute('src',b50) 
                ktm47.appendChild(roar)
                 ktm47.play()
                 
              }
              else if(actual_bingo_number[a]===48){
              // roar.remove()
              const ktm48=document.querySelector('.sound48')
               const roar=document.createElement('source')
               roar.setAttribute('src',b48)
                
               //roar.setAttribute('src',b50) 
                ktm48.appendChild(roar)
                 ktm48.play()
                 
              }
              else if(actual_bingo_number[a]===49){
               const ktm49=document.querySelector('.sound49')
               const roar=document.createElement('source')
               roar.setAttribute('src',b49)
            
               //roar.setAttribute('src',b50) 
                ktm49.appendChild(roar)
                 ktm49.play()
                 
              }
              else if(actual_bingo_number[a]===50){
              // roar.remove()
              const ktm50=document.querySelector('.sound50')
               const roar=document.createElement('source')
               roar.setAttribute('src',b50)
            
               //roar.setAttribute('src',b50) 
                ktm50.appendChild(roar)
                 ktm50.play()
                 
              }
              else if(actual_bingo_number[a]===51){
              // roar.remove()
              const ktm51=document.querySelector('.sound51')
               const roar=document.createElement('source')
               roar.setAttribute('src',b51)
            
               //roar.setAttribute('src',b50) 
                ktm51.appendChild(roar)
                 ktm51.play()
                 
              }
              else if(actual_bingo_number[a]===52){

              // roar.remove()
              const ktm52=document.querySelector('.sound52')
               const roar=document.createElement('source')
               roar.setAttribute('src',b52)
            
               //roar.setAttribute('src',b50) 
                ktm52.appendChild(roar)
                 ktm52.play()
                 
              }
              else if(actual_bingo_number[a]===53){
              // roar.remove()
              const ktm53=document.querySelector('.sound53')
               const roar=document.createElement('source')
               roar.setAttribute('src',b53)
            
               //roar.setAttribute('src',b50) 
                ktm53.appendChild(roar)
                 ktm53.play()
                 
              }
              else if(actual_bingo_number[a]===54){
              // roar.remove()
              const ktm54=document.querySelector('.sound54')
               const roar=document.createElement('source')
               roar.setAttribute('src',b54)
            
               //roar.setAttribute('src',b50) 
                ktm54.appendChild(roar)
                 ktm54.play()
               
              }
              else if(actual_bingo_number[a]===55){
              // roar.remove()
              const ktm55=document.querySelector('.sound55')
               const roar=document.createElement('source')
               roar.setAttribute('src',b55)
            
               //roar.setAttribute('src',b50) 
                ktm55.appendChild(roar)
                 ktm55.play()
                 
              }
              else if(actual_bingo_number[a]===56){
              // roar.remove()
              const ktm56=document.querySelector('.sound56')
               const roar=document.createElement('source')
               roar.setAttribute('src',b56)
            
               //roar.setAttribute('src',b50) 
                ktm56.appendChild(roar)
                 ktm56.play()
                 
              }
              else if(actual_bingo_number[a]===57){
              // roar.remove()
              const ktm57=document.querySelector('.sound57')
               const roar=document.createElement('source')
               roar.setAttribute('src',b57)
            
               //roar.setAttribute('src',b50) 
                ktm57.appendChild(roar)
                 ktm57.play()
                 
              }
              else if(actual_bingo_number[a]===58){
              // roar.remove()
              const ktm58=document.querySelector('.sound58')
               const roar=document.createElement('source')
               roar.setAttribute('src',b58)
            
               //roar.setAttribute('src',b50) 
                ktm58.appendChild(roar)
                 ktm58.play()
                 
              }
              else if(actual_bingo_number[a]===59){
              // roar.remove()
              const ktm59=document.querySelector('.sound59')
               const roar=document.createElement('source')
               roar.setAttribute('src',b59)
            
               //roar.setAttribute('src',b50) 
                ktm59.appendChild(roar)
                 ktm59.play()
                 
              }
              else if(actual_bingo_number[a]===60){
              // roar.remove()
              const ktm60=document.querySelector('.sound60')
               const roar=document.createElement('source')
               roar.setAttribute('src',b60)
            
               //roar.setAttribute('src',b50) 
                ktm60.appendChild(roar)
                 ktm60.play()
                
              }
         }
         else if(actual_bingo_number[a]>=61 && actual_bingo_number[a]<=75){
             calledNumber.o.push(actual_bingo_number[a])
             if(actual_bingo_number[a]===61){
              // roar.remove()
              const ktm61=document.querySelector('.sound61')
               const roar=document.createElement('source')
               roar.setAttribute('src',b61)
            
               //roar.setAttribute('src',b50) 
                ktm61.appendChild(roar)
                 ktm61.play()
                 
              }
              else if(actual_bingo_number[a]===62){
               //roar.remove()
               const ktm62=document.querySelector('.sound62')
               const roar=document.createElement('source')
               roar.setAttribute('src',b62)
            
               //roar.setAttribute('src',b50) 
                ktm62.appendChild(roar)
                 ktm62.play()
                 
              }
              else if(actual_bingo_number[a]===63){
              // roar.remove()
              const ktm63=document.querySelector('.sound63')
               const roar=document.createElement('source')
               roar.setAttribute('src',b63)
                
               //roar.setAttribute('src',b50) 
                ktm63.appendChild(roar)
                 ktm63.play()
                 
              }
              else if(actual_bingo_number[a]===64){
               const ktm64=document.querySelector('.sound64')
               const roar=document.createElement('source')
               roar.setAttribute('src',b64)
            
               //roar.setAttribute('src',b50) 
                ktm64.appendChild(roar)
                 ktm64.play()
                 
              }
              else if(actual_bingo_number[a]===65){
              // roar.remove()
              const ktm65=document.querySelector('.sound65')
               const roar=document.createElement('source')
               roar.setAttribute('src',b65)
            
               //roar.setAttribute('src',b50) 
                ktm65.appendChild(roar)
                 ktm65.play()
                 
              }
              else if(actual_bingo_number[a]===66){
              // roar.remove()
              const ktm66=document.querySelector('.sound66')
               const roar=document.createElement('source')
               roar.setAttribute('src',b66)
            
               //roar.setAttribute('src',b50) 
                ktm66.appendChild(roar)
                 ktm66.play()
                 
              }
              else if(actual_bingo_number[a]===67){

              // roar.remove()
              const ktm67=document.querySelector('.sound67')
               const roar=document.createElement('source')
               roar.setAttribute('src',b67)
            
               //roar.setAttribute('src',b50) 
                ktm67.appendChild(roar)
                 ktm67.play()
                 
              }
              else if(actual_bingo_number[a]===68){
              // roar.remove()
              const ktm68=document.querySelector('.sound68')
               const roar=document.createElement('source')
               roar.setAttribute('src',b68)
            
               //roar.setAttribute('src',b50) 
                ktm68.appendChild(roar)
                 ktm68.play()
                 
              }
              else if(actual_bingo_number[a]===69){
              // roar.remove()
              const ktm69=document.querySelector('.sound69')
               const roar=document.createElement('source')
               roar.setAttribute('src',b69)
            
               //roar.setAttribute('src',b50) 
                ktm69.appendChild(roar)
                 ktm69.play()
               
              }
              else if(actual_bingo_number[a]===70){
              // roar.remove()
              const ktm70=document.querySelector('.sound70')
               const roar=document.createElement('source')
               roar.setAttribute('src',b70)
            
               //roar.setAttribute('src',b50) 
                ktm70.appendChild(roar)
                 ktm70.play()
                 
              }
              else if(actual_bingo_number[a]===71){
              // roar.remove()
              const ktm71=document.querySelector('.sound71')
               const roar=document.createElement('source')
               roar.setAttribute('src',b71)
            
               //roar.setAttribute('src',b50) 
                ktm71.appendChild(roar)
                 ktm71.play()
                 
              }
              else if(actual_bingo_number[a]===72){
              // roar.remove()
              const ktm72=document.querySelector('.sound72')
               const roar=document.createElement('source')
               roar.setAttribute('src',b72)
            
               //roar.setAttribute('src',b50) 
                ktm72.appendChild(roar)
                 ktm72.play()
                 
              }
              else if(actual_bingo_number[a]===73){
              // roar.remove()
              const ktm73=document.querySelector('.sound73')
               const roar=document.createElement('source')
               roar.setAttribute('src',b73)
            
               //roar.setAttribute('src',b50) 
                ktm73.appendChild(roar)
                 ktm73.play()
                 
              }
              else if(actual_bingo_number[a]===74){
              // roar.remove()
              const ktm74=document.querySelector('.sound74')
               const roar=document.createElement('source')
               roar.setAttribute('src',b74)
            
               //roar.setAttribute('src',b50) 
                ktm74.appendChild(roar)
                 ktm74.play()
                 
              }
              else if(actual_bingo_number[a]===75){
              // roar.remove()
              const ktm75=document.querySelector('.sound75')
               const roar=document.createElement('source')
               roar.setAttribute('src',b75)
            
               //roar.setAttribute('src',b50) 
                ktm75.appendChild(roar)
                 ktm75.play()
                
              }
         }
         else{
          console.log('wrong number was decleared...')
         }     
               puase=a;
                a++;

                if(actual_bingo_number[a]===actual_bingo_number[a.length]){
                  console.log(calledNumber)  
                  clearInterval(caller);
                    alert('ጨዋታውን ተፈፀመ')
                    document.querySelector('.str').innerHTML='Game Over With No Winner';
                   // document.querySelector('.str').style.color='gold';
                    for(let k=0;k<actual_bingo_number.length;k++){
                        document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                       // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                        document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                    }
                }
              },7000)




         /////////////////// end of Continoue Logic end code//////////   
         //                                                   //
         //                                                   // 
         //                end of Continoue Logic code        //         
         //                                                   //
         ///////////////////////////////////////////////////////
         }}>ጨዋታውን ቀጥል</button><br/>
        
        <button style={{position:'absolute',marginTop:'400px',marginLeft:'100px',backgroundColor:'red',width:'300px',height:'50px',fontSize:'20px'}} onClick={()=>{
          gameIndicatorValue=1
          for(let k=0;k<actual_bingo_number.length;k++){
             document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
            // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
             document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
         }
         
          
         

         //newgame.innerHTML='';
          //xm.innerHTML='';
          actual_bingo_number=[];
          calledNumber.b=[]
          calledNumber.i=[]
          calledNumber.n=[]
          calledNumber.g=[]
          calledNumber.o=[]

       
         document.querySelector('.cardNumber').style.display='none'; 
         document.querySelector('.closecheakcard').innerHTML=''
         document.querySelector('.num').innerHTML=''      
                  document.querySelector('.bt1').style.backgroundColor='black';
                  document.querySelector('.btnn1').style.backgroundColor='black';
                  document.querySelector('.bt1').style.color='gold';
                  document.querySelector('.btnn1').style.color='gold';
         
        }}>ጨዋታውን አፍርስ</button>
      </div>
              <div className='closecheak'></div>
              <div className='closecheakcardd'>
                 <ul>
                    <li>B</li>
                    <li>I</li>
                    <li>N</li>
                    <li>G</li>
                    <li>O</li>
                 </ul>
                 <div className='closecheakcard'></div>
              </div>
  </div>
</div>



<div className="foot">
   
  <div className="fgame">
     <div className='counterone'></div>
   <select value={valuss} onChange={gameplay}>
      <option>የጨዋታውን ዝግ ምረጥ</option>
      <option >ጨዋታ 1 ዝግ በተገኘበት</option>
      <option >ጨዋታ 2 ዝግ በተገኘበት</option>
      <option >ጨዋታ 3 ዝግ በተገኘበት</option>
      <option>ጨዋታ 2 ወደታች</option>
      <option>ጨዋታ 2 ወደጎን</option>
      <option>ጨዋታ 1 ወደጎን 1 ወደታች</option>
      <option>ጨዋታ 2 ዲያጎናል</option>
      <option>ጨዋታ  2 ሰኩዌር የማይገናኝ</option>
      <option>ጨዋታ 1 ድያጎናል 2 ስኩዌር የማይገናኝ</option>
      <option>ጨዋታ 2 ወደ ጎን 1 ስኩዌር የማይገናኝ</option>
      <option>2 ሰኩዌር ኮርነር ላይ </option>
      <option>ጨዋታ ሙሉ ዝግ</option>
   </select>

   <select value={mdb} onChange={allmedeb}>
      <option>5 ብር መደብ</option>
      <option>10 ብር መደብ</option>
      <option>15 ብር መደብ</option>
      <option>20 ብር መደብ</option>
      <option>25 ብር መደብ</option>
      <option>30 ብር መደብ</option>
      <option>35 ብር መደብ</option>
      <option>40 ብር መደብ</option>
      <option>45 ብር መደብ</option>
      <option>50 ብር መደብ</option>
      <option>100 ብር መደብ</option>
      <option>150 ብር መደብ</option>
      <option>200 ብር መደብ</option>
      <option>300 ብር መደብ</option>
      <option>500 ብር መደብ</option>
      <option>1000 ብር መደብ</option>
   </select>
  <button className="bt1" onClick={()=>{
    if(gameIndicatorValue===1){
      gameIndicatorValue=0
      document.querySelector('.bt1').style.backgroundColor='green'; 
      document.querySelector('.bt1').style.color='#fff' 
      clearInterval(count)
      ctth();

      document.querySelector('.counterone').style.position='absolute';
      document.querySelector('.counterone').style.backgroundColor='gold'
      document.querySelector('.counterone').style.padding='5px'
      document.querySelector('.counterone').style.width='150px'
      document.querySelector('.counterone').innerHTML='ጨዋታው ጀመረ'
     

                const bingoNumber=[] 
                       
              for(let i=1;i<=75;i++){
                var num=Math.floor(Math.random()*75)+1
                bingoNumber.push(num)
              }
              const actual_bingo_numberr=[...new Set(bingoNumber)]
              
              for(let i=1;i<=75;i++){
               var numm=Math.floor(Math.random()*75)+1
               actual_bingo_numberr.push(numm)
             }

             //the final generated number
             const actual_bingo_numberrr=[...new Set(actual_bingo_numberr)]
              
             for(let i=1;i<=75;i++){
              var nummm=Math.floor(Math.random()*75)+1
              actual_bingo_numberrr.push(nummm)
            }

            const actual_bingo_numberrrr=[...new Set(actual_bingo_numberrr)]
              
             for(let i=1;i<=75;i++){
              var nummmm=Math.floor(Math.random()*75)+1
              actual_bingo_numberrrr.push(nummmm)
            }


            const actual_bingo_numberrrr1=[...new Set(actual_bingo_numberrrr)]
              
            for(let i=1;i<=75;i++){
             var nummmm1=Math.floor(Math.random()*75)+1
             actual_bingo_numberrrr1.push(nummmm1)
           }

             actual_bingo_number=[...new Set(actual_bingo_numberrrr1)]
             bingo=actual_bingo_number
              //  const bingoo=actual_bingo_number
            //const tak=[]
            //now generate new random numbers
            // const newbingoNumber=[]
            
             

              //const nk=document.querySelector('.num')
              let a=0;
               caller=setInterval(()=>{
              //import `b${ctual_bingo_number[a]}` from `./audio/b${actual_bingo_number[a]}`
                let kt=document.createElement('p')
                kt.textContent=actual_bingo_number[a]
                kt.style.position='absolute';
                kt.style.backgroundColor='blue';
                kt.style.padding='20px';
                kt.style.marginTop='-20px'
                kt.style.marginLeft='-10px'
                kt.style.borderRadius='40px'
                kt.style.fontSize='35px'

       if(actual_bingo_number[a]<=15){
         const b=document.createElement('p')
         b.textContent='B';
         b.style.position='absolute';
         b.style.backgroundColor='blue';
         b.style.padding='20px';
         b.style.marginTop='-20px';
         b.style.marginLeft='-80px';
         b.style.borderRadius='30px';
         b.style.fontSize='35px';
         b.style.width='70px';
         b.style.height='70px';

         document.querySelector('.num').appendChild(b)
       }  
       else if(actual_bingo_number[a]>15 && actual_bingo_number[a]<=30){
               const i=document.createElement('p')
               i.textContent='I';
               i.style.position='absolute';
               i.style.backgroundColor='blue';
               i.style.padding='20px';
               i.style.marginTop='-20px';
               i.style.marginLeft='-80px';
               i.style.borderRadius='30px';
               i.style.fontSize='35px';
               i.style.width='70px';
               i.style.height='70px'
              document.querySelector('.num').appendChild(i)
       } 
       else if(actual_bingo_number[a]>30 && actual_bingo_number[a]<=45){
         const n=document.createElement('p')
         n.textContent='N';
         n.style.position='absolute';
         n.style.backgroundColor='blue';
         n.style.padding='20px';
         n.style.marginTop='-20px';
         n.style.marginLeft='-80px';
         n.style.borderRadius='30px';
         n.style.fontSize='35px';
         n.style.width='70px';
         n.style.height='70px'; 

         document.querySelector('.num').appendChild(n)
       }      
       else if(actual_bingo_number[a]>45 && actual_bingo_number[a]<=60){
         const g=document.createElement('p')
         g.textContent='G';
         g.style.position='absolute';
         g.style.backgroundColor='blue';
         g.style.padding='20px';
         g.style.marginTop='-20px';
         g.style.marginLeft='-80px';
         g.style.borderRadius='30px';
         g.style.fontSize='35px';
         g.style.width='70px';
         g.style.height='70px';

         document.querySelector('.num').appendChild(g)
       }
       else if(actual_bingo_number[a]>60 && actual_bingo_number[a]<=75){
         const o=document.createElement('p')
         o.textContent='O';
         o.style.position='absolute';
         o.style.backgroundColor='blue';
         o.style.padding='20px';
         o.style.marginTop='-20px';
         o.style.marginLeft='-80px';
         o.style.borderRadius='30px';
         o.style.fontSize='35px';
         o.style.width='70px';
         o.style.height='70px'

         document.querySelector('.num').appendChild(o)
       }

                document.querySelector('.num').appendChild(kt)


///////////////// starting privouse call ////////////////////
   const o1=document.createElement('p')
         o1.textContent=actual_bingo_number[actual_bingo_number.indexOf(actual_bingo_number[a])-1];
         o1.style.position='absolute';
         o1.style.backgroundColor='rgb(151, 13, 47)';
         o1.style.padding='15px';
         o1.style.marginTop='-15px';
         o1.style.textAlign='center';
         o1.style.marginLeft='80px';
         o1.style.borderRadius='30px';
         o1.style.fontSize='35px';
         o1.style.width='60px';
         o1.style.height='60px'

         document.querySelector('.num').appendChild(o1)


/////////////////////////ending of privouse call ///////////
                document.querySelector(`.b${actual_bingo_number[a]}`).style.backgroundColor='gold'
                document.querySelector(`.b${actual_bingo_number[a]}`).style.fontWeight='bold'
                document.querySelector(`.b${actual_bingo_number[a]}`).style.fontSize='25px'
                document.querySelector(`.b${actual_bingo_number[a]}`).style.justifyContent='center'
                document.querySelector(`.b${actual_bingo_number[a]}`).style.color='black'
               // const ktm=document.querySelector('.sound')
               // const roar=document.createElement('source')
               
               //roar.remove(); 
         //your called number logic is here
         if(actual_bingo_number[a]<=15){       
             calledNumber.b.push(actual_bingo_number[a])
               //now add sound for the game
               if(actual_bingo_number[a]===1){
               // roar.remove()
               const ktm1=document.querySelector('.sound1')
                const roar=document.createElement('source')
                roar.setAttribute('src',b1)
             
                //roar.setAttribute('src',b50) 
                 ktm1.appendChild(roar)
                  ktm1.play()
                  
               }
               else if(actual_bingo_number[a]===2){
                //roar.remove()
                const ktm2=document.querySelector('.sound2')
                const roar=document.createElement('source')
                roar.setAttribute('src',b2)
             
                //roar.setAttribute('src',b50) 
                 ktm2.appendChild(roar)
                  ktm2.play()
                  
               }
               else if(actual_bingo_number[a]===3){
               // roar.remove()
               const ktm3=document.querySelector('.sound3')
                const roar=document.createElement('source')
                roar.setAttribute('src',b3)
                 
                //roar.setAttribute('src',b50) 
                 ktm3.appendChild(roar)
                  ktm3.play()
                  
               }
               else if(actual_bingo_number[a]===4){
                const ktm4=document.querySelector('.sound4')
                const roar=document.createElement('source')
                roar.setAttribute('src',b4)
             
                //roar.setAttribute('src',b50) 
                 ktm4.appendChild(roar)
                  ktm4.play()
                  
               }
               else if(actual_bingo_number[a]===5){
               // roar.remove()
               const ktm5=document.querySelector('.sound5')
                const roar=document.createElement('source')
                roar.setAttribute('src',b5)
             
                //roar.setAttribute('src',b50) 
                 ktm5.appendChild(roar)
                  ktm5.play()
                  
               }
               else if(actual_bingo_number[a]===6){
               // roar.remove()
               const ktm6=document.querySelector('.sound6')
                const roar=document.createElement('source')
                roar.setAttribute('src',b6)
             
                //roar.setAttribute('src',b50) 
                 ktm6.appendChild(roar)
                  ktm6.play()
                  
               }
               else if(actual_bingo_number[a]===7){

               // roar.remove()
               const ktm7=document.querySelector('.sound7')
                const roar=document.createElement('source')
                roar.setAttribute('src',b7)
             
                //roar.setAttribute('src',b50) 
                 ktm7.appendChild(roar)
                  ktm7.play()
                  
               }
               else if(actual_bingo_number[a]===8){
               // roar.remove()
               const ktm8=document.querySelector('.sound8')
                const roar=document.createElement('source')
                roar.setAttribute('src',b8)
             
                //roar.setAttribute('src',b50) 
                 ktm8.appendChild(roar)
                  ktm8.play()
                  
               }
               else if(actual_bingo_number[a]===9){
               // roar.remove()
               const ktm9=document.querySelector('.sound9')
                const roar=document.createElement('source')
                roar.setAttribute('src',b9)
             
                //roar.setAttribute('src',b50) 
                 ktm9.appendChild(roar)
                  ktm9.play()
                
               }
               else if(actual_bingo_number[a]===10){
               // roar.remove()
               const ktm10=document.querySelector('.sound10')
                const roar=document.createElement('source')
                roar.setAttribute('src',b10)
             
                //roar.setAttribute('src',b50) 
                 ktm10.appendChild(roar)
                  ktm10.play()
                  
               }
               else if(actual_bingo_number[a]===11){
               // roar.remove()
               const ktm11=document.querySelector('.sound11')
                const roar=document.createElement('source')
                roar.setAttribute('src',b11)
             
                //roar.setAttribute('src',b50) 
                 ktm11.appendChild(roar)
                  ktm11.play()
                  
               }
               else if(actual_bingo_number[a]===12){
               // roar.remove()
               const ktm12=document.querySelector('.sound12')
                const roar=document.createElement('source')
                roar.setAttribute('src',b12)
             
                //roar.setAttribute('src',b50) 
                 ktm12.appendChild(roar)
                  ktm12.play()
                  
               }
               else if(actual_bingo_number[a]===13){
               // roar.remove()
               const ktm13=document.querySelector('.sound13')
                const roar=document.createElement('source')
                roar.setAttribute('src',b13)
             
                //roar.setAttribute('src',b50) 
                 ktm13.appendChild(roar)
                  ktm13.play()
                  
               }
               else if(actual_bingo_number[a]===14){
               // roar.remove()
               const ktm14=document.querySelector('.sound14')
                const roar=document.createElement('source')
                roar.setAttribute('src',b14)
             
                //roar.setAttribute('src',b50) 
                 ktm14.appendChild(roar)
                  ktm14.play()
                  
               }
               else if(actual_bingo_number[a]===15){
               // roar.remove()
               const ktm15=document.querySelector('.sound15')
                const roar=document.createElement('source')
                roar.setAttribute('src',b15)
             
                //roar.setAttribute('src',b50) 
                 ktm15.appendChild(roar)
                  ktm15.play()
                 
               }
              
           }
         else if(actual_bingo_number[a]>=16 && actual_bingo_number[a]<=30){
            calledNumber.i.push(actual_bingo_number[a])
            if(actual_bingo_number[a]===16){
              // roar.remove()
              const ktm16=document.querySelector('.sound16')
               const roar=document.createElement('source')
               roar.setAttribute('src',b16)
            
               //roar.setAttribute('src',b50) 
                ktm16.appendChild(roar)
                 ktm16.play()
                 
              }
              else if(actual_bingo_number[a]===17){
               //roar.remove()
               const ktm17=document.querySelector('.sound17')
               const roar=document.createElement('source')
               roar.setAttribute('src',b17)
            
               //roar.setAttribute('src',b50) 
                ktm17.appendChild(roar)
                 ktm17.play()
                 
              }
              else if(actual_bingo_number[a]===18){
              // roar.remove()
              const ktm18=document.querySelector('.sound18')
               const roar=document.createElement('source')
               roar.setAttribute('src',b18)
                
               //roar.setAttribute('src',b50) 
                ktm18.appendChild(roar)
                 ktm18.play()
                 
              }
              else if(actual_bingo_number[a]===19){
               const ktm19=document.querySelector('.sound19')
               const roar=document.createElement('source')
               roar.setAttribute('src',b19)
            
               //roar.setAttribute('src',b50) 
                ktm19.appendChild(roar)
                 ktm19.play()
                 
              }
              else if(actual_bingo_number[a]===20){
              // roar.remove()
              const ktm20=document.querySelector('.sound20')
               const roar=document.createElement('source')
               roar.setAttribute('src',b20)
            
               //roar.setAttribute('src',b50) 
                ktm20.appendChild(roar)
                 ktm20.play()
                 
              }
              else if(actual_bingo_number[a]===21){
              // roar.remove()
              const ktm21=document.querySelector('.sound21')
               const roar=document.createElement('source')
               roar.setAttribute('src',b21)
            
               //roar.setAttribute('src',b50) 
                ktm21.appendChild(roar)
                 ktm21.play()
                 
              }
              else if(actual_bingo_number[a]===22){

              // roar.remove()
              const ktm22=document.querySelector('.sound22')
               const roar=document.createElement('source')
               roar.setAttribute('src',b22)
            
               //roar.setAttribute('src',b50) 
                ktm22.appendChild(roar)
                 ktm22.play()
                 
              }
              else if(actual_bingo_number[a]===23){
              // roar.remove()
              const ktm23=document.querySelector('.sound23')
               const roar=document.createElement('source')
               roar.setAttribute('src',b23)
            
               //roar.setAttribute('src',b50) 
                ktm23.appendChild(roar)
                ktm23.play()
                 
              }
              else if(actual_bingo_number[a]===24){
              // roar.remove()
              const ktm24=document.querySelector('.sound24')
               const roar=document.createElement('source')
               roar.setAttribute('src',b24)
            
               //roar.setAttribute('src',b50) 
                ktm24.appendChild(roar)
                 ktm24.play()
               
              }
              else if(actual_bingo_number[a]===25){
              // roar.remove()
              const ktm25=document.querySelector('.sound25')
               const roar=document.createElement('source')
               roar.setAttribute('src',b25)
            
               //roar.setAttribute('src',b50) 
                ktm25.appendChild(roar)
                 ktm25.play()
                 
              }
              else if(actual_bingo_number[a]===26){
              // roar.remove()
              const ktm26=document.querySelector('.sound26')
               const roar=document.createElement('source')
               roar.setAttribute('src',b26)
            
               //roar.setAttribute('src',b50) 
                ktm26.appendChild(roar)
                 ktm26.play()
                 
              }
              else if(actual_bingo_number[a]===27){
              // roar.remove()
              const ktm27=document.querySelector('.sound27')
               const roar=document.createElement('source')
               roar.setAttribute('src',b27)
            
               //roar.setAttribute('src',b50) 
                ktm27.appendChild(roar)
                 ktm27.play()
                 
              }
              else if(actual_bingo_number[a]===28){
              // roar.remove()
              const ktm28=document.querySelector('.sound28')
               const roar=document.createElement('source')
               roar.setAttribute('src',b28)
            
               //roar.setAttribute('src',b50) 
                ktm28.appendChild(roar)
                 ktm28.play()
                 
              }
              else if(actual_bingo_number[a]===29){
              // roar.remove()
              const ktm29=document.querySelector('.sound29')
               const roar=document.createElement('source')
               roar.setAttribute('src',b29)
            
               //roar.setAttribute('src',b50) 
                ktm29.appendChild(roar)
                 ktm29.play()
                 
              }
              else if(actual_bingo_number[a]===30){
              // roar.remove()
              const ktm30=document.querySelector('.sound30')
               const roar=document.createElement('source')
               roar.setAttribute('src',b30)
            
               //roar.setAttribute('src',b50) 
                ktm30.appendChild(roar)
                 ktm30.play()
                
              }
         }
         else if(actual_bingo_number[a]>=31 && actual_bingo_number[a]<=45){
             calledNumber.n.push(actual_bingo_number[a])
             if(actual_bingo_number[a]===31){
              // roar.remove()
              const ktm31=document.querySelector('.sound31')
               const roar=document.createElement('source')
               roar.setAttribute('src',b31)
            
               //roar.setAttribute('src',b50) 
                ktm31.appendChild(roar)
                 ktm31.play()
                 
              }
              else if(actual_bingo_number[a]===32){
               //roar.remove()
               const ktm32=document.querySelector('.sound32')
               const roar=document.createElement('source')
               roar.setAttribute('src',b32)
            
               //roar.setAttribute('src',b50) 
                ktm32.appendChild(roar)
                 ktm32.play()
                 
              }
              else if(actual_bingo_number[a]===33){
              // roar.remove()
              const ktm33=document.querySelector('.sound33')
               const roar=document.createElement('source')
               roar.setAttribute('src',b33)
                
               //roar.setAttribute('src',b50) 
                ktm33.appendChild(roar)
                 ktm33.play()
                 
              }
              else if(actual_bingo_number[a]===34){
               const ktm34=document.querySelector('.sound34')
               const roar=document.createElement('source')
               roar.setAttribute('src',b34)
            
               //roar.setAttribute('src',b50) 
                ktm34.appendChild(roar)
                 ktm34.play()
                 
              }
              else if(actual_bingo_number[a]===35){
              // roar.remove()
              const ktm35=document.querySelector('.sound35')
               const roar=document.createElement('source')
               roar.setAttribute('src',b35)
            
               //roar.setAttribute('src',b50) 
                ktm35.appendChild(roar)
                 ktm35.play()
                 
              }
              else if(actual_bingo_number[a]===36){
              // roar.remove()
              const ktm36=document.querySelector('.sound36')
               const roar=document.createElement('source')
               roar.setAttribute('src',b36)
            
               //roar.setAttribute('src',b50) 
                ktm36.appendChild(roar)
                 ktm36.play()
                 
              }
              else if(actual_bingo_number[a]===37){

              // roar.remove()
              const ktm37=document.querySelector('.sound37')
               const roar=document.createElement('source')
               roar.setAttribute('src',b37)
            
               //roar.setAttribute('src',b50) 
                ktm37.appendChild(roar)
                 ktm37.play()
                 
              }
              else if(actual_bingo_number[a]===38){
              // roar.remove()
              const ktm38=document.querySelector('.sound38')
               const roar=document.createElement('source')
               roar.setAttribute('src',b38)
            
               //roar.setAttribute('src',b50) 
                ktm38.appendChild(roar)
                 ktm38.play()
                 
              }
              else if(actual_bingo_number[a]===39){
              // roar.remove()
              const ktm39=document.querySelector('.sound39')
               const roar=document.createElement('source')
               roar.setAttribute('src',b39)
            
               //roar.setAttribute('src',b50) 
                ktm39.appendChild(roar)
                 ktm39.play()
               
              }
              else if(actual_bingo_number[a]===40){
              // roar.remove()
              const ktm40=document.querySelector('.sound40')
               const roar=document.createElement('source')
               roar.setAttribute('src',b40)
            
               //roar.setAttribute('src',b50) 
                ktm40.appendChild(roar)
                 ktm40.play()
                 
              }
              else if(actual_bingo_number[a]===41){
              // roar.remove()
              const ktm41=document.querySelector('.sound41')
               const roar=document.createElement('source')
               roar.setAttribute('src',b41)
            
               //roar.setAttribute('src',b50) 
                ktm41.appendChild(roar)
                 ktm41.play()
                 
              }
              else if(actual_bingo_number[a]===42){
              // roar.remove()
              const ktm42=document.querySelector('.sound42')
               const roar=document.createElement('source')
               roar.setAttribute('src',b42)
            
               //roar.setAttribute('src',b50) 
                ktm42.appendChild(roar)
                 ktm42.play()
                 
              }
              else if(actual_bingo_number[a]===43){
              // roar.remove()
              const ktm43=document.querySelector('.sound43')
               const roar=document.createElement('source')
               roar.setAttribute('src',b43)
            
               //roar.setAttribute('src',b50) 
                ktm43.appendChild(roar)
                 ktm43.play()
                 
              }
              else if(actual_bingo_number[a]===44){
              // roar.remove()
              const ktm44=document.querySelector('.sound44')
               const roar=document.createElement('source')
               roar.setAttribute('src',b44)
            
               //roar.setAttribute('src',b50) 
                ktm44.appendChild(roar)
                 ktm44.play()
                 
              }
              else if(actual_bingo_number[a]===45){
              // roar.remove()
              const ktm45=document.querySelector('.sound45')
               const roar=document.createElement('source')
               roar.setAttribute('src',b45)
            
               //roar.setAttribute('src',b50) 
                ktm45.appendChild(roar)
                 ktm45.play()
                
              }

         }
         else if(actual_bingo_number[a]>=46 && actual_bingo_number[a]<=60){
             calledNumber.g.push(actual_bingo_number[a])
             if(actual_bingo_number[a]===46){
              // roar.remove()
              const ktm46=document.querySelector('.sound46')
               const roar=document.createElement('source')
               roar.setAttribute('src',b46)
            
               //roar.setAttribute('src',b50) 
                ktm46.appendChild(roar)
                 ktm46.play()
                 
              }
              else if(actual_bingo_number[a]===47){
               //roar.remove()
               const ktm47=document.querySelector('.sound47')
               const roar=document.createElement('source')
               roar.setAttribute('src',b47)
            
               //roar.setAttribute('src',b50) 
                ktm47.appendChild(roar)
                 ktm47.play()
                 
              }
              else if(actual_bingo_number[a]===48){
              // roar.remove()
              const ktm48=document.querySelector('.sound48')
               const roar=document.createElement('source')
               roar.setAttribute('src',b48)
                
               //roar.setAttribute('src',b50) 
                ktm48.appendChild(roar)
                 ktm48.play()
                 
              }
              else if(actual_bingo_number[a]===49){
               const ktm49=document.querySelector('.sound49')
               const roar=document.createElement('source')
               roar.setAttribute('src',b49)
            
               //roar.setAttribute('src',b50) 
                ktm49.appendChild(roar)
                 ktm49.play()
                 
              }
              else if(actual_bingo_number[a]===50){
              // roar.remove()
              const ktm50=document.querySelector('.sound50')
               const roar=document.createElement('source')
               roar.setAttribute('src',b50)
            
               //roar.setAttribute('src',b50) 
                ktm50.appendChild(roar)
                 ktm50.play()
                 
              }
              else if(actual_bingo_number[a]===51){
              // roar.remove()
              const ktm51=document.querySelector('.sound51')
               const roar=document.createElement('source')
               roar.setAttribute('src',b51)
            
               //roar.setAttribute('src',b50) 
                ktm51.appendChild(roar)
                 ktm51.play()
                 
              }
              else if(actual_bingo_number[a]===52){

              // roar.remove()
              const ktm52=document.querySelector('.sound52')
               const roar=document.createElement('source')
               roar.setAttribute('src',b52)
            
               //roar.setAttribute('src',b50) 
                ktm52.appendChild(roar)
                 ktm52.play()
                 
              }
              else if(actual_bingo_number[a]===53){
              // roar.remove()
              const ktm53=document.querySelector('.sound53')
               const roar=document.createElement('source')
               roar.setAttribute('src',b53)
            
               //roar.setAttribute('src',b50) 
                ktm53.appendChild(roar)
                 ktm53.play()
                 
              }
              else if(actual_bingo_number[a]===54){
              // roar.remove()
              const ktm54=document.querySelector('.sound54')
               const roar=document.createElement('source')
               roar.setAttribute('src',b54)
            
               //roar.setAttribute('src',b50) 
                ktm54.appendChild(roar)
                 ktm54.play()
               
              }
              else if(actual_bingo_number[a]===55){
              // roar.remove()
              const ktm55=document.querySelector('.sound55')
               const roar=document.createElement('source')
               roar.setAttribute('src',b55)
            
               //roar.setAttribute('src',b50) 
                ktm55.appendChild(roar)
                 ktm55.play()
                 
              }
              else if(actual_bingo_number[a]===56){
              // roar.remove()
              const ktm56=document.querySelector('.sound56')
               const roar=document.createElement('source')
               roar.setAttribute('src',b56)
            
               //roar.setAttribute('src',b50) 
                ktm56.appendChild(roar)
                 ktm56.play()
                 
              }
              else if(actual_bingo_number[a]===57){
              // roar.remove()
              const ktm57=document.querySelector('.sound57')
               const roar=document.createElement('source')
               roar.setAttribute('src',b57)
            
               //roar.setAttribute('src',b50) 
                ktm57.appendChild(roar)
                 ktm57.play()
                 
              }
              else if(actual_bingo_number[a]===58){
              // roar.remove()
              const ktm58=document.querySelector('.sound58')
               const roar=document.createElement('source')
               roar.setAttribute('src',b58)
            
               //roar.setAttribute('src',b50) 
                ktm58.appendChild(roar)
                 ktm58.play()
                 
              }
              else if(actual_bingo_number[a]===59){
              // roar.remove()
              const ktm59=document.querySelector('.sound59')
               const roar=document.createElement('source')
               roar.setAttribute('src',b59)
            
               //roar.setAttribute('src',b50) 
                ktm59.appendChild(roar)
                 ktm59.play()
                 
              }
              else if(actual_bingo_number[a]===60){
              // roar.remove()
              const ktm60=document.querySelector('.sound60')
               const roar=document.createElement('source')
               roar.setAttribute('src',b60)
            
               //roar.setAttribute('src',b50) 
                ktm60.appendChild(roar)
                 ktm60.play()
                
              }
         }
         else if(actual_bingo_number[a]>=61 && actual_bingo_number[a]<=75){
             calledNumber.o.push(actual_bingo_number[a])
             if(actual_bingo_number[a]===61){
              // roar.remove()
              const ktm61=document.querySelector('.sound61')
               const roar=document.createElement('source')
               roar.setAttribute('src',b61)
            
               //roar.setAttribute('src',b50) 
                ktm61.appendChild(roar)
                 ktm61.play()
                 
              }
              else if(actual_bingo_number[a]===62){
               //roar.remove()
               const ktm62=document.querySelector('.sound62')
               const roar=document.createElement('source')
               roar.setAttribute('src',b62)
            
               //roar.setAttribute('src',b50) 
                ktm62.appendChild(roar)
                 ktm62.play()
                 
              }
              else if(actual_bingo_number[a]===63){
              // roar.remove()
              const ktm63=document.querySelector('.sound63')
               const roar=document.createElement('source')
               roar.setAttribute('src',b63)
                
               //roar.setAttribute('src',b50) 
                ktm63.appendChild(roar)
                 ktm63.play()
                 
              }
              else if(actual_bingo_number[a]===64){
               const ktm64=document.querySelector('.sound64')
               const roar=document.createElement('source')
               roar.setAttribute('src',b64)
            
               //roar.setAttribute('src',b50) 
                ktm64.appendChild(roar)
                 ktm64.play()
                 
              }
              else if(actual_bingo_number[a]===65){
              // roar.remove()
              const ktm65=document.querySelector('.sound65')
               const roar=document.createElement('source')
               roar.setAttribute('src',b65)
            
               //roar.setAttribute('src',b50) 
                ktm65.appendChild(roar)
                 ktm65.play()
                 
              }
              else if(actual_bingo_number[a]===66){
              // roar.remove()
              const ktm66=document.querySelector('.sound66')
               const roar=document.createElement('source')
               roar.setAttribute('src',b66)
            
               //roar.setAttribute('src',b50) 
                ktm66.appendChild(roar)
                 ktm66.play()
                 
              }
              else if(actual_bingo_number[a]===67){

              // roar.remove()
              const ktm67=document.querySelector('.sound67')
               const roar=document.createElement('source')
               roar.setAttribute('src',b67)
            
               //roar.setAttribute('src',b50) 
                ktm67.appendChild(roar)
                 ktm67.play()
                 
              }
              else if(actual_bingo_number[a]===68){
              // roar.remove()
              const ktm68=document.querySelector('.sound68')
               const roar=document.createElement('source')
               roar.setAttribute('src',b68)
            
               //roar.setAttribute('src',b50) 
                ktm68.appendChild(roar)
                 ktm68.play()
                 
              }
              else if(actual_bingo_number[a]===69){
              // roar.remove()
              const ktm69=document.querySelector('.sound69')
               const roar=document.createElement('source')
               roar.setAttribute('src',b69)
            
               //roar.setAttribute('src',b50) 
                ktm69.appendChild(roar)
                 ktm69.play()
               
              }
              else if(actual_bingo_number[a]===70){
              // roar.remove()
              const ktm70=document.querySelector('.sound70')
               const roar=document.createElement('source')
               roar.setAttribute('src',b70)
            
               //roar.setAttribute('src',b50) 
                ktm70.appendChild(roar)
                 ktm70.play()
                 
              }
              else if(actual_bingo_number[a]===71){
              // roar.remove()
              const ktm71=document.querySelector('.sound71')
               const roar=document.createElement('source')
               roar.setAttribute('src',b71)
            
               //roar.setAttribute('src',b50) 
                ktm71.appendChild(roar)
                 ktm71.play()
                 
              }
              else if(actual_bingo_number[a]===72){
              // roar.remove()
              const ktm72=document.querySelector('.sound72')
               const roar=document.createElement('source')
               roar.setAttribute('src',b72)
            
               //roar.setAttribute('src',b50) 
                ktm72.appendChild(roar)
                 ktm72.play()
                 
              }
              else if(actual_bingo_number[a]===73){
              // roar.remove()
               const ktm73=document.querySelector('.sound73')
               const roar=document.createElement('source')
               roar.setAttribute('src',b73)
            
               //roar.setAttribute('src',b50) 
                ktm73.appendChild(roar)
                ktm73.play()
                 
              }
              else if(actual_bingo_number[a]===74){
              // roar.remove()
              const ktm74=document.querySelector('.sound74')
               const roar=document.createElement('source')
               roar.setAttribute('src',b74)
            
               //roar.setAttribute('src',b50) 
                ktm74.appendChild(roar)
                 ktm74.play()
                 
              }
              else if(actual_bingo_number[a]===75){
              // roar.remove()
              const ktm75=document.querySelector('.sound75')
               const roar=document.createElement('source')
               roar.setAttribute('src',b75)
            
               //roar.setAttribute('src',b50) 
                ktm75.appendChild(roar)
                 ktm75.play()
                
              }
         }
         else{
          console.log('wrong number was decleared...')
         }     
               puase=a;
                a++;

                if(actual_bingo_number[a]===actual_bingo_number[a.length]){
                  //console.log(calledNumber)  
                  clearInterval(caller);
                    alert('ጨዋታው ተፈፀመ')
                    document.querySelector('.str').innerHTML='Game Over With No Winner';
                   // document.querySelector('.str').style.color='gold';
                    for(let k=0;k<actual_bingo_number.length;k++){
                        document.querySelector(`.b${actual_bingo_number[k]}`).style.backgroundColor='rgb(30, 28, 28)'
                       // document.querySelector(`.b${actual_bingo_number[k]}`).style.fontWeight='bold'
                        document.querySelector(`.b${actual_bingo_number[k]}`).style.color='white'
                    }
                }
              },7000)

              //these is the code we write the code and the se isthe 

      const playersinfo={
         name:sessionStorage.getItem('nameE'),
         phone:sx,
         admin:sessionStorage.getItem('adxm'),
         players:number_of_players,
         medebs:medeb,
      } 

      authAxois.post('/employee/players/info',playersinfo).then(r=>r).catch(e=>e)
      
   }else{
         console.log('the game already stared')
      }

  }}>ጨዋታውን ጀምር</button>
  <button className='btnn1' onClick={()=>{
     const tg=document.querySelector('.cardNumber')
   
     tg.style.display='block';
     const kk=document.querySelector('.cnone')
     const gm=document.createElement('h2')
     gm.style.position='absolute'
     gm.style.marginTop='-50px';
     gm.style.marginLeft='-150px'
     gm.style.backgroundColor='#fff'
     gm.style.width='200px'
    
     if(bingo[puase]<=15){
      gm.textContent=`B ${bingo[puase]}`;
     }
     else if(bingo[puase]>=16 && bingo[puase]<=30){
      gm.textContent=`I ${bingo[puase]}`;
     }
     else if(bingo[puase]>=31 && bingo[puase]<=45){
      gm.textContent=`N ${bingo[puase]}`;
     }
     else if(bingo[puase]>=46 && bingo[puase]<=60){
      gm.textContent=`G ${bingo[puase]}`;
     }
     else if(bingo[puase]>=61 && bingo[puase]<=75){
      gm.textContent=`O ${bingo[puase]}`;
     }
     else{
      gm.textContent=`Invalid`;
     }

     kk.appendChild(gm)

    // alert(`index of ${puase} with value of ${bingo[puase]}`)
     clearInterval(caller)
     document.querySelector('.btnn1').style.backgroundColor='gold'
     document.querySelector('.btnn1').style.color='black'
     
     document.querySelector('.closecheak').innerHTML=''
     //document.querySelector('.cardNumber').innerHTML='' 

     const kbingo=document.querySelector('.allsound3')
     kbingo.play()

  }}>BINGO </button>
  <button className='btnn2' onClick={()=>{
   if(document.querySelector('.btnn2').innerHTML==='ጨዋታውን አቁም'){
      document.querySelector('.btnn2').innerHTML='PLAY';
      clearInterval(caller);
   }else{
      //alert('the game will start soon')
      document.querySelector('.btnn2').innerHTML='ጨዋታውን አቁም'
      clc();
   }


  }}>ጨዋታውን አቁም</button>
 </div>
</div>

       <div className="employ">
         <img src={avat2} alt=''/>
         <p onClick={()=>{
            if(document.querySelector('.percentage').style.display==='none'){
               document.querySelector('.percentage').style.display='block'
            }else{
               document.querySelector('.percentage').style.display='none'
            }
         }}>ጠሪ መለያ<br/>{sessionStorage.getItem('nameE')}</p>
         <div className='percentage' style={{display:'none'}}>
             <select value={pec} onChange={percVal}>
                <option>0.5</option>
                <option>0.6</option>
                <option>0.7</option>
                <option>0.8</option>
                <option>0.85</option>
                <option>0.9</option>
                <option>1</option>
             </select>
         </div>

            <div className='base'>
                <h2>
                በስልኮ ካርቴላ መርጠው ለመጫወት <br/>
                 እባክዎ ከስር ያለውን አድራሻ ይጎብኙ
                </h2>   
                <button>bingobet.net</button>
            </div>
       </div>




<div className='alltakencards'>
   <div className='alltakencardszero'>
      <p style={{position:'fixed',fontSize:'15px',cursor:'pointer',margin:'5px',backgroundColor:'red',color:'#fff',width:'40px',padding:'10px'}} onClick={()=>{
         document.querySelector('.alltakencards').style.display='none'
      }}>X</p>
      <h1>የተያዙ እና ያልተያዙ ካርቴላዎች ዝርዝር</h1>
   </div>
    <div className='alltakencardsone'>
     <button className='card1'>1</button>
     <button className='card2'>2</button>
     <button className='card3'>3</button>
     <button className='card4'>4</button>
     <button className='card5'>5</button>
     <button className='card6'>6</button>
     <button className='card7'>7</button>
     <button className='card8'>8</button>
     <button className='card9'>9</button>
     <button className='card10'>10</button>
     <button className='card11'>11</button>
     <button className='card12'>12</button>
     <button className='card13'>13</button>
     <button className='card14'>14</button>
     <button className='card15'>15</button>
     <button className='card16'>16</button>
     <button className='card17'>17</button>
     <button className='card18'>18</button>
     <button className='card19'>19</button>
     <button className='card20'>20</button>
     <button className='card21'>21</button>
     <button className='card22'>22</button>
     <button className='card23'>23</button>
     <button className='card24'>24</button>
     <button className='card25'>25</button>
     <button className='card26'>26</button>
     <button className='card27'>27</button>
     <button className='card28'>28</button>
     <button className='card29'>29</button>
     <button className='card30'>30</button>
     <button className='card31'>31</button>
     <button className='card32'>32</button>
     <button className='card33'>33</button>
     <button className='card34'>34</button>
     <button className='card35'>35</button>
     <button className='card36'>36</button>
     <button className='card37'>37</button>
     <button className='card38'>38</button>
     <button className='card39'>39</button>
     <button className='card40'>40</button>
     <button className='card41'>41</button>
     <button className='card42'>42</button>
     <button className='card43'>43</button>
     <button className='card44'>44</button>
     <button className='card45'>45</button>
     <button className='card46'>46</button>
     <button className='card47'>47</button>
     <button className='card48'>48</button>
     <button className='card49'>49</button>
     <button className='card50'>50</button>
     <button className='card51'>51</button>
     <button className='card52'>52</button>
     <button className='card53'>53</button>
     <button className='card54'>54</button>
     <button className='card55'>55</button>
     <button className='card56'>56</button>
     <button className='card57'>57</button>
     <button className='card58'>58</button>
     <button className='card59'>59</button>
     <button className='card60'>60</button>
     <button className='card61'>61</button>
     <button className='card62'>62</button>
     <button className='card63'>63</button>
     <button className='card64'>64</button>
     <button className='card65'>65</button>
     <button className='card66'>66</button>
     <button className='card67'>67</button>
     <button className='card68'>68</button>
     <button className='card69'>69</button>
     <button className='card70'>70</button>
     <button className='card71'>71</button>
     <button className='card72'>72</button>
     <button className='card73'>73</button>
     <button className='card74'>74</button>
     <button className='card75'>75</button>
     <button className='card76'>76</button>
     <button className='card78'>78</button>
     <button className='card79'>79</button>
     <button className='card80'>80</button>
     <button className='card81'>81</button>
     <button className='card82'>82</button>
     <button className='card83'>83</button>
     <button className='card84'>84</button>
     <button className='card85'>85</button>
     <button className='card86'>86</button>
     <button className='card87'>87</button>
     <button className='card89'>89</button>
     <button className='card90'>90</button>
     <button className='card91'>91</button>
     <button className='card92'>92</button>
     <button className='card93'>93</button>
     <button className='card94'>94</button>
     <button className='card95'>95</button>
     <button className='card96'>96</button>
     <button className='card97'>97</button>
     <button className='card98'>98</button>
     <button className='card99'>99</button>
     <button className='card100'>100</button>
     <button className='card101'>101</button>
     <button className='card102'>102</button>
     <button className='card103'>103</button>
     <button className='card104'>104</button>
     <button className='card105'>105</button>
     <button className='card106'>106</button>
     <button className='card107'>107</button>
     <button className='card108'>108</button>
     <button className='card109'>109</button>
     <button className='card110'>110</button>
     <button className='card111'>111</button>
     <button className='card46112'>112</button>
     <button className='card113'>113</button>
     <button className='card114'>114</button>
     <button className='card115'>115</button>
     <button className='card116'>116</button>
     <button className='card117'>117</button>
     <button className='card118'>118</button>
     <button className='card119'>119</button>
     <button className='card120'>120</button>
     <button className='card121'>121</button>
     <button className='card122'>122</button>
     <button className='card123'>123</button>
     <button className='card124'>124</button>
     <button className='card125'>125</button>
     <button className='card126'>126</button>
     <button className='card127'>127</button>
     <button className='card128'>128</button>
     <button className='card129'>129</button>
     <button className='card130'>130</button>
     <button className='card131'>131</button>
     <button className='card132'>132</button>
     <button className='card133'>133</button>
     <button className='card134'>134</button>
     <button className='card135'>135</button>
     <button className='card136'>136</button>
     <button className='card137'>137</button>
     <button className='card138'>138</button>
     <button className='card139'>139</button>
     <button className='card140'>140</button>
     <button className='card141'>141</button>
     <button className='card142'>142</button>
     <button className='card143'>143</button>
     <button className='card144'>144</button>
     <button className='card145'>145</button>
     <button className='card146'>146</button>
     <button className='card147'>147</button>
     <button className='card148'>148</button>
     <button className='card149'>149</button>
     <button className='card150'>150</button>
     <button className='card151'>151</button>
     <button className='card152'>152</button>
     <button className='card153'>153</button>
     <button className='card154'>154</button>
     <button className='card155'>155</button>
     <button className='card156'>156</button>
     <button className='card157'>157</button>
     <button className='card158'>158</button>
     <button className='card159'>159</button>
     <button className='card160'>160</button>
     <button className='card161'>161</button>
     <button className='card162'>162</button>
     <button className='card163'>163</button>
     <button className='card164'>164</button>
     <button className='card165'>165</button>
     <button className='card166'>166</button>
     <button className='card167'>167</button>
     <button className='card168'>168</button>
     <button className='card169'>169</button>
     <button className='card170'>170</button>
     <button className='card171'>171</button>
     <button className='card172'>172</button>
     <button className='card173'>173</button>
     <button className='card174'>174</button>
     <button className='card175'>175</button>
     <button className='card176'>176</button>
     <button className='card177'>177</button>
     <button className='card178'>178</button>
     <button className='card179'>179</button>
     <button className='card180'>180</button>
     <button className='card181'>181</button>
     <button className='card182'>182</button>
     <button className='card183'>183</button>
     <button className='card184'>184</button>
     <button className='card185'>185</button>
     <button className='card186'>186</button>
     <button className='card187'>187</button>
     <button className='card188'>188</button>
     <button className='card189'>189</button>
     <button className='card190'>190</button>
     <button className='card191'>191</button>
     <button className='card192'>192</button>
     <button className='card193'>193</button>
     <button className='card194'>194</button>
     <button className='card195'>195</button>
     <button className='card196'>196</button>
     <button className='card197'>197</button>
     <button className='card198'>198</button>
     <button className='card199'>199</button>
     <button className='card200'>200</button>
     <button className='card201'>201</button>
     <button className='card202'>202</button>
     <button className='card203'>203</button>
     <button className='card204'>204</button>
     <button className='card205'>205</button>
     <button className='card206'>206</button>
     <button className='card207'>207</button>
     <button className='card208'>208</button>
     <button className='card209'>209</button>
     <button className='card210'>210</button>
     <button className='card211'>211</button>
     <button className='card212'>212</button>
     <button className='card213'>213</button>
     <button className='card214'>214</button>
     <button className='card215'>215</button>
     <button className='card216'>216</button>
     <button className='card217'>217</button>
     <button className='card218'>218</button>
     <button className='card219'>219</button>
     <button className='card220'>220</button>
     <button className='card221'>221</button>
     <button className='card222'>222</button>
     <button className='card223'>223</button>
     <button className='card224'>224</button>
     <button className='card225'>225</button>
     <button className='card226'>226</button>
     <button className='card227'>227</button>
     <button className='card228'>228</button>
     <button className='card229'>229</button>
     <button className='card230'>230</button>
     <button className='card231'>231</button>
     <button className='card232'>232</button>
     <button className='card233'>233</button>
     <button className='card234'>234</button>
     <button className='card235'>235</button>
     <button className='card236'>236</button>
     <button className='card237'>237</button>
     <button className='card238'>238</button>
     <button className='card239'>239</button>
     <button className='card240'>240</button>
     <button className='card241'>241</button>
     <button className='card242'>242</button>
     <button className='card243'>243</button>
     <button className='card244'>244</button>
     <button className='card245'>245</button>
     <button className='card246'>246</button>
     <button className='card247'>247</button>
     <button className='card248'>248</button>
     <button className='card249'>249</button>
     <button className='card250'>250</button>
     <button className='card251'>251</button>
     <button className='card252'>252</button>
     <button className='card253'>253</button>
     <button className='card254'>254</button>
     <button className='card255'>255</button>
     <button className='card256'>256</button>
     <button className='card257'>257</button>
     <button className='card258'>258</button>
     <button className='card259'>259</button>
     <button className='card260'>260</button>
     <button className='card261'>261</button>
     <button className='card262'>262</button>
     <button className='card263'>263</button>
     <button className='card264'>264</button>
     <button className='card265'>265</button>
     <button className='card266'>266</button>
     <button className='card267'>267</button>
     <button className='card268'>268</button>
     <button className='card269'>269</button>
     <button className='card270'>270</button>
     <button className='card271'>271</button>
     <button className='card272'>272</button>
     <button className='card273'>273</button>
     <button className='card274'>274</button>
     <button className='card275'>275</button>
     <button className='card276'>276</button>
     <button className='card277'>277</button>
     <button className='card278'>278</button>
     <button className='card279'>279</button>
     <button className='card280'>280</button>
     <button className='card281'>281</button>
     <button className='card282'>282</button>
     <button className='card283'>283</button>
     <button className='card284'>284</button>
     <button className='card285'>285</button>
     <button className='card286'>286</button>
     <button className='card287'>287</button>
     <button className='card288'>288</button>
     <button className='card289'>289</button>
     <button className='card290'>290</button>
     <button className='card291'>291</button>
     <button className='card292'>292</button>
     <button className='card293'>293</button>
     <button className='card294'>294</button>
     <button className='card295'>295</button>
     <button className='card296'>296</button>
     <button className='card297'>297</button>
     <button className='card298'>298</button>
     <button className='card299'>299</button>
     <button className='card300'>300</button>
     <button className='card301'>301</button>
     <button className='card302'>302</button>
     <button className='card303'>303</button>
     <button className='card304'>304</button>
     <button className='card305'>305</button>
     <button className='card306'>306</button>
     <button className='card307'>307</button>
     <button className='card308'>308</button>
     <button className='card309'>309</button>
     <button className='card310'>310</button>
     <button className='card311'>311</button>
     <button className='card312'>312</button>
     <button className='card313'>313</button>
     <button className='card314'>314</button>
     <button className='card315'>315</button>
     <button className='card316'>316</button>
     <button className='card317'>317</button>
     <button className='card318'>318</button>
     <button className='card319'>319</button>
     <button className='card320'>320</button>
     <button className='card321'>321</button>
     <button className='card322'>322</button>
     <button className='card323'>323</button>
     <button className='card324'>324</button>
     <button className='card325'>325</button>
     <button className='card326'>326</button>
     <button className='card327'>327</button>
     <button className='card328'>328</button>
     <button className='card329'>329</button>
     <button className='card330'>330</button>
     <button className='card331'>331</button>
     <button className='card332'>332</button>
     <button className='card333'>333</button>
     <button className='card334'>334</button>
     <button className='card335'>335</button>
     <button className='card336'>336</button>
     <button className='card337'>337</button>
     <button className='card338'>338</button>
     <button className='card339'>339</button>
     <button className='card340'>340</button>
     <button className='card341'>341</button>
     <button className='card342'>342</button>
     <button className='card343'>343</button>
     <button className='card344'>344</button>
     <button className='card345'>345</button>
     <button className='card346'>346</button>
     <button className='card347'>347</button>
     <button className='card348'>348</button>
     <button className='card349'>349</button>
     <button className='card350'>350</button>
     <button className='card351'>351</button>
     <button className='card352'>352</button>
     <button className='card353'>353</button>
     <button className='card354'>354</button>
     <button className='card355'>355</button>
     <button className='card356'>356</button>
     <button className='card357'>357</button>
     <button className='card358'>358</button>
     <button className='card359'>359</button>
     <button className='card360'>360</button>
     <button className='card361'>361</button>
     <button className='card362'>362</button>
     <button className='card363'>363</button>
     <button className='card364'>364</button>
     <button className='card365'>365</button>
     <button className='card366'>366</button>
     <button className='card367'>367</button>
     <button className='card368'>368</button>
     <button className='card369'>369</button>
     <button className='card370'>370</button>
     <button className='card371'>371</button>
     <button className='card372'>372</button>
     <button className='card373'>373</button>
     <button className='card374'>374</button>
     <button className='card375'>375</button>
     <button className='card376'>376</button>
     <button className='card377'>377</button>
     <button className='card378'>378</button>
     <button className='card379'>379</button>

     <button className='card380'>380</button>
     <button className='card381'>381</button>
     <button className='card382'>382</button>
     <button className='card383'>383</button>
     <button className='card384'>384</button>
     <button className='card385'>385</button>
   
     <button className='card386'>386</button>
     <button className='card387'>387</button>
     <button className='card388'>388</button>
     <button className='card389'>389</button>
     <button className='card390'>390</button>
     <button className='card391'>391</button>                         
     <button className='card392'>392</button>
     <button className='card393'>393</button>
     <button className='card394'>394</button>
     <button className='card395'>395</button>
     <button className='card396'>396</button>
     <button className='card397'>397</button>
     <button className='card398'>398</button>
     <button className='card399'>399</button>
     <button className='card400'>400</button>



  </div>
</div>




<div className='userscard'>


   <div className='usersca'>
      <h3 onClick={()=>{
        const tg=document.querySelector('.userscard')
        tg.style.display='none'
        document.querySelector('.btc').style.display='block'
      }}>X</h3>
    <div className='userexploit'>
      <br/><br/>
      <button onClick={()=>{
         document.querySelector('.bnames').style.display='block'
         document.querySelector('.cnames').style.display='none'
      }}>ካርድ ቁጥርን ያስገቡ</button>
      <button onClick={()=>{
         document.querySelector('.alltakencards').style.display='block'
      }}>የተያዙ ካርዶች ዝርዝር</button>
      <button onClick={()=>{
         let dd=document.querySelector('.cnames')
         dd.innerHTML=''
         dd.style.backgroundColor='rgb(13, 20, 45)'
         dd.style.margin='50px'
        document.querySelector('.bnames').style.display='none'
        document.querySelector('.cnames').style.display='block'
         
       for(let a=0;a<cardNumbers.length;a++){
         console.log(cardNumbers[a].cardname)
         const bbet=document.createElement('p')
             bbet.textContent='B'
             bbet.style.backgroundColor='gold'
             bbet.style.color='black'
             bbet.style.textAlign='center'
             bbet.style.padding='5px'
             bbet.style.borderRadius='30px';
             bbet.style.width='30px';
             bbet.style.height='30px'
             bbet.style.border='1px solid yellow'
             dd.appendChild(bbet)
             
             const ibet=document.createElement('p')
             ibet.textContent='I'
            // ibet.style.position='absolute'
             ibet.style.marginLeft='50px'
             ibet.style.backgroundColor='gold'
             ibet.style.color='black'
             ibet.style.textAlign='center'
             ibet.style.padding='5px'
             ibet.style.marginTop='-30px'
             ibet.style.borderRadius='30px';
             ibet.style.width='30px';
             ibet.style.height='30px'
             ibet.style.border='1px solid yellow'
             dd.appendChild(ibet)

             const nbet=document.createElement('p')
             nbet.textContent='N'
            // nbet.style.position='absolute'
             nbet.style.marginTop='-30px'
             nbet.style.marginLeft='105px'
             nbet.style.backgroundColor='gold'
             nbet.style.padding='5px'
             nbet.style.color='black'
             nbet.style.textAlign='center'
             nbet.style.borderRadius='30px';
             nbet.style.width='30px';
             nbet.style.height='30px'
             nbet.style.border='1px solid yellow'
             dd.appendChild(nbet)

             const gbet=document.createElement('p')
             gbet.textContent='G'
            // gbet.style.position='absolute'
             gbet.style.marginTop='-30px'
             gbet.style.marginLeft='160px'
             gbet.style.backgroundColor='gold'
             gbet.style.color='black'
             gbet.style.textAlign='center'
             gbet.style.padding='5px'
             gbet.style.borderRadius='30px';
             gbet.style.width='30px';
             gbet.style.height='30px'
             gbet.style.border='1px solid yellow'
             dd.appendChild(gbet)

             const obet=document.createElement('p')
             obet.textContent='O'
            // obet.style.position='absolute'
             obet.style.marginTop='-30px'
             obet.style.marginLeft='215px'
             obet.style.backgroundColor='gold'
             obet.style.color='black'
             obet.style.textAlign='center'
             obet.style.padding='5px'
             obet.style.borderRadius='30px';
             obet.style.width='30px';
             obet.style.height='30px'
             obet.style.border='1px solid yellow'
             dd.appendChild(obet)


         for(let k=0;k<cardNumbers[a].b.length;k++){
             console.log(cardNumbers[a].b[k])
            

             const db=document.createElement('p')
             db.textContent=cardNumbers[a].b[k];
             db.style.width='40px';
             db.style.padding='10px'
             db.style.marginTop='10px'
             db.style.backgroundColor='black'
             db.style.color='#fff'
             db.style.border='1px solid gold'
             db.style.borderRadius='30px'
             dd.appendChild(db)

             console.log(cardNumbers[a].i[k])

            const di=document.createElement('p')
         di.textContent=cardNumbers[a].i[k];
        // di.style.position='absolute'
         di.style.width='40px';
         di.style.padding='10px'
         di.style.marginLeft='50px'
         di.style.marginTop='-40px'
         di.style.backgroundColor='black'
         di.style.color='#fff'
         di.style.border='1px solid gold'
         di.style.borderRadius='30px'
         dd.appendChild(di)


         //n bet number
         const dn=document.createElement('p')
         dn.textContent=cardNumbers[a].n[k];
         //dn.style.position='absolute'
         dn.style.width='40px';
         dn.style.padding='10px'
         dn.style.marginLeft='105px'
         dn.style.marginTop='-40px'
         dn.style.backgroundColor='black'
         dn.style.color='#fff'
         dn.style.border='1px solid gold'
         dn.style.borderRadius='30px'
         dd.appendChild(dn)

         //g bet number
         const dg=document.createElement('p')
         dg.textContent=cardNumbers[a].g[k];
         //dg.style.position='absolute'
         dg.style.width='40px';
         dg.style.padding='10px'
         dg.style.marginLeft='160px'
         dg.style.marginTop='-40px'
         dg.style.backgroundColor='black'
         dg.style.color='#fff'
         dg.style.border='1px solid gold'
         dg.style.borderRadius='30px'
         dd.appendChild(dg)

         //o bet number
         const dob=document.createElement('p')
         dob.textContent=cardNumbers[a].o[k];
         //dob.style.position='absolute'
         dob.style.width='40px';
         dob.style.padding='10px'
         dob.style.marginLeft='215px'
         dob.style.marginTop='-40px'
         dob.style.backgroundColor='black'
         dob.style.color='#fff'
         dob.style.border='1px solid gold'
         dob.style.borderRadius='30px'
         dd.appendChild(dob)
    
        
          
         }
         console.log("_________ I ____________")
         const tname=document.createElement('p')
         tname.textContent=cardNumbers[a].cardname
         tname.style.width='300px'
         tname.style.padding='10px'
         tname.style.borderRadius='30px'
         tname.style.marginTop='20px'
         tname.style.marginBottom='20px'
         tname.style.backgroundColor='blue'
         tname.style.color='#fff'
         dd.appendChild(tname)  
     
      }
         /*   
       for(let k=0;k<cardNumbers.length;k++){    
         const db=document.createElement('p')
         db.textContent=cardNumbers[k].b[k];
         db.style.width='40px';
         db.style.padding='10px'
         db.style.marginTop='10px'
         db.style.backgroundColor='black'
         db.style.color='#fff'
         db.style.border='1px solid gold'
         db.style.borderRadius='30px'
         dd.appendChild(db)

         const di=document.createElement('p')
         di.textContent=cardNumbers[k].i[k];
         di.style.position='absolute'
         di.style.width='40px';
         di.style.padding='10px'
         di.style.marginLeft='70px'
         di.style.marginTop='-40px'
         di.style.backgroundColor='black'
         di.style.color='#fff'
         di.style.border='1px solid gold'
         di.style.borderRadius='30px'
         dd.appendChild(di)


         const dn=document.createElement('p')
         dn.textContent=cardNumbers[k].n[k];
         dn.style.position='absolute'
         dn.style.width='40px';
         dn.style.padding='10px'
         dn.style.marginLeft='120px'
         dn.style.marginTop='-40px'
         dn.style.backgroundColor='black'
         dn.style.color='#fff'
         dn.style.border='1px solid gold'
         dn.style.borderRadius='30px'
         dd.appendChild(dn)

         const dg=document.createElement('p')
         dg.textContent=cardNumbers[k].g[k];
         dg.style.position='absolute'
         dg.style.width='40px';
         di.style.padding='10px'
         di.style.marginLeft='70px'
         di.style.marginTop='-40px'
         di.style.backgroundColor='black'
         di.style.color='#fff'
         di.style.border='1px solid gold'
         di.style.borderRadius='30px'
         dd.appendChild(di)

       }
       */
      }}>ካርዶችን ይመልከቱ</button>
    </div>  
       <div className='allcards'>

         <div className='showallcards'>
            <p className='winprice'></p>
         <div className='bnames'>
            <h1>ተጫዋች የመረጠውን ካርድ ቁጥር ያስገቡ!!</h1>
            <input className='choosedcard' placeholder='ካርድ ቁጥር ያስገቡ'/><br/><br/>
            <button onClick={()=>{

               let elem=true;
               let elemm=false;
             
             val=`card${document.querySelector('.choosedcard').value}`
               for(let b=0;b<takencards.length;b++){
                  if(val===takencards[b]){
                       elem=false
                  }
               } 
                
               for(let mk=0;mk<cardNumbers.length;mk++){
                      if(val===cardNumbers[mk].cardname){
                         elemm=true;
                      }
               }

      if(elem===true && elemm===true){
         const info={
            data:document.querySelector('.choosedcard').value,
            phone:sessionStorage.getItem('phone')
         }


         document.querySelector(`.${val}`).style.backgroundColor='gold';
         document.querySelector(`.${val}`).style.color='black';
         //socket.emit('mobile-register',info)     
               let kt=document.querySelector('.registercards')
               let mm=document.createElement('p')
               mm.textContent=`ካርድ ቁጥር ${document.querySelector('.choosedcard').value} ተመዝግቡዋል`
               mm.style.backgroundColor='rgb(13, 40, 45)'
               mm.style.padding='20px'
               mm.style.width='330px'
               mm.style.margin='10px'
               mm.style.color='gold'
               mm.style.borderRadius='5px'
               const height=kt.clientHeight;
               kt.scroll(0,height);
               kt.appendChild(mm)
               takencards.push(val)
               number_of_players++;

               
              // const wnp=document.querySelector('winprice')
               const wnp=document.createElement('p')
               wnp.textContent=`ለአሸናፊው ${Number(number_of_players)*Number(medeb.slice(0,3))*perc} ብር ሽልማት`
               wnp.style.position='absolute';
               wnp.style.backgroundColor='#083e31';
               wnp.style.width='250px';
               wnp.style.marginLeft='50px';
               wnp.style.color='#fff';
               wnp.style.padding='10px';
               wnp.style.borderRadius='5px'
               document.querySelector('.winprice').appendChild(wnp);


               const wnp2=document.createElement('p')
               wnp2.textContent=`ለአሸናፊው ${Number(number_of_players)*Number(medeb.slice(0,3))*perc} ብር ሽልማት`
               wnp2.style.position='absolute';
               wnp2.style.backgroundColor='#083e31';
               wnp2.style.width='250px';
               wnp2.style.marginLeft='50px';
               wnp2.style.color='#fff';
               wnp2.style.padding='10px';
               wnp2.style.borderRadius='5px'
               document.querySelector('.newshow').appendChild(wnp2)

               

               let nps=document.createElement('button')
               nps.textContent=number_of_players;
               nps.style.position='absolute';
               nps.style.marginLeft='320px';
               nps.style.top='130px'
               nps.style.backgroundColor='gold';
               nps.style.width='10px';
               nps.style.height='10px';
               nps.style.border='none'
               nps.style.borderRadius='10px';
               nps.style.fontSize='10px'
               nps.style.color='black';
               nps.style.textAlign='center'
               document.querySelector('.winprice').appendChild(nps); 
      }
      else{
         alert(`${document.querySelector('.choosedcard').value} ይህ ካርቴላ ቁጥር ተመዝግቡዋል እባክኦ ሌላ ካርቴላ ይምረጡ  `)
      }
            }}>መዝግብ</button>
              <div className='registercards'></div>
          </div>
          <div className='cnames'>          
           </div>
         </div>  
          
       </div>
    </div>
</div>









      </div>
    </div>


    </div>)
}

export default Game;