import './style/board.css'

function Board(){
    return(<div className='bodyy'>
            <div className='main__boards'>
               <h1>Hello Board</h1>
        </div>
    </div>)
}

export default Board;
